import { useCallback } from 'react';
import {
    createSearchParams,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';

export const useNavigateWithParams = () => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const navigateWithParams = useCallback(
        (pathname: string) => {
            navigate({
                pathname,
                search: `?${createSearchParams(searchParams)}`,
            });
        },
        [navigate, searchParams]
    );

    return navigateWithParams;
};
