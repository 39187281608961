import styled from 'styled-components';

import { spacing, transparencies } from 'styles/theme';

export const AvatarWrapper = styled.div`
    border-radius: ${spacing.xxLarge};
    background-color: ${transparencies.darkJungle5};
    width: ${spacing.xxLarge};
    height: ${spacing.xxLarge};
    display: flex;
    justify-content: center;
    align-items: center;
`;
