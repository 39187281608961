import { Database } from 'libs/supabaseTypes';

import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { supabase, useAuth } from 'hooks/useAuth';
import { useToast } from 'hooks/useToast';

import { Fonts, colors } from 'styles/theme';
import { Bumper, ContentScroller, GridListWrapper } from 'styles/shared';
import { ScrollerWrapper, ListWrapper } from 'legacy/styles/coach';
import { GroupReportCard } from 'legacy/styles/groupReports';

export type SubmittedRecap = Database['public']['Tables']['recaps']['Row'];

export const RecapHistory = () => {
    const { userId } = useAuth();
    const { showErrorToast } = useToast();

    const [, setSearchParams] = useSearchParams();

    const [mySubmittedRecaps, setMySubmittedRecaps] = useState<
        Array<SubmittedRecap>
    >([]);

    useEffect(() => {
        const getMySubmittedRecaps = async () => {
            const { data, error } = await supabase.from('recaps').select('*');

            if (error == null) {
                setMySubmittedRecaps(data);
            } else {
                showErrorToast(error.message);
            }
        };

        if (userId !== '') {
            getMySubmittedRecaps();
        }
    }, [showErrorToast, userId]);

    const onSelectSubmittedRecap = useCallback(
        (submittedRecap: SubmittedRecap) => () => {
            setSearchParams({
                modalContent: 'recap-details',
                submittedRecapId: String(submittedRecap.id),
            });
        },
        [setSearchParams]
    );

    return (
        <ContentScroller>
            <ListWrapper>
                <Bumper />
                <Bumper />
                <Bumper />
                <ScrollerWrapper>
                    <Bumper />
                    {mySubmittedRecaps.length === 0 ? (
                        <>
                            <Fonts.Large>{`Once you've submitted your first recap, it will appear here along with any future session recaps!`}</Fonts.Large>
                        </>
                    ) : (
                        <GridListWrapper>
                            {mySubmittedRecaps.map((submittedRecap) => {
                                const sessionDate = new Date(
                                    submittedRecap.session_date ?? 0
                                );

                                return (
                                    <GroupReportCard
                                        $linkEnabled={true}
                                        key="submittedRecap.id"
                                        onClick={onSelectSubmittedRecap(
                                            submittedRecap
                                        )}
                                    >
                                        <Fonts.Heading5
                                            $color={colors.mountainAsh}
                                        >
                                            {submittedRecap.group_id}
                                        </Fonts.Heading5>
                                        <Fonts.Medium>
                                            {sessionDate.toLocaleString(
                                                'en-us',
                                                {
                                                    month: 'long',
                                                }
                                            )}{' '}
                                            {sessionDate.getDate()}
                                            {', '}
                                            {sessionDate.getFullYear()}
                                        </Fonts.Medium>
                                    </GroupReportCard>
                                );
                            })}
                        </GridListWrapper>
                    )}
                    <Bumper />
                    <Bumper />
                    <Bumper />
                </ScrollerWrapper>
            </ListWrapper>
        </ContentScroller>
    );
};

export default RecapHistory;
