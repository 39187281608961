import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Database } from 'libs/supabaseTypes';

import { supabase, useAuth } from 'hooks/useAuth';
import { useToast } from 'hooks/useToast';

import { SubmitIntake } from 'components/layout/SubmitIntake';
import { SubmitDiagnostic } from 'components/layout/SubmitDiagnostic';

import { GrandLogoVert } from 'components/svg/GrandLogoVert';

import { Fonts, spacing } from 'styles/theme';
import { Card, FullBleedScroller, Bumper } from 'styles/shared';

type IntakeData = Database['public']['Tables']['intakes']['Row'];
type DiagnosticData = Database['public']['Tables']['diagnostics']['Row'];
type OnboardingView =
    | 'loading'
    | 'waitlist'
    | 'intake'
    | 'diagnostic'
    | 'complete';

export const OnboardingContainer = () => {
    const { userId, userProfile } = useAuth();
    const { showErrorToast } = useToast();

    const [intakes, setIntakes] = useState<Array<IntakeData>>([]);
    const [diagnostics, setDiagnostics] = useState<Array<DiagnosticData>>([]);
    const [currentView, setCurrentView] = useState<OnboardingView>('loading');
    const [shouldScrollToTop, setShouldScrollToTop] = useState<boolean>(false);

    const scrollRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    useEffect(() => {
        if (shouldScrollToTop) {
            scrollRef.current?.scrollTo({ top: 0 });
            setShouldScrollToTop(false);
        }
    }, [shouldScrollToTop]);

    const getIntakesAndDiagnostics = useCallback(async () => {
        const { data: intakesData, error: intakesError } = await supabase
            .from('intakes')
            .select('*')
            .eq('created_by', userId);

        if (intakesError == null) {
            setIntakes(intakesData);
        } else {
            showErrorToast(intakesError.message);
        }

        const { data: diagnosticsData, error: diagnosticsError } =
            await supabase
                .from('diagnostics')
                .select('*')
                .eq('created_by', userId);

        if (diagnosticsError == null) {
            setDiagnostics(diagnosticsData);
        } else {
            showErrorToast(diagnosticsError.message);
        }
    }, [showErrorToast, userId]);

    useEffect(() => {
        if (userId !== '') {
            getIntakesAndDiagnostics();
        }
    }, [getIntakesAndDiagnostics, userId]);

    useEffect(() => {
        if (userProfile == null) {
            setCurrentView('loading');
            setShouldScrollToTop(true);
        } else if (userProfile?.temp_group_sponsor_id == null) {
            setCurrentView('waitlist');
            setShouldScrollToTop(true);
        } else if (intakes.length === 0) {
            setCurrentView('intake');
            setShouldScrollToTop(true);
        } else if (diagnostics.length === 0) {
            setCurrentView('diagnostic');
            setShouldScrollToTop(true);
        } else {
            setCurrentView('complete');
            setShouldScrollToTop(true);
        }
    }, [diagnostics.length, intakes.length, userProfile]);

    const renderOnboardingComponent = useCallback(() => {
        switch (currentView) {
            case 'waitlist': {
                return (
                    <>
                        <Fonts.Heading3>{`We aren't accepting any applications to The Grand Council until 2025.`}</Fonts.Heading3>
                        <Bumper />
                        <Bumper />
                        <Fonts.Medium>
                            {`If you'd like, you can join our waitlist `}
                            <Fonts.Medium
                                as="a"
                                href="https://share.hsforms.com/1EMthQoaWTAWgEH4rxvBzBQqy9kk"
                                target="_blank"
                                rel="noreferrer"
                                $isUnderlined
                            >{`here`}</Fonts.Medium>
                            {`.`}
                        </Fonts.Medium>
                    </>
                );
            }
            case 'intake': {
                return (
                    <SubmitIntake
                        onChangeFormStep={() => setShouldScrollToTop(true)}
                        onSubmit={getIntakesAndDiagnostics}
                    />
                );
            }
            case 'diagnostic': {
                return <SubmitDiagnostic onSubmit={getIntakesAndDiagnostics} />;
            }
            case 'complete': {
                return (
                    <Fonts.Heading5 $textAlign="center">{`Thanks for your application! Our team will be in touch regarding next steps soon.`}</Fonts.Heading5>
                );
            }
            case 'loading':
            default: {
                return <Fonts.Medium>{`Loading...`}</Fonts.Medium>;
            }
        }
    }, [currentView, getIntakesAndDiagnostics]);

    return (
        <FullBleedScroller ref={scrollRef}>
            <Bumper />
            <div>
                <GrandLogoVert width={140} />
            </div>
            <Bumper $minHeight={spacing.xLarge} />
            <Card $margin="auto" $width="720px">
                {renderOnboardingComponent()}
            </Card>
            <Bumper $minHeight={spacing.xxxLarge} $shouldHideOnMobile />
            <Bumper $minHeight={spacing.xxxLarge} $shouldHideOnMobile />
        </FullBleedScroller>
    );
};
