import { Database } from 'libs/supabaseTypes';

import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { supabase, useAuth } from 'hooks/useAuth';
import { useToast } from 'hooks/useToast';

import { Bumper, ContentScroller, HorizontalFlexWrapper } from 'styles/shared';
import { Fonts, colors, spacing } from 'styles/theme';

import {
    ScrollerWrapper,
    ListWrapper,
    RecapsWrapper,
    LinkText,
} from 'legacy/styles/coach';

import { Buttons } from 'legacy/styles/buttons';

import { GroupReportCard } from 'legacy/styles/groupReports';
import { getRelationshipToToday } from 'libs/utils';
import { Button } from 'components/ui/Button';

type GroupData = Database['public']['Tables']['groups']['Row'];
type ProfileData = Database['public']['Tables']['profiles']['Row'];

export const CoachResources = () => {
    const { userProfile } = useAuth();
    const { showSuccessToast, showErrorToast } = useToast();

    const [groupsData, setGroupsData] = useState<GroupData[] | null>([]);
    const [memberProfileData, setMemberProfileData] = useState<
        ProfileData[] | null
    >([]);

    useEffect(() => {
        const getGroupsData = async () => {
            const { data, error } = await supabase
                .from('groups')
                .select()
                .in('gid', userProfile?.coach_groups || []);

            if (error == null) {
                setGroupsData(data);
            } else {
                showErrorToast(error.message);
            }
        };

        getGroupsData();
    }, [showErrorToast, userProfile?.coach_groups]);

    useEffect(() => {
        const getGroupMembers = async () => {
            const { data, error } = await supabase
                .from('profiles')
                .select()
                .overlaps('member_groups', userProfile?.coach_groups || []);

            if (error == null) {
                setMemberProfileData(data);
            } else {
                showErrorToast(error.message);
            }
        };
        getGroupMembers();
    }, [showErrorToast, userProfile?.coach_groups]);

    const passClosingLinkToClipboard = useCallback(
        (sessionNumber: number, sessionDate: string) => async () => {
            try {
                await navigator.clipboard.writeText(
                    `http://home.thegrand.world/member/all-sessions/${sessionNumber}/description?modalContent=closing&sessionDate=${encodeURIComponent(
                        sessionDate
                    )}`
                );
                showSuccessToast(
                    `Copied member closing link for Session ${sessionNumber} to clipboard!`
                );
            } catch (error) {
                if (typeof error === 'string') {
                    showErrorToast(error.toUpperCase());
                } else if (error instanceof Error) {
                    showErrorToast(error.message);
                }
            }
        },
        [showErrorToast, showSuccessToast]
    );

    return (
        <ContentScroller>
            <ListWrapper>
                <Bumper />
                <Bumper />
                <Bumper />
                <ScrollerWrapper>
                    <Bumper />
                    <Bumper />
                    <Fonts.Medium>
                        {`Here are links to your resources:`}
                    </Fonts.Medium>
                    <HorizontalFlexWrapper>
                        <Link
                            to="https://www.notion.so/thegrandworld/The-Grand-Coaching-Hub-7fef7bc9ada5414ab03e35cc6cd1577c"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <LinkText $isUnderlined>
                                {`Coaches Hub (Notion)`}
                            </LinkText>
                        </Link>
                        <Bumper $minWidth={spacing.xxxLarge} />
                        <Link
                            to="https://thegrandcommuity.slack.com"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <LinkText
                                $isUnderlined
                            >{`Slack Community`}</LinkText>
                        </Link>
                        <Bumper $minWidth={spacing.xxxLarge} />
                        <Link to="?modalContent=feedback">
                            <LinkText $isUnderlined>{`Feedback Tool`}</LinkText>
                        </Link>
                    </HorizontalFlexWrapper>
                    <Bumper />
                    <Bumper />
                    <Fonts.Medium>
                        {`Here is some information about your groups:`}
                    </Fonts.Medium>

                    <RecapsWrapper>
                        {groupsData?.map((group: GroupData) => {
                            const isNextSessionDatePast =
                                group.next_session_date == null ||
                                getRelationshipToToday(
                                    new Date(group.next_session_date)
                                ) === 'previous';
                            return (
                                <GroupReportCard
                                    $linkEnabled={false}
                                    key={group.gid}
                                >
                                    <Fonts.Heading5 $color={colors.mountainAsh}>
                                        {group.gid}
                                    </Fonts.Heading5>
                                    <Bumper />
                                    {group.has_topic_tool ? (
                                        <>
                                            <Link
                                                to={`https://topics-39ziwba.thegrand.world/?groupSession=${group.gid}-${group.session_counter}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <Button
                                                    $style="outline"
                                                    $isSmall
                                                >
                                                    {`Open Topic Tool for Session ${group.session_counter}`}
                                                </Button>
                                            </Link>
                                            <Bumper />
                                        </>
                                    ) : null}
                                    {group.next_session_date == null ? null : (
                                        <>
                                            <Button
                                                onClick={passClosingLinkToClipboard(
                                                    group.session_counter,
                                                    group.next_session_date
                                                )}
                                                $style="outline"
                                                $isSmall
                                            >
                                                {`Copy Member Closing Link for Session ${group.session_counter}`}
                                            </Button>
                                            <Bumper />
                                        </>
                                    )}
                                    {isNextSessionDatePast ||
                                    group.previous_session_date ==
                                        null ? null : (
                                        <>
                                            <Button
                                                onClick={passClosingLinkToClipboard(
                                                    group.session_counter - 1,
                                                    group.previous_session_date
                                                )}
                                                $style="outline"
                                                $isSmall
                                            >
                                                {`Copy Member Closing Link for Session ${
                                                    group.session_counter - 1
                                                }`}
                                            </Button>
                                            <Bumper />
                                        </>
                                    )}
                                    <div>
                                        <Fonts.Small
                                            $color={colors.grey30}
                                        >{`Members:`}</Fonts.Small>
                                        <br />
                                        <Fonts.XSmall>
                                            {memberProfileData?.map(
                                                (member) => {
                                                    if (
                                                        member.member_groups?.includes(
                                                            group.gid
                                                        )
                                                    )
                                                        return (
                                                            <Buttons.Pill
                                                                $isSelected={
                                                                    false
                                                                }
                                                                $isDisabled
                                                                key={member.id}
                                                            >
                                                                {
                                                                    member.full_name
                                                                }
                                                            </Buttons.Pill>
                                                        );
                                                }
                                            )}
                                        </Fonts.XSmall>
                                    </div>
                                </GroupReportCard>
                            );
                        })}
                    </RecapsWrapper>
                </ScrollerWrapper>
            </ListWrapper>
        </ContentScroller>
    );
};
