import React from 'react';

import { Fonts } from 'styles/theme';
import { TagWrapper } from 'styles/tag';

interface TagProps {
    text: string;
}

export const Tag = ({ text }: TagProps) =>
    text === '' ? null : (
        <TagWrapper>
            <Fonts.XxSmall $isCapitalized>{text}</Fonts.XxSmall>
        </TagWrapper>
    );
