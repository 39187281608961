import React, { Fragment, ReactNode, useCallback, useMemo } from 'react';

import { useAuth } from 'hooks/useAuth';
import { ClosingData, useMemberGroupData } from 'hooks/useMemberGroupData';

import { KUDOS_TYPES_TO_EMOJIS } from 'libs/constants';
import { formatDate } from 'libs/utils';

import { colors, Fonts, spacing, transparencies } from 'styles/theme';
import { Bumper, Divider, HorizontalFlexWrapper } from 'styles/shared';

export const Closings = () => {
    const { userId } = useAuth();
    const { closings, sessionDetails } = useMemberGroupData();

    const currentUserClosings = useMemo(
        () => closings.filter((closing) => closing.created_by === userId),
        [closings, userId]
    );

    const getSessionTitle = useCallback(
        (sessionNumber: number) => {
            const sessionDetailsForClosing = sessionDetails[sessionNumber - 1];
            return sessionDetailsForClosing == null
                ? ''
                : sessionDetailsForClosing.title;
        },
        [sessionDetails]
    );

    const renderKudosCounts = useCallback(
        (sessionNumber: number) => {
            const groupClosingsBySessionNumber = closings.filter(
                (closing) => closing.session_number === sessionNumber
            );
            const currentUserKudosCounts: Map<string, number> = new Map();
            groupClosingsBySessionNumber.forEach((closing) => {
                if (userId === closing.created_by) {
                    return;
                }
                const parsedRawData = JSON.parse(
                    JSON.stringify(closing.raw_data ?? {})
                );
                Object.keys(parsedRawData?.kudos ?? {})?.forEach(
                    (kudosText) => {
                        if (parsedRawData?.kudos?.[kudosText] === userId) {
                            const currentKudosCount =
                                currentUserKudosCounts.get(kudosText);
                            if (currentKudosCount == null) {
                                currentUserKudosCounts.set(kudosText, 1);
                            } else {
                                currentUserKudosCounts.set(
                                    kudosText,
                                    currentKudosCount + 1
                                );
                            }
                        }
                    }
                );
            });
            const kudosCountsToRender: Array<ReactNode> = [];
            let i = 0;
            currentUserKudosCounts.forEach((kudosCount, kudosText) => {
                i++;
                kudosCountsToRender.push(
                    <Fragment key={kudosText}>
                        <Fonts.MediumQuote>
                            {`${
                                // TODO: update typing so kudosText can be used to index the emoji map
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                KUDOS_TYPES_TO_EMOJIS[kudosText]
                            } ${kudosText} (x${kudosCount.toString()})`}
                        </Fonts.MediumQuote>
                        {kudosCountsToRender.length <
                        currentUserKudosCounts.size - 1
                            ? ','
                            : ''}
                        {i === 0 || i === currentUserKudosCounts.size ? null : (
                            <>&nbsp;</>
                        )}
                    </Fragment>
                );
            });
            return kudosCountsToRender;
        },
        [closings, userId]
    );

    const renderClosingData = useCallback(
        (closing: ClosingData, index: number) => {
            const parsedRawData = JSON.parse(JSON.stringify(closing.raw_data));
            const renderedKudosCounts = renderKudosCounts(
                closing.session_number ?? 0
            );
            return (
                <Fragment key={closing.id}>
                    <Fonts.XLarge
                        $color={colors.mountainAsh}
                    >{`Session ${closing.session_number}`}</Fonts.XLarge>
                    {closing.session_date == null ? null : (
                        <Fonts.Medium $color={colors.grandGreen}>
                            {formatDate(new Date(closing.session_date))}
                        </Fonts.Medium>
                    )}
                    <Bumper $minHeight={spacing.xSmall} />
                    <Fonts.Heading7>
                        {getSessionTitle(closing.session_number ?? 0)}
                    </Fonts.Heading7>
                    <Bumper $minHeight={spacing.xSmall} />
                    <Fonts.MediumQuote $color={colors.grandGreen}>
                        {parsedRawData?.learnings}
                    </Fonts.MediumQuote>
                    <Bumper $minHeight={spacing.xSmall} />
                    {parsedRawData?.action == null ||
                    parsedRawData.action === '' ? null : (
                        <>
                            <Fonts.Small
                                $isOpenSansSemiBold
                            >{`Your “Tiny Actions”:`}</Fonts.Small>
                            <Bumper $minHeight={spacing.xxxSmall} />
                            <Fonts.MediumQuote>
                                {parsedRawData?.action}
                            </Fonts.MediumQuote>
                            <Bumper $minHeight={spacing.xSmall} />
                        </>
                    )}
                    {renderedKudosCounts.length > 0 ? (
                        <>
                            <Fonts.Small
                                $isOpenSansSemiBold
                            >{`Members in your group gave you the following kudos. You were recognized for being:`}</Fonts.Small>
                            <Bumper $minHeight={spacing.xxxSmall} />
                            <HorizontalFlexWrapper $isWrapping>
                                {renderedKudosCounts}
                            </HorizontalFlexWrapper>
                            <Bumper $minHeight={spacing.xSmall} />
                        </>
                    ) : null}

                    {index === currentUserClosings.length - 1 ? null : (
                        <Divider
                            $backgroundColor={transparencies.darkJungle20}
                        />
                    )}
                </Fragment>
            );
        },
        [currentUserClosings.length, getSessionTitle, renderKudosCounts]
    );

    return currentUserClosings.length === 0 ? null : (
        <>{currentUserClosings.map(renderClosingData)}</>
    );
};
