export const CLARITY_SESSION_TITLE = 'Clarity Council';

export const SESSION_EXERCISES = [
    'Clarity Council',
    'Knowledge Share',
    'Coaching Fundamentals',
    'DISC',
    'Eisenhower Matrix',
    'Future Self Visualization',
    'Giving and Receiving Feedback',
    'Grand Retro',
    'Grounding Meditation',
    'Life Story Redux',
    'Limiting Beliefs',
    'Reflected Best Self',
    'Rose Bud Thorn',
    'Six Lives',
    'Timeline of Your Life',
    'Values Hierarchy',
    'Zone of Genius',
    'Other',
] as const;

export const ADMIN_TIMES = [
    '0-5 min',
    '5-10 min',
    '10-15 min',
    '15-20 min',
    '20-25 min',
    '25-30 min',
    '30+ min',
] as const;

export const ADMIN_TIME_THRESHOLDS = [
    '15-20 min',
    '20-25 min',
    '25-30 min',
    '30+ min',
];

export const ADMIN_TYPES = [
    'Reviewing session guides and prepping for sessions',
    'Filling out the recap form',
    'Sending a recap email',
    'Following up on Slack',
    'Managing different tools',
] as const;
