import styled, { css } from 'styled-components';

import {
    spacing,
    colors,
    transparencies,
    shadows,
    Fonts,
    Spacing,
    ColorsTransparencies,
    mobileCssQuerist,
} from 'styles/theme';

export const MainViewWrapper = styled.div`
    display: flex;
    overflow: hidden;
`;

export const ContentScroller = styled.div`
    width: 650px;
    max-height: 100vh;
    overflow: scroll;
`;

export const FullBleedScroller = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;

    ${mobileCssQuerist(
        css`
            height: auto;
            overflow-x: hidden;
        `
    )}
`;

interface CustomizableWidthWrapper {
    $width: string;
}

export const CustomizableWidthWrapper = styled.div<CustomizableWidthWrapper>`
    ${(props) => css`
        width: ${props.$width};
    `};
`;

interface HorizontalFlexWrapperProps {
    $isWrapping?: boolean;
    $isMarginAuto?: boolean;
    $isContentSpread?: boolean;
    $isVerticalOnMobile?: boolean;
}

export const HorizontalFlexWrapper = styled.div<HorizontalFlexWrapperProps>`
    display: flex;
    align-items: center;

    ${(props) =>
        props.$isWrapping
            ? css`
                  flex-wrap: wrap;
              `
            : null}

    ${(props) =>
        props.$isMarginAuto
            ? css`
                  margin: auto;
              `
            : null}

    ${(props) =>
        props.$isContentSpread
            ? css`
                  justify-content: space-between;
              `
            : null}

    ${(props) =>
        props.$isVerticalOnMobile
            ? mobileCssQuerist(css`
                  flex-direction: column;
              `)
            : null}
`;

interface CardProps {
    $margin?: string;
    $width?: string;
    $padding?: Spacing;
}

export const Card = styled.div<CardProps>`
    position: relative;
    box-shadow: ${shadows.smallBase};
    border-radius: ${spacing.radiusLarge};
    border: 1px solid ${transparencies.darkJungle5};
    background-color: ${colors.white};
    padding: ${spacing.xLarge};
    display: flex;
    flex-direction: column;
    cursor: default;

    ${mobileCssQuerist(css`
        width: auto;
        min-width: 360px;
        margin: 0 ${spacing.xLarge};
        overflow-x: hidden;
        overflow-y: visible;
    `)}

    ${(props) =>
        props.$margin == null
            ? null
            : css`
                  margin: ${props.$margin};
              `}

    ${(props) =>
        props.$width == null
            ? null
            : css`
                  width: ${props.$width};
              `}

    ${(props) =>
        props.$padding == null
            ? null
            : css`
                  padding: ${props.$padding};
              `}
`;

export const BackButtonWrapper = styled.div`
    position: absolute;
    left: ${spacing.large};
    top: ${spacing.large};
`;

interface ProgressBarWrapperProps {
    $color?: ColorsTransparencies;
    $isStretch?: boolean;
}

export const ProgressBarWrapper = styled.div<ProgressBarWrapperProps>`
    margin: ${spacing.xxxSmall} ${spacing.xxxLarge} 0 ${spacing.xxLarge};
    border-radius: ${spacing.radiusXSmall};
    background-color: ${transparencies.darkJungle40};
    height: ${spacing.xxxSmall};
    display: flex;
    align-items: center;

    ${(props) =>
        props.$color == null
            ? null
            : css`
                  background-color: ${props.$color};
              `}

    ${(props) =>
        props.$isStretch
            ? css`
                  width: 100%;
                  margin: 0 ${spacing.small} 0 0;
              `
            : null}
`;

interface ProgressBarProps {
    $color?: ColorsTransparencies;
    $width: number;
}

export const ProgressBar = styled.div<ProgressBarProps>`
    border-radius: ${spacing.radiusXSmall};
    background-color: ${colors.grandGold};
    height: 100%;
    transition: width 0.4s;

    ${(props) =>
        props.$color == null
            ? null
            : css`
                  background-color: ${props.$color};
              `}

    ${(props) =>
        css`
            width: ${props.$width}%;
        `}
`;

export interface NestedCardProps {
    $padding?: Spacing;
}

export const NestedCard = styled.div<NestedCardProps>`
    position: relative;
    border: 1px solid ${transparencies.darkJungle5};
    border-radius: ${spacing.radiusSmall};
    background-color: ${transparencies.darkJungle5};
    padding: ${spacing.small};
    display: flex;
    flex-direction: column;

    ${(props) =>
        props.$padding == null
            ? null
            : css`
                  padding: ${props.$padding};
              `};
`;

interface CardBottomButtonWrapperProps {
    $backgroundColor?: 'white' | 'light';
}

export const CardBottomButtonWrapper = styled.div<CardBottomButtonWrapperProps>`
    margin: 0 -${spacing.xLarge} -${spacing.xLarge};
    border-bottom-left-radius: ${spacing.radiusMedium};
    border-bottom-right-radius: ${spacing.radiusMedium};
    background-color: ${colors.light};
    width: calc(100% + ${spacing.xLarge} + ${spacing.xLarge});
    padding: ${spacing.xLarge};
    display: flex;
    justify-content: flex-end;

    ${(props) => {
        switch (props.$backgroundColor) {
            case 'white': {
                return css`
                    background-color: ${colors.white};
                    padding-top: 0;
                `;
            }
            case 'light':
            default: {
                return;
            }
        }
    }};
`;

interface BannerWrapperProps {
    $backgroundImageUrl: string;
}

export const BannerWrapper = styled.div<BannerWrapperProps>`
    // When using a background image, adding a transparent border will render
    // transparency over the edge the image regardless of how it's positioned
    // because borders exist outside of padding
    //
    // Use an insetted box shadows instead, which is rendered inside of padding
    box-shadow: inset 0 0 0 1px ${transparencies.darkJungle5};
    border-radius: ${spacing.radiusSmall};
    padding: ${spacing.large};

    ${(props) => css`
        background-image: url(${props.$backgroundImageUrl});
        background-position: top right;
        background-size: cover;
    `}
`;

export const GridListWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

interface BumperProps {
    $minHeight?: Spacing;
    $minWidth?: Spacing;
    $shouldHideOnDesktop?: boolean;
    $shouldHideOnMobile?: boolean;
}

export const Bumper = styled.div<BumperProps>`
    min-height: ${spacing.large};

    ${(props) =>
        props.$minHeight == null
            ? null
            : css`
                  min-height: ${props.$minHeight};
              `}

    ${(props) =>
        props.$minWidth == null
            ? null
            : css`
                  min-width: ${props.$minWidth};
              `}

    ${(props) =>
        props.$shouldHideOnDesktop
            ? css`
                  display: none;

                  ${mobileCssQuerist(
                      css`
                          display: block;
                      `
                  )}
              `
            : null}

    ${(props) =>
        props.$shouldHideOnMobile
            ? mobileCssQuerist(
                  css`
                      display: none;
                  `
              )
            : null}
`;

interface DividerProps {
    $backgroundColor?: ColorsTransparencies;
}

export const Divider = styled.div<DividerProps>`
    margin: ${spacing.small} 0;
    background-color: ${transparencies.darkJungle5};
    width: 100%;
    height: 1px;

    ${(props) =>
        props.$backgroundColor == null
            ? null
            : css`
                  background-color: ${props.$backgroundColor};
              `};
`;

export const Label = styled(Fonts.Small)`
    margin-bottom: ${spacing.xxSmall};
`;

export const SubLabel = styled(Fonts.XSmall)`
    margin-bottom: ${spacing.xxSmall};
    color: ${colors.grandGreen};
`;

export const CheckboxWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

export const Checkbox = styled.input`
    margin-right: ${spacing.xSmall};
    min-width: ${spacing.small};
    outline: none;
    min-height: ${spacing.small};
    cursor: pointer;

    &:checked {
        accent-color: ${colors.grandGreen};
    }
`;
