import React, { SelectHTMLAttributes } from 'react';

import { RequiredAsterisk } from 'components/ui/RequiredAsterisk';

import { ChevronSmall } from 'components/svg/ChevronSmall';

import { colors, FontColorProps } from 'styles/theme';
import { Label, SubLabel } from 'styles/shared';
import {
    Select as StyledSelect,
    DropdownArrowWrapper,
    SelectWrapper,
} from 'styles/select';

type HTMLSelectCustomProps = SelectHTMLAttributes<HTMLSelectElement> &
    FontColorProps;
interface SelectProps extends HTMLSelectCustomProps {
    label?: string;
    subLabel?: string;
}

export const Select = (props: SelectProps) => {
    const { label, required, subLabel, value } = props;

    return (
        <SelectWrapper>
            {label == null ? null : (
                <Label>
                    {label}
                    {required ? <RequiredAsterisk /> : null}
                </Label>
            )}
            {subLabel == null ? null : <SubLabel>{subLabel}</SubLabel>}
            <StyledSelect
                {...props}
                $color={
                    value != null && value !== ''
                        ? colors.darkJungle
                        : colors.grey30
                }
            />
            <DropdownArrowWrapper>
                <ChevronSmall />
            </DropdownArrowWrapper>
        </SelectWrapper>
    );
};
