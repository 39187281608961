import React, { useCallback } from 'react';

import { useAuth } from 'hooks/useAuth';

import {
    colors,
    ColorsTransparencies,
    Fonts,
    isMobileScreenSize,
    spacing,
} from 'styles/theme';
import { Bumper, HorizontalFlexWrapper } from 'styles/shared';
import { Link } from 'react-router-dom';

export interface FooterProps {
    hasSignOut: boolean;
    $textColor: ColorsTransparencies;
    $hasBottomBumper: boolean;
}

export const Footer = ({
    hasSignOut,
    $textColor,
    $hasBottomBumper,
}: FooterProps) => {
    const { signOut } = useAuth();

    const onSignOut = useCallback(() => {
        signOut?.();
    }, [signOut]);

    const calculatedTextColor = isMobileScreenSize ? colors.grey30 : $textColor;

    return (
        <HorizontalFlexWrapper $isContentSpread $isVerticalOnMobile>
            <Bumper $shouldHideOnDesktop />
            <Fonts.XSmall $color={calculatedTextColor}>
                {`© The Grand, Inc. 2024`}
            </Fonts.XSmall>
            <HorizontalFlexWrapper $isVerticalOnMobile>
                <Bumper $minWidth={spacing.xxxLarge} />
                <Link
                    to="https://www.thegrand.world/termsofservice"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Fonts.XSmall $color={calculatedTextColor} $isUnderlined>
                        {`Terms of Service`}
                    </Fonts.XSmall>
                </Link>
                <Bumper $minWidth={spacing.xxxLarge} />
                <Link
                    to="https://www.thegrand.world/privacypolicy"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Fonts.XSmall $color={calculatedTextColor} $isUnderlined>
                        {`Privacy Policy`}
                    </Fonts.XSmall>
                </Link>
                {hasSignOut ? (
                    <>
                        <Bumper $minWidth={spacing.xxxLarge} />
                        <Link to="/sign-in" onClick={onSignOut}>
                            <Fonts.XSmall
                                $color={calculatedTextColor}
                                $isUnderlined
                            >
                                {`Log Out`}
                            </Fonts.XSmall>
                        </Link>
                    </>
                ) : null}
            </HorizontalFlexWrapper>

            {$hasBottomBumper ? (
                <>
                    <Bumper
                        $minHeight={spacing.xxxLarge}
                        $shouldHideOnDesktop
                    />
                    <Bumper
                        $minHeight={spacing.xxxLarge}
                        $shouldHideOnDesktop
                    />
                </>
            ) : null}
        </HorizontalFlexWrapper>
    );
};
