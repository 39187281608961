import React from 'react';

import { colors, Fonts } from 'styles/theme';
import { TabControlsWrapper, TabWrapper } from 'styles/tabControls';

export interface Tab {
    text: string;
    isSelected: boolean;
    onClick: () => void;
}

interface TabControlsProps {
    tabs: Array<Tab>;
    $isFlexStart?: boolean;
}

export const TabControls = ({ tabs, $isFlexStart }: TabControlsProps) => (
    <TabControlsWrapper $isFlexStart={$isFlexStart}>
        {tabs.map((tab) => (
            <TabWrapper
                key={tab.text}
                onClick={tab.onClick}
                $isSelected={tab.isSelected}
                $isFlexStart={$isFlexStart}
            >
                <Fonts.Medium
                    $color={
                        tab.isSelected ? colors.mountainAsh : colors.grandGreen
                    }
                >
                    {tab.text}
                </Fonts.Medium>
            </TabWrapper>
        ))}
    </TabControlsWrapper>
);
