import { Database } from 'libs/supabaseTypes';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { supabase, useAuth } from 'hooks/useAuth';
import { useToast } from 'hooks/useToast';

import { Select } from 'components/ui/Select';

import { Bumper, ContentScroller } from 'styles/shared';
import { isMediumScreenSize, Fonts, colors } from 'styles/theme';

import { GroupReportCard } from 'legacy/styles/groupReports';

import {
    ScrollerWrapper,
    ListWrapper,
    RecapsWrapper,
    CoachSelectWrapper,
} from 'legacy/styles/coach';

export type IncompleteRecapData =
    Database['public']['Views']['incomplete_recaps']['Row'];
export type ProfileData = Database['public']['Tables']['profiles']['Row'];
export type GroupMember = {
    profile: ProfileData;
    attended: boolean;
    closingWords: string;
};

export const PendingRecaps = () => {
    const { userId, userProfile } = useAuth();
    const { showErrorToast } = useToast();

    const [, setSearchParams] = useSearchParams();

    const [incompleteRecaps, setIncompleteRecaps] = useState<
        Array<IncompleteRecapData>
    >([]);
    const [coachProfiles, setCoachProfiles] = useState<Array<ProfileData>>([]);
    const [selectedCoach, setSelectedCoach] = useState<ProfileData | null>(
        null
    );

    useEffect(() => {
        const getIncompleteRecaps = async () => {
            const { data, error } = await supabase
                .from('incomplete_recaps')
                .select('*')
                .order('session_start');

            if (error == null) {
                data.sort((a) =>
                    userProfile?.coach_groups?.includes(a.group_id ?? '')
                        ? -1
                        : 0
                );
                setIncompleteRecaps(data);
            } else {
                showErrorToast(error.message);
            }
        };

        const getCoachProfiles = async () => {
            const { data, error } = await supabase
                .from('profiles')
                .select('*')
                .eq('is_coach', true);

            if (error == null) {
                setCoachProfiles(data);
            } else {
                showErrorToast(error.message);
            }
        };

        if (userId !== '') {
            getIncompleteRecaps();
            getCoachProfiles();
        }
        // Adding setSearchParams to our dependencies to refetch after submitting a recap (which should trigger the modal to close and the search params to be updated)
        // eslint-disable-next-line react-hooks-addons/no-unused-deps
    }, [showErrorToast, userId, userProfile?.coach_groups, setSearchParams]);

    const onSelectIncompleteRecap = useCallback(
        (incompleteRecap: IncompleteRecapData) => () => {
            setSearchParams({
                modalContent: 'coach-recap',
                incompleteRecapId: incompleteRecap?.event_id ?? '',
            });
        },
        [setSearchParams]
    );

    const onChangeSelectedCoach = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            const newSelectedCoachProfile = coachProfiles.find(
                (coachProfile) => coachProfile.full_name === target.value
            );
            if (newSelectedCoachProfile == null) {
                setSelectedCoach(null);
            } else {
                setSelectedCoach(newSelectedCoachProfile);
            }
        },
        [coachProfiles]
    );

    const selectedIncompleteRecaps = useMemo(
        () =>
            selectedCoach == null
                ? incompleteRecaps
                : incompleteRecaps.filter((incompleteRecap) =>
                      selectedCoach.coach_groups?.includes(
                          incompleteRecap.group_id ?? ''
                      )
                  ),
        [incompleteRecaps, selectedCoach]
    );

    return (
        <ContentScroller>
            <ListWrapper>
                <Bumper />
                <Bumper />
                <Bumper />
                <ScrollerWrapper>
                    <Bumper />
                    <Bumper />
                    <Fonts.Large>
                        {selectedIncompleteRecaps.length === 0
                            ? selectedCoach == null
                                ? `You're all set! No recent sessions have incomplete recaps.`
                                : `This coach's sessions have no incomplete recaps. If you would like to filter groups by their coach's name, select one from the dropdown.`
                            : `Please select a recent session to submit a recap. If you would like to filter groups by their coach's name, select one from the dropdown.`}
                    </Fonts.Large>
                    <Bumper />
                    <CoachSelectWrapper>
                        <Select
                            onInput={onChangeSelectedCoach}
                            value={selectedCoach?.full_name ?? ''}
                            // $size="small"
                            // $fontFamily="alt"
                        >
                            <option
                                value=""
                                label={
                                    selectedCoach == null
                                        ? 'Filter groups by coach?'
                                        : 'All Coaches'
                                }
                            />
                            {coachProfiles.map((coachProfile, index) => (
                                <option
                                    key={index}
                                    value={coachProfile.full_name ?? ''}
                                >
                                    {coachProfile.full_name}
                                </option>
                            ))}
                        </Select>
                    </CoachSelectWrapper>
                    <Bumper />
                    <RecapsWrapper>
                        {selectedIncompleteRecaps.map((incompleteRecap) => {
                            const sessionDate = new Date(
                                incompleteRecap.session_start ?? ''
                            );

                            return (
                                <GroupReportCard
                                    $linkEnabled={true}
                                    key={incompleteRecap.event_id}
                                    onClick={onSelectIncompleteRecap(
                                        incompleteRecap
                                    )}
                                >
                                    {isMediumScreenSize ? (
                                        <Fonts.Heading6
                                            $color={colors.mountainAsh}
                                        >
                                            {incompleteRecap.group_id}
                                        </Fonts.Heading6>
                                    ) : (
                                        <Fonts.Heading5
                                            $color={colors.mountainAsh}
                                        >
                                            {incompleteRecap.group_id}
                                        </Fonts.Heading5>
                                    )}
                                    <Fonts.Medium $color={colors.mountainAsh}>
                                        {
                                            coachProfiles.find((coachProfile) =>
                                                coachProfile.coach_groups?.includes(
                                                    incompleteRecap.group_id ??
                                                        ''
                                                )
                                            )?.full_name
                                        }
                                    </Fonts.Medium>
                                    <Bumper />
                                    <Fonts.Medium>
                                        {new Date(sessionDate).toLocaleString(
                                            'default',
                                            {
                                                timeZone: 'UTC',
                                                weekday: 'long',
                                                day: 'numeric',
                                                month: 'long',
                                                year: 'numeric',
                                            }
                                        )}
                                    </Fonts.Medium>
                                </GroupReportCard>
                            );
                        })}
                    </RecapsWrapper>
                </ScrollerWrapper>
            </ListWrapper>
        </ContentScroller>
    );
};
