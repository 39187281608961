import React from 'react';

import {
    LoaderWrapper,
    LoadingRingOne,
    LoadingRingTwo,
    LoadingRingThree,
} from 'styles/loader';

export const Loader = () => (
    <LoaderWrapper>
        <LoadingRingOne />
        <LoadingRingTwo />
        <LoadingRingThree />
    </LoaderWrapper>
);
