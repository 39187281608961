import React, { useCallback, useMemo, useEffect, useState } from 'react';
import {
    JoinedGroupProgramHistory,
    JoinedRepeatableSessionDetails,
    ModuleJoinedData,
    SessionDetailsModulesData,
    repeatableSessionDetailsQuery,
    sortSessionDetails,
    filterBankedSessionDetails,
    sortModules,
} from 'queries/groups';

import {
    IncompleteRecapData,
    GroupMember,
} from 'legacy/components/PendingRecaps';
import { GroupData } from 'legacy/components/GroupReports';
import { FormStep } from 'components/layout/SteppedForm';

import { supabase, useAuth } from 'hooks/useAuth';
import { useToast } from 'hooks/useToast';
import { usePrevious } from 'legacy/hooks/usePrevious';

import { deepEqual } from 'libs/utils';
import { EXIT_RESPONSES } from 'libs/constants';
import {
    SESSION_EXERCISES,
    ADMIN_TIMES,
    ADMIN_TYPES,
    ADMIN_TIME_THRESHOLDS,
    CLARITY_SESSION_TITLE,
} from 'legacy/libs/constants';

import { SteppedForm } from 'components/layout/SteppedForm';
import { RequiredAsterisk } from 'components/ui/RequiredAsterisk';
import { MarkdownWrapper } from 'components/ui/MarkdownWrapper';
import { TextInput } from 'components/ui/TextInput';
import { Select } from 'components/ui/Select';

import MultiSelectDropdown from 'legacy/components/MultiSectionDropdown';

import { Checkbox, CheckboxWrapper, NestedCard } from 'styles/shared';
import { Fonts, colors } from 'styles/theme';
import { Bumper } from 'styles/shared';
import {
    SessionExercisesWrapper,
    ObjectiveSelectorWrapper,
    ExplainerTextWrapper,
} from 'legacy/styles/coach';

import { Buttons } from 'legacy/styles/buttons';

type SessionExercise = (typeof SESSION_EXERCISES)[number];
type RecapExit = (typeof EXIT_RESPONSES)[number];
type AdminTime = (typeof ADMIN_TIMES)[number];
type AdminType = (typeof ADMIN_TYPES)[number];

interface Objective {
    id: string;
    title: string;
    description: string;
    session_details_modules: Array<SessionDetailsModulesData>;
    modules: Array<ModuleJoinedData>;
}

interface SubmitRecapProps {
    selectedIncompleteRecapId: string;
    onChangeFormStep: () => void;
    onClose: () => void;
}

export const SubmitRecap = (props: SubmitRecapProps) => {
    const { selectedIncompleteRecapId, onChangeFormStep, onClose } = props;

    const { userId, userProfile } = useAuth();
    const { showSuccessToast, showErrorToast } = useToast();

    const [selectedIncompleteRecap, setSelectedIncompleteRecap] =
        useState<IncompleteRecapData | null>(null);
    const [groupMembers, setGroupMembers] = useState<{
        [name: string]: GroupMember;
    }>({});

    const [internalGroupMembers, setInternalGroupMembers] = useState<{
        [name: string]: GroupMember;
    }>({});
    const [topics, setTopics] = useState<Array<string>>(['', '', '']);
    const [learnings, setLearnings] = useState<string>('');
    const [selectedExercises, setSelectedExercises] = useState<
        Array<SessionExercise>
    >([]);
    const [selectedExit, setSelectedExit] = useState<RecapExit | null>(null);
    const [adminTime, setAdminTime] = useState<AdminTime | null>(null);
    const [shouldShowAdminTypes, setShouldShowAdminTypes] =
        useState<boolean>(false);
    const [adminType, setAdminType] = useState<AdminType | null>(null);
    const [otherComments, setOtherComments] = useState<string>('');
    const [group, setGroup] = useState<GroupData | null>(null);
    const [repeatableSessionDetails, setRepeatableSessionDetails] =
        useState<JoinedRepeatableSessionDetails | null>(null);
    const [groupProgramHistory, setGroupProgramHistory] =
        useState<JoinedGroupProgramHistory | null>(null);
    const [modifiedGroupObjectives, setModifiedGroupObjectives] = useState<
        Array<Objective>
    >([]);
    const [currentBankedObjectives, setCurrentBankedObjectives] = useState<
        Array<Objective>
    >([]);
    const [isUpdatingObjectives, setIsUpdatingObjectives] =
        useState<boolean>(false);
    const [isSubmittingRecap, setIsSubmittingRecap] = useState<boolean>(false);

    useEffect(() => {
        const getSelectedIncompleteRecap = async () => {
            const { data, error } = await supabase
                .from('incomplete_recaps')
                .select('*')
                .eq('event_id', selectedIncompleteRecapId);

            if (error == null) {
                setSelectedIncompleteRecap(data[0] ?? null);
            } else {
                showErrorToast(error.message);
            }
        };
        getSelectedIncompleteRecap();
    }, [selectedIncompleteRecapId, showErrorToast]);

    useEffect(() => {
        const getGroupMembers = async () => {
            const { data, error } = await supabase
                .from('profiles')
                .select('*')
                .eq('is_active', true)
                .contains('member_groups', [
                    selectedIncompleteRecap?.group_id ?? '',
                ]);

            if (error == null) {
                const newGroupMembers: {
                    [name: string]: GroupMember;
                } = {};
                data.forEach((memberProfile) => {
                    newGroupMembers[memberProfile?.full_name ?? ''] = {
                        profile: memberProfile,
                        attended: false,
                        closingWords: '',
                    };
                });
                setGroupMembers(newGroupMembers);
            } else {
                showErrorToast(error.message);
            }
        };

        if (selectedIncompleteRecap == null) {
            setGroupMembers({});
        } else {
            getGroupMembers();
        }
    }, [selectedIncompleteRecap, showErrorToast]);

    const prevGroupMembers = usePrevious(groupMembers);
    useEffect(() => {
        if (prevGroupMembers !== groupMembers) {
            setInternalGroupMembers(groupMembers);
        }
    }, [groupMembers, prevGroupMembers]);

    const getGroup = useCallback(async () => {
        const { data, error } = await supabase
            .from('groups')
            .select('*')
            .eq('gid', selectedIncompleteRecap?.group_id ?? '');

        if (error == null) {
            setGroup(data[0]);
        } else {
            showErrorToast(error?.message);
        }
    }, [selectedIncompleteRecap?.group_id, showErrorToast]);

    useEffect(() => {
        getGroup();
    }, [getGroup]);

    useEffect(() => {
        const getRepeatableSessionDetails = async () => {
            const { data, error } = await repeatableSessionDetailsQuery;

            if (error == null) {
                setRepeatableSessionDetails(data);
            } else {
                showErrorToast(error.message);
            }
        };
        getRepeatableSessionDetails();
    }, [showErrorToast]);

    const getGroupProgramHistory = useCallback(async () => {
        // Cannot reuse groupProgramHistoryQuery function here or refetch
        // breaks due to an undiagnosed caching issue
        const { data, error } = await supabase
            .from('group_program_history')
            .select(
                `*,
                group_program_history_session_details!inner(*),
                session_details(
                    *,
                    session_details_modules!left(*),
                    modules(*,
                        exercises_frameworks(*)
                    )
                )
            `
            )
            .eq('id', group?.group_program_id ?? '');

        if (error == null) {
            const joinedGroupProgramHistory: JoinedGroupProgramHistory = data;
            setGroupProgramHistory(joinedGroupProgramHistory);
        } else {
            showErrorToast(error.message);
        }
    }, [group?.group_program_id, showErrorToast]);

    useEffect(() => {
        if (group != null && group?.group_program_id != null) {
            getGroupProgramHistory();
        }
    }, [getGroupProgramHistory, group]);

    const sortedSessionDetails = useMemo(
        () => sortSessionDetails(groupProgramHistory),
        [groupProgramHistory]
    );

    const bankedObjectives = useMemo(
        () =>
            filterBankedSessionDetails(groupProgramHistory).map(
                // Type check is working in development, but compiler flags on build
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                (sessionDetail) => ({
                    id: sessionDetail?.id ?? '',
                    title: sessionDetail?.title ?? '',
                    description: sessionDetail?.description ?? '',
                    session_details_modules:
                        sessionDetail?.session_details_modules ?? [],
                    modules: sessionDetail?.modules ?? [],
                })
            ),
        [groupProgramHistory]
    );

    useEffect(() => {
        setCurrentBankedObjectives(bankedObjectives);
    }, [bankedObjectives]);

    const currentGroupObjectivesArray = useMemo(() => {
        const objectivesToOrder =
            modifiedGroupObjectives.length > 0
                ? modifiedGroupObjectives
                : sortedSessionDetails;

        const returnArray = [];
        for (let i = 1; i <= (objectivesToOrder?.length ?? 0); i++) {
            returnArray.push({
                objective: objectivesToOrder[i - 1],
            });
        }

        return returnArray;
    }, [modifiedGroupObjectives, sortedSessionDetails]);

    const recapSessionNumber =
        group?.session_counter == null
            ? 0
            : group?.session_counter - (group?.has_session_today ? 0 : 1);

    const onUpdateAttendee = useCallback(
        (memberName: string) => (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            const newGroupMembers = { ...internalGroupMembers };
            if (newGroupMembers[memberName] != null) {
                // Null check above — TypeScript linter approves, but transpiler does not
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newGroupMembers[memberName].attended = target.checked;
            }
            setInternalGroupMembers(newGroupMembers);
        },
        [internalGroupMembers]
    );

    const onUpdateObjectiveSequence = useCallback(
        (sessionNumber: number) => (selectedValue: string) => {
            const sortedSessionDetailsClone =
                structuredClone(sortedSessionDetails);
            const sortedObjectives = sortedSessionDetailsClone.map(
                // Type check is working in development, but compiler flags on build
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                (sortedSessionDetailsDatum) => ({
                    id: sortedSessionDetailsDatum?.id ?? '',
                    title: sortedSessionDetailsDatum?.title ?? '',
                    description: sortedSessionDetailsDatum?.description ?? '',
                    session_details_modules:
                        sortedSessionDetailsDatum?.session_details_modules ??
                        [],
                    modules: sortedSessionDetailsDatum?.modules ?? [],
                })
            );
            const currentBankedObjectivesClone = structuredClone(
                currentBankedObjectives
            );
            const previouslySelectedObjective =
                modifiedGroupObjectives[sessionNumber - 1];
            const originalObjective =
                sortedSessionDetailsClone[sessionNumber - 1];

            if (
                typeof selectedValue === 'string' &&
                isNaN(parseInt(selectedValue, 10))
            ) {
                if (selectedValue === repeatableSessionDetails?.[0]?.title) {
                    sortedObjectives[sessionNumber - 1] = {
                        id: repeatableSessionDetails[0]?.id ?? '',
                        title: repeatableSessionDetails[0]?.title ?? '',
                        description:
                            repeatableSessionDetails[0]?.description ?? '',
                        session_details_modules:
                            repeatableSessionDetails[0]
                                ?.session_details_modules ?? [],
                        modules: repeatableSessionDetails[0]?.modules ?? [],
                    };
                    if (
                        originalObjective != null &&
                        originalObjective.title !==
                            repeatableSessionDetails[0].title
                    ) {
                        currentBankedObjectivesClone.push({
                            id: originalObjective.id,
                            title: originalObjective.title ?? '',
                            description: originalObjective.description ?? '',
                            session_details_modules:
                                originalObjective.session_details_modules ?? [],
                            modules: originalObjective.modules ?? [],
                        });
                        setCurrentBankedObjectives(
                            currentBankedObjectivesClone
                        );
                    } else {
                        setCurrentBankedObjectives(bankedObjectives);
                    }
                } else {
                    const selectedBankedObjective =
                        currentBankedObjectives.find(
                            (objectiveDatum: Objective) =>
                                objectiveDatum.title === selectedValue
                        );
                    const bankedObjectiveIndex =
                        currentBankedObjectivesClone.findIndex(
                            (objectiveDatum: Objective) =>
                                objectiveDatum.title === selectedValue
                        );
                    currentBankedObjectivesClone.splice(
                        bankedObjectiveIndex,
                        1
                    );

                    if (
                        previouslySelectedObjective != null &&
                        bankedObjectives.find(
                            (objectiveDatum: Objective) =>
                                objectiveDatum.title ===
                                previouslySelectedObjective?.title
                        ) != null
                    ) {
                        currentBankedObjectivesClone.push(
                            previouslySelectedObjective
                        );
                    } else if (
                        originalObjective != null &&
                        originalObjective.title !==
                            repeatableSessionDetails?.[0]?.title &&
                        originalObjective.title !== CLARITY_SESSION_TITLE &&
                        currentBankedObjectives.find(
                            (objectiveDatum: Objective) =>
                                objectiveDatum.title === originalObjective.title
                        ) == null
                    ) {
                        currentBankedObjectivesClone.push({
                            id: originalObjective.id,
                            title: originalObjective.title ?? '',
                            description: originalObjective.description ?? '',
                            session_details_modules:
                                originalObjective.session_details_modules ?? [],
                            modules: originalObjective.modules ?? [],
                        });
                    }

                    if (selectedBankedObjective != null) {
                        sortedObjectives[sessionNumber - 1] =
                            selectedBankedObjective;
                    }
                    setCurrentBankedObjectives(currentBankedObjectivesClone);
                }
            } else {
                if (
                    originalObjective != null &&
                    modifiedGroupObjectives[parseInt(selectedValue, 10)]
                        ?.title !== originalObjective.title
                ) {
                    const newSelectedObjective =
                        sortedObjectives[parseInt(selectedValue, 10)];
                    if (newSelectedObjective != null) {
                        sortedObjectives[sessionNumber - 1] =
                            newSelectedObjective;
                    }
                    sortedObjectives[parseInt(selectedValue, 10)] = {
                        id: originalObjective.id,
                        title: originalObjective.title ?? '',
                        description: originalObjective.description ?? '',
                        session_details_modules:
                            originalObjective.session_details_modules ?? [],
                        modules: originalObjective.modules ?? [],
                    };
                }

                setCurrentBankedObjectives(bankedObjectives);
            }

            setModifiedGroupObjectives(sortedObjectives);
        },
        [
            bankedObjectives,
            currentBankedObjectives,
            modifiedGroupObjectives,
            repeatableSessionDetails,
            sortedSessionDetails,
        ]
    );

    const onToggleExercise = useCallback(
        (exercise: SessionExercise) =>
            (event: React.FormEvent<HTMLButtonElement>) => {
                event.preventDefault();

                const newSelectedExercises = [...selectedExercises];
                const exerciseIndex = newSelectedExercises.findIndex(
                    (selectedExercise) => selectedExercise === exercise
                );
                if (exerciseIndex === -1) {
                    newSelectedExercises.push(exercise);
                } else {
                    newSelectedExercises.splice(exerciseIndex, 1);
                }
                setSelectedExercises(newSelectedExercises);
            },
        [selectedExercises]
    );

    const onChangeTopic = useCallback(
        (topicIndex: number) => (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            const newTopics = [...topics];
            newTopics[topicIndex] = target.value;
            setTopics(newTopics);
        },
        [topics]
    );

    const onUpdateClosingWords = useCallback(
        (memberName: string) => (event: React.FormEvent<HTMLInputElement>) => {
            const newGroupMembers = { ...internalGroupMembers };
            const target = event.target as HTMLInputElement;
            if (newGroupMembers[memberName] != null) {
                // Null check above — TypeScript linter approves, but transpiler does not
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newGroupMembers[memberName].closingWords = target.value;
            }
        },
        [internalGroupMembers]
    );

    const onLearningsInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setLearnings(target.value);
        },
        []
    );

    const onChangeExit = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setSelectedExit(target.value as RecapExit);
        },
        []
    );

    const onChangeAdminTime = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setAdminTime(target.value as AdminTime);
        },
        []
    );

    useEffect(() => {
        if (adminTime != null && ADMIN_TIME_THRESHOLDS.includes(adminTime)) {
            setShouldShowAdminTypes(true);
        } else {
            setShouldShowAdminTypes(false);
            setAdminType(null);
        }
    }, [adminTime]);

    const onChangeAdminType = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setAdminType(target.value as AdminType);
        },
        []
    );

    const onOtherCommentsInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setOtherComments(target.value);
        },
        []
    );

    const onSubmitObjectives = useCallback(async () => {
        if (
            modifiedGroupObjectives.length > 0 &&
            !deepEqual(
                // Type check is working in development, but compiler flags on build
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                sortedSessionDetails.map((sortedSessionDetailsDatum) => ({
                    id: sortedSessionDetailsDatum?.id ?? '',
                    title: sortedSessionDetailsDatum?.title ?? '',
                    description: sortedSessionDetailsDatum?.description ?? '',
                })),
                modifiedGroupObjectives
            )
        ) {
            if (isUpdatingObjectives) {
                return;
            }
            setIsUpdatingObjectives(true);

            const {
                data: groupProgramHistoryData,
                error: groupProgramHistoryError,
            } = await supabase
                .from('group_program_history')
                .insert({
                    program_title: groupProgramHistory?.[0]?.program_title,
                    group_id: selectedIncompleteRecap?.group_id,
                    published_by: userProfile?.full_name,
                    publish_notes: 'Sessions reordered during recap',
                })
                .select();
            if (groupProgramHistoryError != null) {
                showErrorToast(groupProgramHistoryError.message);
                setIsUpdatingObjectives(false);
                return;
            }

            const { error: groupProgramHistorySessionDetailsError } =
                await supabase
                    .from('group_program_history_session_details')
                    .insert(
                        modifiedGroupObjectives
                            .map((modifiedGroupObjectivesDatum, index) => ({
                                group_program_history_id:
                                    groupProgramHistoryData?.[0]?.id ?? '',
                                session_details_id:
                                    modifiedGroupObjectivesDatum.id,
                                session_number: index + 1,
                                is_banked: false,
                            }))
                            .concat(
                                currentBankedObjectives.map(
                                    (currentBankedObjectivesDatum, index) => ({
                                        group_program_history_id:
                                            groupProgramHistoryData?.[0]?.id ??
                                            '',
                                        session_details_id:
                                            currentBankedObjectivesDatum.id,
                                        session_number: index + 1,
                                        is_banked: true,
                                    })
                                )
                            )
                    );
            if (groupProgramHistorySessionDetailsError != null) {
                showErrorToast(groupProgramHistorySessionDetailsError.message);
                setIsUpdatingObjectives(false);
                return;
            }

            const { error: groupUpdateError } = await supabase
                .from('groups')
                .update({
                    group_program_id: groupProgramHistoryData?.[0]?.id,
                })
                .eq('gid', selectedIncompleteRecap?.group_id ?? '');

            if (groupUpdateError == null) {
                showSuccessToast('Your group objectives have been updated!');
                setModifiedGroupObjectives([]);
                setCurrentBankedObjectives([]);
                await getGroup();
                await getGroupProgramHistory();
            } else {
                showErrorToast(groupUpdateError.message);
            }

            setIsUpdatingObjectives(false);
        }
    }, [
        currentBankedObjectives,
        getGroup,
        getGroupProgramHistory,
        groupProgramHistory,
        isUpdatingObjectives,
        modifiedGroupObjectives,
        selectedIncompleteRecap?.group_id,
        showErrorToast,
        showSuccessToast,
        sortedSessionDetails,
        userProfile?.full_name,
    ]);

    const onSubmitRecap = useCallback(async () => {
        if (isSubmittingRecap) {
            return;
        }
        setIsSubmittingRecap(true);

        // The type inference for this insertion causes type warnings on raw_data
        //
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { error } = await supabase.from('recaps').insert({
            created_by: userId,
            group_id: selectedIncompleteRecap?.group_id ?? '',
            session_date: selectedIncompleteRecap?.session_start ?? '',
            is_hq: userProfile?.is_hq,
            raw_data: {
                group_id: selectedIncompleteRecap?.group_id ?? '',
                coach_id: userId,
                coach_name: userProfile?.full_name,
                is_hq: userProfile?.is_hq,
                event_id: selectedIncompleteRecap?.event_id ?? '',
                session: {
                    aid: selectedIncompleteRecap?.aid,
                    session_date: selectedIncompleteRecap?.session_start ?? '',
                },
                expected_attendees: Object.keys(internalGroupMembers),
                present_attendees: Object.keys(internalGroupMembers).filter(
                    (memberName) =>
                        internalGroupMembers[memberName]?.attended === true
                ),
                closing_words: Object.keys(internalGroupMembers).map(
                    (memberName) =>
                        internalGroupMembers[memberName]?.closingWords
                ),
                attributed_closing_words: Object.keys(internalGroupMembers).map(
                    (memberName) =>
                        `${memberName}~~${internalGroupMembers[memberName]?.closingWords}`
                ),
                topic1: topics[0],
                topic2: topics[1],
                topic3: topics[2],
                learnings,
                selected_exercises: selectedExercises,
                exit: selectedExit,
                other: otherComments,
                admin_time: adminTime,
                admin_type: adminType,
            },
        });

        if (error == null) {
            showSuccessToast('Your session recap has been submitted!');
        } else {
            showErrorToast(error.message);
        }

        setIsSubmittingRecap(false);
    }, [
        isSubmittingRecap,
        userId,
        selectedIncompleteRecap?.group_id,
        selectedIncompleteRecap?.session_start,
        selectedIncompleteRecap?.event_id,
        selectedIncompleteRecap?.aid,
        userProfile?.is_hq,
        userProfile?.full_name,
        internalGroupMembers,
        topics,
        learnings,
        selectedExercises,
        selectedExit,
        otherComments,
        adminTime,
        adminType,
        showSuccessToast,
        showErrorToast,
    ]);

    const renderObjectiveSelect = useCallback(
        (sessionNumber: number) => {
            const multiSelectDropdownSections = [
                {
                    headerText: `Your group's current curriculum:`,
                    options: currentGroupObjectivesArray.map(
                        (objectiveDatum, index) => ({
                            value: index.toString(),
                            label: objectiveDatum.objective?.title ?? '',
                            isDisabled: index + 1 < sessionNumber,
                        })
                    ),
                },
                {
                    headerText:
                        'Additional Clarity Council or Knowledge Share, if needed:',
                    options: [
                        {
                            value: repeatableSessionDetails?.[0]?.title ?? '',
                            label: repeatableSessionDetails?.[0]?.title ?? '',
                        },
                    ],
                },
            ];

            if (currentBankedObjectives.length > 0) {
                multiSelectDropdownSections.push({
                    headerText: `Objectives you've moved out of your curriculum:`,
                    options: currentBankedObjectives.map((objectiveDatum) => ({
                        value: objectiveDatum.title,
                        label: objectiveDatum.title,
                    })),
                });
            }

            return (
                <MultiSelectDropdown
                    onSelectOption={onUpdateObjectiveSequence(sessionNumber)}
                    selectedOptionIndex={sessionNumber - 1}
                    sections={multiSelectDropdownSections}
                />
            );
        },
        [
            currentBankedObjectives,
            currentGroupObjectivesArray,
            onUpdateObjectiveSequence,
            repeatableSessionDetails,
        ]
    );

    const formSteps: Array<FormStep> = useMemo(() => {
        const formStepsReturnArray = [];
        formStepsReturnArray.push({
            isComplete: true,
            formNode: (
                <>
                    <NestedCard>
                        <Fonts.Heading5>{`Attendance`}</Fonts.Heading5>
                        <Bumper />
                        <Fonts.Medium>
                            {`Please check the box next to the names who have attended. The attendance will not be shared with your group.`}
                        </Fonts.Medium>
                        <Bumper />
                        <Bumper />
                        {Object.keys(internalGroupMembers).map((memberName) => (
                            <>
                                <CheckboxWrapper
                                    key={
                                        internalGroupMembers[memberName]
                                            ?.profile.id
                                    }
                                >
                                    <Checkbox
                                        type="checkbox"
                                        name="share"
                                        onChange={onUpdateAttendee(memberName)}
                                        checked={
                                            internalGroupMembers[memberName]
                                                ?.attended
                                        }
                                    />
                                    <Fonts.Small>{memberName}</Fonts.Small>
                                </CheckboxWrapper>
                                <Bumper />
                            </>
                        ))}
                        <Fonts.XxSmall>{`Your responses below will generate an email recap for you to review and share with your group (we don't send emails on your behalf). To ensure member confidentiality, please do not include specific names. Instead you can say “one founder” or “one member.” The Grand will reference these anonymized responses in monthly reports to partners.`}</Fonts.XxSmall>
                    </NestedCard>
                </>
            ),
        });

        if (currentGroupObjectivesArray.length > 0) {
            const sortedModules = sortModules(
                currentGroupObjectivesArray?.[recapSessionNumber - 1]?.objective
                    ?.session_details_modules ?? [],
                currentGroupObjectivesArray?.[recapSessionNumber - 1]?.objective
                    ?.modules ?? []
            );
            formStepsReturnArray.push({
                isComplete: true,
                onSubmitStep: onSubmitObjectives,
                formNode: (
                    <>
                        <NestedCard>
                            <Fonts.Heading5>{`Session Objectives`}</Fonts.Heading5>
                            <Bumper />
                            <Fonts.Medium>
                                {`The intended session objective was to address `}
                                <Fonts.MediumQuote
                                    as="span"
                                    $color={colors.mountainAsh}
                                >
                                    {
                                        sortedSessionDetails[
                                            recapSessionNumber - 1
                                        ]?.title
                                    }
                                </Fonts.MediumQuote>
                                {`. If you've made any adjustments, please update using the options below.`}
                            </Fonts.Medium>
                            <Bumper />
                            <Bumper />
                            <ObjectiveSelectorWrapper>
                                {renderObjectiveSelect(recapSessionNumber)}
                                <Bumper />
                                {(currentGroupObjectivesArray?.[
                                    recapSessionNumber - 1
                                ]?.objective?.description?.length ?? 0) > 0 ? (
                                    <MarkdownWrapper
                                        markdownString={
                                            currentGroupObjectivesArray?.[
                                                recapSessionNumber - 1
                                            ]?.objective?.description ?? ''
                                        }
                                    />
                                ) : (
                                    sortedModules.map((module, index) => (
                                        <MarkdownWrapper
                                            key={index}
                                            markdownString={
                                                module?.description ?? ''
                                            }
                                        />
                                    ))
                                )}
                            </ObjectiveSelectorWrapper>
                        </NestedCard>
                    </>
                ),
            });
        }

        formStepsReturnArray.push({
            isComplete: !topics.includes(''),
            formNode: (
                <>
                    <NestedCard>
                        <Fonts.Heading5>{`Topics`}</Fonts.Heading5>
                        <Bumper />
                        <Fonts.Medium>{`Your responses below will automatically be shared with all coaches in the #recaps-and-support Slack channel and recap email template.`}</Fonts.Medium>
                        <Bumper />
                        <Bumper />
                        <Fonts.Medium>{`Top 3 Session Topics`}</Fonts.Medium>
                        <Bumper />
                        {(() => {
                            const selectOptions = [];
                            for (let i = 0; i <= 2; i++) {
                                selectOptions.push(
                                    <>
                                        <TextInput
                                            key={i}
                                            type="text"
                                            placeholder="Topic"
                                            value={topics[i]}
                                            onInput={onChangeTopic(i)}
                                            $isError={false}
                                        />
                                        <Bumper />
                                    </>
                                );
                            }
                            return selectOptions;
                        })()}
                    </NestedCard>
                </>
            ),
        });
        formStepsReturnArray.push({
            isComplete: true,
            formNode: (
                <>
                    <NestedCard>
                        <Fonts.Heading5>{`Exercises`}</Fonts.Heading5>
                        <Bumper />
                        <Fonts.Medium>{`Please select all the exercises that were used during the session. If they are not on this list, please share what you tried, in the next section below.`}</Fonts.Medium>
                        <Bumper />
                        <SessionExercisesWrapper>
                            {SESSION_EXERCISES.map((exercise) => (
                                <Buttons.Pill
                                    key={exercise}
                                    onClick={onToggleExercise(exercise)}
                                    $isSelected={selectedExercises.includes(
                                        exercise
                                    )}
                                >
                                    <Buttons.OverlayMedium />
                                    <Fonts.XSmall
                                        $color={
                                            selectedExercises.includes(exercise)
                                                ? colors.white
                                                : colors.grandGreen
                                        }
                                    >
                                        {exercise}
                                    </Fonts.XSmall>
                                </Buttons.Pill>
                            ))}
                        </SessionExercisesWrapper>
                    </NestedCard>
                </>
            ),
        });

        if (
            Object.values(internalGroupMembers).find(
                (groupMember) => groupMember.attended === true
            ) != null
        ) {
            formStepsReturnArray.push({
                isComplete: true,
                formNode: (
                    <>
                        <NestedCard>
                            <Fonts.Heading5>{`Closing Words`}</Fonts.Heading5>
                            <Bumper />
                            <Fonts.Medium>
                                {`Please include the closing words for each attended member.`}
                            </Fonts.Medium>
                            <Bumper />
                            {Object.keys(internalGroupMembers).map(
                                (memberName) =>
                                    internalGroupMembers[memberName]
                                        ?.attended ? (
                                        <>
                                            <Bumper />
                                            <ExplainerTextWrapper>
                                                <Fonts.Medium>
                                                    {memberName}
                                                </Fonts.Medium>
                                            </ExplainerTextWrapper>
                                            <TextInput
                                                type="text"
                                                placeholder="(Closing word...)"
                                                onInput={onUpdateClosingWords(
                                                    memberName
                                                )}
                                                $isError={false}
                                            />
                                            <Bumper />
                                        </>
                                    ) : null
                            )}
                        </NestedCard>
                    </>
                ),
            });
        }

        formStepsReturnArray.push({
            isComplete: learnings !== '',
            formNode: (
                <>
                    <NestedCard>
                        <Fonts.Heading5>{`Your Learnings`}</Fonts.Heading5>
                        <Bumper />
                        <Fonts.Medium>{`What are some learnings from this session that would be helpful to other coaches? Please include at least one thought, observation, or technique you tried. Do not input N/A unless no one showed up to your session.`}</Fonts.Medium>
                        <Bumper />
                        <Bumper />
                        <TextInput
                            isTextArea
                            id="learnings"
                            name="learnings"
                            value={learnings}
                            onInput={onLearningsInput}
                        />
                    </NestedCard>
                </>
            ),
        });
        formStepsReturnArray.push({
            isComplete:
                selectedExit != null &&
                adminTime != null &&
                (!shouldShowAdminTypes || adminType != null),
            onSubmitStep: onSubmitRecap,
            submitText: 'Submit',
            formNode: (
                <>
                    <NestedCard>
                        <Fonts.Heading5>{`Your session and product feedback`}</Fonts.Heading5>
                        <Bumper />
                        <Fonts.Medium>{`The next four answers will only be shared with The Grand product team to help improve your experience.`}</Fonts.Medium>
                        <Bumper />
                        <Bumper />
                        <Fonts.MediumQuote $color={colors.mountainAsh}>
                            {`"The Grand’s training and support helped me feel prepared for this session."`}
                            <RequiredAsterisk />
                        </Fonts.MediumQuote>
                        <Bumper />
                        <Select
                            onInput={onChangeExit}
                            value={selectedExit ?? ''}
                        >
                            <option value="" hidden>{`Select`}</option>
                            {EXIT_RESPONSES.map((closingExit) => (
                                <option key={closingExit} value={closingExit}>
                                    {closingExit}
                                </option>
                            ))}
                        </Select>
                        <Bumper />
                        <Bumper />
                        <Fonts.Medium>
                            {`Select the amount of admin time you spent on this session:`}
                            <RequiredAsterisk />
                        </Fonts.Medium>
                        <Bumper />
                        <Select
                            onInput={onChangeAdminTime}
                            value={adminTime ?? ''}
                        >
                            <option value="" label="Select" hidden />
                            {ADMIN_TIMES.map((adminTimeOption) => (
                                <option
                                    key={adminTimeOption}
                                    value={adminTimeOption}
                                >
                                    {adminTimeOption}
                                </option>
                            ))}
                        </Select>
                        <Bumper />
                        {shouldShowAdminTypes ? (
                            <>
                                <Bumper />
                                <Fonts.Medium>
                                    {`Where did you spend most of your admin time?`}
                                    <RequiredAsterisk />
                                </Fonts.Medium>
                                <Bumper />
                                <Select
                                    onInput={onChangeAdminType}
                                    value={adminType ?? ''}
                                >
                                    <option value="" label="Select" hidden />
                                    {ADMIN_TYPES.map((adminTypeOption) => (
                                        <option
                                            key={adminTypeOption}
                                            value={adminTypeOption}
                                        >
                                            {adminTypeOption}
                                        </option>
                                    ))}
                                </Select>
                                <Bumper />
                            </>
                        ) : null}
                        <Bumper />
                        <Fonts.Medium>{`Is there anything else you'd like to share?`}</Fonts.Medium>
                        <Bumper />
                        <TextInput
                            isTextArea
                            id="other-comments"
                            name="other-comments"
                            value={otherComments}
                            onInput={onOtherCommentsInput}
                        />
                    </NestedCard>
                </>
            ),
        });

        if (
            recapSessionNumber < sortedSessionDetails.length &&
            currentGroupObjectivesArray.length > 0
        ) {
            const sortedModules = sortModules(
                currentGroupObjectivesArray?.[recapSessionNumber]?.objective
                    ?.session_details_modules ?? [],
                currentGroupObjectivesArray?.[recapSessionNumber]?.objective
                    ?.modules ?? []
            );
            formStepsReturnArray.push({
                isComplete: true,
                onSubmitStep: onSubmitObjectives,
                shouldHideProgress: true,
                formNode: (
                    <>
                        <NestedCard>
                            <Fonts.Heading5>{`Thanks for your recap!`}</Fonts.Heading5>
                            <Bumper />
                            <Fonts.Medium>
                                {`For your next `}
                                <Fonts.Medium $isOpenSansSemiBold as="span">
                                    {selectedIncompleteRecap?.group_id}
                                </Fonts.Medium>
                                {` session, your objectives will cover `}
                                <Fonts.MediumQuote
                                    as="span"
                                    $color={colors.mountainAsh}
                                >
                                    {
                                        sortedSessionDetails[recapSessionNumber]
                                            ?.title
                                    }
                                </Fonts.MediumQuote>
                            </Fonts.Medium>
                            <Bumper />
                            <Fonts.Medium>{`If you do not intend cover the objective, please update changes below.`}</Fonts.Medium>
                            <Bumper />
                            <Bumper />
                            <ObjectiveSelectorWrapper>
                                {renderObjectiveSelect(recapSessionNumber + 1)}
                                <Bumper />
                                {(currentGroupObjectivesArray?.[
                                    recapSessionNumber
                                ]?.objective?.description?.length ?? 0) > 0 ? (
                                    <MarkdownWrapper
                                        markdownString={
                                            currentGroupObjectivesArray?.[
                                                recapSessionNumber
                                            ]?.objective?.description ?? ''
                                        }
                                    />
                                ) : (
                                    sortedModules.map((module, index) => (
                                        <MarkdownWrapper
                                            key={index}
                                            markdownString={
                                                module?.description ?? ''
                                            }
                                        />
                                    ))
                                )}
                            </ObjectiveSelectorWrapper>
                        </NestedCard>
                    </>
                ),
            });
        }

        return formStepsReturnArray;
    }, [
        adminTime,
        adminType,
        currentGroupObjectivesArray,
        internalGroupMembers,
        learnings,
        onChangeAdminTime,
        onChangeAdminType,
        onChangeExit,
        onChangeTopic,
        onLearningsInput,
        onOtherCommentsInput,
        onSubmitObjectives,
        onSubmitRecap,
        onToggleExercise,
        onUpdateAttendee,
        onUpdateClosingWords,
        otherComments,
        recapSessionNumber,
        renderObjectiveSelect,
        selectedExercises,
        selectedExit,
        selectedIncompleteRecap?.group_id,
        shouldShowAdminTypes,
        sortedSessionDetails,
        topics,
    ]);

    return (
        <SteppedForm
            formSteps={formSteps}
            onChangeFormStep={onChangeFormStep}
            onClose={onClose}
            isLoadingSubmission={isUpdatingObjectives || isSubmittingRecap}
            $isBottomAreaWhite
        />
    );
};
