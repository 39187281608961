import React, { useMemo } from 'react';

import { useMemberGroupData } from 'hooks/useMemberGroupData';

import { formatDate } from 'libs/utils';

import { DimensionResponses } from 'components/ui/DimensionResponses';

import { colors, Fonts, spacing } from 'styles/theme';
import { Bumper, NestedCard } from 'styles/shared';

interface RetroResultsProps {
    retroId: string;
}

export const RetroResults = ({ retroId }: RetroResultsProps) => {
    const { retros } = useMemberGroupData();

    const selectedRetro = useMemo(
        () => retros.find((retro) => retro.id === parseInt(retroId, 10)),
        [retroId, retros]
    );

    return (
        <>
            <Fonts.Heading5>{`Your Grand Retro`}</Fonts.Heading5>
            <Fonts.Medium $color={colors.mountainAsh}>
                {formatDate(new Date(selectedRetro?.created_at ?? 0))}
            </Fonts.Medium>
            <Bumper />
            <Fonts.Medium>{`Here are the totals for each of your dimensions.`}</Fonts.Medium>
            <Bumper />
            <DimensionResponses retroDataPayload={selectedRetro as object} />
            <Bumper />
            <NestedCard>
                <Fonts.Medium $isOpenSansSemiBold>
                    {`Your reflections:`}
                </Fonts.Medium>
                <Bumper $minHeight={spacing.xxxSmall} />
                <Fonts.MediumQuote $color={colors.mountainAsh}>
                    {selectedRetro?.reflections ?? ''}
                </Fonts.MediumQuote>
                <Bumper />
                <Fonts.Medium $isOpenSansSemiBold>
                    {`Your reactions:`}
                </Fonts.Medium>
                <Bumper $minHeight={spacing.xxxSmall} />
                <Fonts.MediumQuote $color={colors.mountainAsh}>
                    {selectedRetro?.reactions ?? ''}
                </Fonts.MediumQuote>
            </NestedCard>
        </>
    );
};
