import styled from 'styled-components';

import { spacing } from 'styles/theme';

export const TopLeftQuoteWrapper = styled.div`
    position: absolute;
    // Custom positioning for symmetry
    top: -18px;
    left: ${spacing.xxxSmall};
`;

export const BottomRightQuoteWrapper = styled.div`
    position: absolute;
    // Custom positioning for symmetry
    bottom: -44px;
    right: ${spacing.xxxSmall};
`;
