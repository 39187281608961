import { Database } from 'libs/supabaseTypes';

import React, { useCallback, useMemo } from 'react';

import { useMemberGroupData } from 'hooks/useMemberGroupData';

import { formatDate, getRelationshipToToday } from 'libs/utils';

import { InfoCard } from 'components/ui/InfoCard';

import { GridListWrapper } from 'styles/shared';
import { useSearchParams } from 'react-router-dom';

export type RetroData = Database['public']['Tables']['retros']['Row'];

export const RetroList = () => {
    const { retros } = useMemberGroupData();

    const [, setSearchParams] = useSearchParams();

    const hasCompletedRetroToday = useMemo(() => {
        let isRetroDateToday = false;
        retros.forEach((retro) => {
            if (
                getRelationshipToToday(new Date(retro.created_at ?? 0)) ===
                'current'
            ) {
                isRetroDateToday = true;
            }
        });
        return isRetroDateToday;
    }, [retros]);

    const onSelectNewRetro = useCallback(() => {
        setSearchParams({
            modalContent: 'retro',
        });
    }, [setSearchParams]);

    const onSelectPreviousRetro = useCallback(
        (retroId: number) => () => {
            setSearchParams({
                modalContent: 'retro-results',
                retroId: retroId.toString(),
            });
        },
        [setSearchParams]
    );

    return (
        <GridListWrapper>
            {hasCompletedRetroToday ? null : (
                <InfoCard
                    dateRelationship="current"
                    headerText={formatDate(new Date())}
                    mainText="Start New Grand Retro"
                    onClick={onSelectNewRetro}
                />
            )}
            {retros.map((retro) => (
                <InfoCard
                    key={retro.period}
                    dateRelationship="previous"
                    headerText={formatDate(new Date(retro.created_at ?? 0))}
                    mainText="Grand Retro Results"
                    onClick={onSelectPreviousRetro(retro.id)}
                />
            ))}
        </GridListWrapper>
    );
};
