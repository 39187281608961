import { css, styled } from 'styled-components';
import { spacing, Fonts, shadows, colors } from 'styles/theme';

const REPORT_CONTENT_WIDTH = 1000;

export interface GroupReportCardProps {
    $linkEnabled: boolean;
}

export const LargeNumberText = styled(Fonts.Heading6)`
    font-family: 'OpenSans-SemiBold';
    line-height: 64px;
`;

export const BodyLink = styled(Fonts.Medium)`
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        opacity: 0.7;
    }
`;

export const GroupReportListWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;

    @media only screen and (max-width: ${spacing.mobileBreakpoint}) {
        width: 100vw;
        padding: 0 16px;
    }
`;

export const GroupReportCard = styled.div<GroupReportCardProps>`
    margin: 12px;
    box-shadow: ${shadows.smallBase};
    border-radius: 8px;
    background-color: ${colors.light};
    width: 300px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    text-align: left;

    @media only screen and (max-width: ${spacing.mobileBreakpoint}) {
        width: 100%;
        max-width: 300px;
    }

    ${(props) =>
        props.$linkEnabled
            ? css`
                  &:hover {
                      filter: brightness(95%);
                  }
                  cursor: pointer;
                  transition: 0.2s;
              `
            : null};
`;

export const MainWrapper = styled.div`
    background-color: ${colors.light};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
`;

export const Heading = styled(Fonts.Heading1)`
    background-color: ${colors.darkJungle};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
`;

export const TopBarWrapper = styled.div`
    width: ${REPORT_CONTENT_WIDTH}px;
    padding: 0px 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: ${spacing.mobileBreakpoint}) {
        width: 100%;
        padding: 32px;
        flex-direction: column;
    }
`;

export const TopBarText = styled.div`
    max-width: 400px;
    text-align: left;

    @media only screen and (max-width: ${spacing.mobileBreakpoint}) {
        margin-top: 24px;
    }
`;

export const CumulativeDataWrapper = styled.div`
    width: ${REPORT_CONTENT_WIDTH}px;
    padding: 40px 80px;
    display: flex;
    flex-direction: column;
    align-items: start;

    @media only screen and (max-width: ${spacing.mobileBreakpoint}) {
        width: 100%;
        padding: 32px 32px 12px;
    }
`;

export const CumulativeDataContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: ${spacing.mobileBreakpoint}) {
        flex-direction: column;
    }
`;

export const CumulativeDataSection = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;

    @media only screen and (max-width: ${spacing.mobileBreakpoint}) {
        width: 100%;
        margin-bottom: 20px;
    }
`;

export const BulletList = styled.ol`
    list-style: outside;
    text-align: left;
`;

export const BulletListItem = styled(Fonts.Medium)`
    line-height: 24px;
`;

export const ReportDetailsWrapper = styled.div`
    width: ${REPORT_CONTENT_WIDTH}px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: ${spacing.mobileBreakpoint}) {
        padding: 0 24px;
        width: 100%;
    }
`;

export const RatingOuterWrapper = styled.div`
    margin-top: 32px;
    border: 1px solid ${colors.grey10};
    background-color: ${colors.white};
    width: 800px;
    padding: 32px 48px;
    display: flex;
    align-items: center;
    text-align: left;

    @media only screen and (max-width: ${spacing.mobileBreakpoint}) {
        margin-top: 20px;
        width: 100%;
        padding: 20px 32px;
    }
`;

export const RatingImageWrapper = styled.div`
    margin-left: 32px;
    border-radius: 16px;
    background-color: ${colors.grandGreen};
    padding: 12px;
    cursor: pointer;

    @media only screen and (max-width: ${spacing.mobileBreakpoint}) {
        margin-left: 20px;
        padding: 12px;
    }
`;

export const Divider = styled.div`
    width: 100%;
    min-height: 1px;
    background-color: ${colors.grey10};
`;
