import React, { useMemo } from 'react';
import { useMatch } from 'react-router-dom';

import { useNavigateWithParams } from 'hooks/useNavigateWithParams';

import { Closings } from 'components/layout/Closings';
import { GroupLearnings } from 'components/layout/GroupLearnings';
import { RetroList } from 'components/layout/RetroList';

import { Tab, TabControls } from 'components/ui/TabControls';

import Telescope from 'assets/telescope.png';
import Birds from 'assets/birds.png';

import { Fonts, spacing } from 'styles/theme';
import {
    Card,
    ContentScroller,
    CustomizableWidthWrapper,
    Bumper,
    BannerWrapper,
} from 'styles/shared';

export const Insights = () => {
    const navigateWithParams = useNavigateWithParams();

    const myLearningsRoute = useMatch(`/member/insights/my-learnings/*`);
    const groupLearningsRoute = useMatch(`/member/insights/group-learnings/*`);
    const retroRoute = useMatch(`/member/insights/grand-retro/*`);

    const insightsTabs: Array<Tab> = useMemo(
        () => [
            {
                text: 'My Learnings',
                isSelected: myLearningsRoute != null,
                onClick: () =>
                    navigateWithParams(`/member/insights/my-learnings`),
            },
            {
                text: 'Group Learnings',
                isSelected: groupLearningsRoute != null,
                onClick: () =>
                    navigateWithParams(`/member/insights/group-learnings`),
            },
            {
                text: 'The Grand Retro',
                isSelected: retroRoute != null,
                onClick: () =>
                    navigateWithParams(`/member/insights/grand-retro`),
            },
        ],
        [myLearningsRoute, groupLearningsRoute, retroRoute, navigateWithParams]
    );

    return (
        <ContentScroller>
            <Bumper $minHeight={spacing.xxxLarge} />
            <Bumper $minHeight={spacing.xxxLarge} />
            <Card>
                <BannerWrapper
                    $backgroundImageUrl={retroRoute == null ? Telescope : Birds}
                >
                    <Fonts.Heading5>
                        {retroRoute == null ? `Insights` : `Your Grand Retros`}
                    </Fonts.Heading5>
                    {retroRoute == null ? null : (
                        <>
                            <Bumper />
                            <CustomizableWidthWrapper $width="330px">
                                <Fonts.Medium>
                                    {`This is your place to track your personal growth, stay accountable to your goals, and track your session learnings. Reflecting is your way to track progress.`}
                                </Fonts.Medium>
                            </CustomizableWidthWrapper>
                        </>
                    )}
                </BannerWrapper>
                <Bumper $minHeight={spacing.xxxLarge} />
                <TabControls tabs={insightsTabs} $isFlexStart />
                <Bumper
                    $minHeight={
                        retroRoute == null ? spacing.xLarge : spacing.xxSmall
                    }
                />
                {myLearningsRoute == null ? null : <Closings />}
                {groupLearningsRoute == null ? null : <GroupLearnings />}
                {retroRoute == null ? null : <RetroList />}
            </Card>
            <Bumper $minHeight={spacing.xxxLarge} />
        </ContentScroller>
    );
};
