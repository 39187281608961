import styled, { css } from 'styled-components';

import { colors, spacing, transparencies } from 'styles/theme';

const TAB_CONTROL_WRAPPER_BORDER_WIDTH = '1px';

interface TabControlsProps {
    $isFlexStart?: boolean;
}

export const TabControlsWrapper = styled.div<TabControlsProps>`
    border-bottom: ${TAB_CONTROL_WRAPPER_BORDER_WIDTH} solid
        ${transparencies.darkJungle20};
    width: 100%;
    display: flex;
    justify-content: space-between;

    ${(props) =>
        props.$isFlexStart == null
            ? null
            : css`
                  justify-content: flex-start;
              `};
`;

interface TabWrapperProps extends TabControlsProps {
    $isSelected: boolean;
}

export const TabWrapper = styled.button<TabWrapperProps>`
    position: relative;
    bottom: -${TAB_CONTROL_WRAPPER_BORDER_WIDTH};
    padding: 0 ${spacing.xSmall} ${spacing.xxSmall};
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.2s;

    ${(props) =>
        props.$isSelected
            ? css`
                  border-bottom: 2px solid ${colors.mountainAsh};
              `
            : null};

    ${(props) =>
        props.$isFlexStart == null
            ? null
            : css`
                  margin-right: ${spacing.large};
              `};
`;
