import React, {
    InputHTMLAttributes,
    useCallback,
    useMemo,
    useState,
} from 'react';

import { RequiredAsterisk } from 'components/ui/RequiredAsterisk';

import { colors } from 'styles/theme';
import { Label, SubLabel } from 'styles/shared';
import {
    TextInput as StyledTextInput,
    TextArea as StyledTextArea,
    TextInputProps as StyledTextInputProps,
    TextInputWrapper,
} from 'styles/textInput';

type HTMLInputCustomProps = InputHTMLAttributes<
    HTMLInputElement | HTMLTextAreaElement
> &
    StyledTextInputProps;
interface TextInputProps extends HTMLInputCustomProps {
    label?: string;
    subLabel?: string;
    isTextArea?: boolean;
    $isError?: boolean;
    $width?: string;
}

export const TextInput = (props: TextInputProps) => {
    const { label, subLabel, isTextArea, $isError, required, $width } = props;

    const [isFocused, setIsFocused] = useState<boolean>(false);

    const onFocus = useCallback(() => {
        if (!isFocused) {
            setIsFocused(true);
        }
    }, [isFocused]);

    const onBlur = useCallback(() => {
        if (isFocused) {
            setIsFocused(false);
        }
    }, [isFocused]);

    const borderColor = useMemo(() => {
        if ($isError) {
            return colors.error;
        } else if (isFocused) {
            return colors.focus;
        }
    }, [$isError, isFocused]);

    const renderedTextInputArea = useMemo(
        () =>
            isTextArea ? (
                <StyledTextArea
                    {...props}
                    rows={3}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    $borderColor={borderColor}
                />
            ) : (
                <StyledTextInput
                    {...props}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    $borderColor={borderColor}
                />
            ),
        [borderColor, isTextArea, onBlur, onFocus, props]
    );

    return (
        <TextInputWrapper $width={$width}>
            {label == null ? null : (
                <Label>
                    {label}
                    {required ? <RequiredAsterisk /> : null}
                </Label>
            )}
            {subLabel == null ? null : <SubLabel>{subLabel}</SubLabel>}
            {renderedTextInputArea}
        </TextInputWrapper>
    );
};
