import React, { Fragment, useCallback, useMemo, useState } from 'react';

import { supabase, useAuth } from 'hooks/useAuth';
import { useMemberGroupData } from 'hooks/useMemberGroupData';
import { useToast } from 'hooks/useToast';

import { formatDateTime } from 'libs/utils';
import { EXIT_RESPONSES, KUDOS_TYPES_TO_EMPTY_STRINGS } from 'libs/constants';

import {
    PeriodicSurvey,
    PeriodicSurveyResponses,
} from 'components/layout/PeriodicSurvey';
import { SteppedForm } from 'components/layout/SteppedForm';

import { TextInput } from 'components/ui/TextInput';
import { Select } from 'components/ui/Select';

import { colors, Fonts, spacing } from 'styles/theme';
import {
    Divider,
    HorizontalFlexWrapper,
    NestedCard,
    Bumper,
} from 'styles/shared';

type ClosingExit = (typeof EXIT_RESPONSES)[number];

interface SubmitClosingProps {
    sessionNumber: number;
    sessionDate: string;
    isPreviousSessionClosing: boolean;
    onChangeFormStep: () => void;
    onClose: () => void;
}

export const SubmitClosing = ({
    sessionNumber,
    sessionDate,
    isPreviousSessionClosing,
    onChangeFormStep,
    onClose,
}: SubmitClosingProps) => {
    const { userId, userProfile, groupProfiles } = useAuth();
    const { sessionDetails, getClosings } = useMemberGroupData();
    const { showSuccessToast, showErrorToast } = useToast();

    const [periodicSurvey, setPeriodicSurvey] =
        useState<PeriodicSurveyResponses>({});
    const [learnings, setLearnings] = useState<string>('');
    const [selectedExit, setSelectedExit] = useState<ClosingExit | null>(null);
    const [action, setAction] = useState<string>('');
    const [otherFeedback, setOtherFeedback] = useState<string>('');
    const [kudosToMembers, setKudosToMembers] = useState<object>(
        structuredClone(KUDOS_TYPES_TO_EMPTY_STRINGS)
    );
    const [isSubmittingClosing, setIsSubmittingClosing] =
        useState<boolean>(false);

    const onLearningsInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setLearnings(target.value);
        },
        []
    );

    const onActionInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setAction(target.value);
        },
        []
    );

    const onSelectMemberForKudos = useCallback(
        (kudosType: string) => (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            const newKudosToMembers = { ...kudosToMembers };
            // This always happens with constant objects that you key into,
            // still need to figure that typing out one day...
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            newKudosToMembers[kudosType] = target.value;
            setKudosToMembers(newKudosToMembers);
        },
        [kudosToMembers]
    );

    const onChangeExit = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setSelectedExit(target.value as ClosingExit);
        },
        []
    );

    const onOtherFeedbackInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setOtherFeedback(target.value);
        },
        []
    );

    const onSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (userProfile?.is_test) {
                showErrorToast('Test accounts cannot submit closings');
                return;
            }

            if (isSubmittingClosing) {
                return;
            }
            setIsSubmittingClosing(true);

            // The type inference for this insertion causes supabase to warn that
            // the kudos and periodicSurvey values should be stringified, but that
            // is not consistent with the rest of the closing data in the db
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { error } = await supabase.from('closings').insert({
                created_by: userId,
                group_id: userProfile?.member_groups?.[0],
                session_number: sessionNumber,
                session_date: sessionDate,
                raw_data: {
                    learnings,
                    action,
                    exit: selectedExit,
                    other: otherFeedback,
                    member_name: userProfile?.full_name,
                    // Pass everything we save in DB columns to raw_data as well
                    created_by: userId,
                    group_id: userProfile?.member_groups?.[0],
                    session_number: sessionNumber,
                    session_date: sessionDate,
                    kudos: kudosToMembers,
                    periodic_survey: periodicSurvey,
                },
            });

            if (error == null) {
                getClosings?.();
                showSuccessToast('Your session closing has been submitted!');
                onClose();
            } else {
                showErrorToast(error.message);
            }

            setIsSubmittingClosing(false);
        },
        [
            userProfile?.is_test,
            userProfile?.member_groups,
            userProfile?.full_name,
            isSubmittingClosing,
            userId,
            sessionNumber,
            sessionDate,
            learnings,
            action,
            selectedExit,
            otherFeedback,
            kudosToMembers,
            periodicSurvey,
            showErrorToast,
            getClosings,
            showSuccessToast,
            onClose,
        ]
    );

    const headerNode = useMemo(
        () => (
            <>
                <Fonts.Large $color={colors.mountainAsh}>
                    {`Session ${sessionNumber}`}
                </Fonts.Large>
                {sessionDate === '' ? null : (
                    <HorizontalFlexWrapper>
                        <Fonts.Medium $color={colors.grandGreen}>
                            {formatDateTime(new Date(sessionDate))}
                        </Fonts.Medium>
                    </HorizontalFlexWrapper>
                )}
                <Bumper $minHeight={spacing.xSmall} />
                <Fonts.Heading5>{`Session Closing Space`}</Fonts.Heading5>
                <Bumper />
            </>
        ),
        [sessionDate, sessionNumber]
    );

    const formSteps = useMemo(() => {
        const formStepsReturnArray = [
            {
                isComplete: learnings !== '',
                formNode: (
                    <NestedCard>
                        <Fonts.Heading7
                            $color={colors.mountainAsh}
                        >{`Session Closing`}</Fonts.Heading7>
                        <Fonts.Medium>{`At the end of every session, we will ask you to self reflect on your session experience.`}</Fonts.Medium>
                        <Bumper />
                        <Divider />
                        <Bumper />
                        <TextInput
                            id="learnings"
                            name="learnings"
                            value={learnings}
                            onInput={onLearningsInput}
                            label={`What’s your learning from ${
                                isPreviousSessionClosing
                                    ? `your last`
                                    : `today’s`
                            } session? (Your learnings will be shared with your coach and your group. They'll also be de-identified and then shared with your sponsor.)`}
                            subLabel="Scientific research has shown when you skip documenting what you’ve learned, you give up on 70% of your potential learning. This will be shared back with your coaches, your group, and you in the future."
                            isTextArea
                            required
                        />
                        <Bumper />
                        <Bumper />
                        <TextInput
                            id="action"
                            name="action"
                            value={action}
                            onInput={onActionInput}
                            label="What small action might you commit to taking between now and next session?"
                            isTextArea
                        />
                    </NestedCard>
                ),
            },
            {
                isComplete: true,
                formNode: (
                    <NestedCard>
                        <Fonts.Heading7
                            $color={colors.mountainAsh}
                        >{`Session Closing`}</Fonts.Heading7>
                        <Fonts.Medium>{`At the end of every session, we will ask you to self reflect on your session experience.`}</Fonts.Medium>
                        <Bumper />
                        <Divider />
                        {Object.keys(KUDOS_TYPES_TO_EMPTY_STRINGS).map(
                            (kudosType, index) => (
                                <Fragment key={kudosType}>
                                    <Bumper
                                        $minHeight={
                                            index === 0
                                                ? spacing.xxSmall
                                                : spacing.large
                                        }
                                    />
                                    <Bumper $minHeight={spacing.xSmall} />
                                    <Fonts.Small>{kudosType}</Fonts.Small>
                                    <Bumper $minHeight={spacing.xxxSmall} />
                                    <Select
                                        onInput={onSelectMemberForKudos(
                                            kudosType
                                        )}
                                        // See above
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        value={kudosToMembers[kudosType]}
                                        label="Give your group members some kudos 👏 (optional)"
                                        subLabel="Giving and receiving gratitude helps you develop emotional intelligence, and improves your wellbeing."
                                    >
                                        <option
                                            value=""
                                            label="Choose a member"
                                        />
                                        {groupProfiles
                                            .filter(
                                                (profile) =>
                                                    profile.id !== userId
                                            )
                                            .map((profile, index) => (
                                                <option
                                                    key={index}
                                                    value={profile.id}
                                                >
                                                    {profile.full_name}
                                                </option>
                                            ))}
                                    </Select>
                                </Fragment>
                            )
                        )}
                    </NestedCard>
                ),
            },
            {
                isComplete: selectedExit !== null,
                formNode: (
                    <NestedCard>
                        <Fonts.Heading7
                            $color={colors.mountainAsh}
                        >{`Session Closing`}</Fonts.Heading7>
                        <Fonts.Medium>{`At the end of every session, we will ask you to self reflect on your session experience.`}</Fonts.Medium>
                        <Bumper />
                        <Divider />
                        <Bumper />
                        <Select
                            onInput={onChangeExit}
                            value={selectedExit ?? ''}
                            label={`What is your reaction to the following statement? “I feel that this session was a great use of my time.”`}
                            required
                        >
                            <option value="" label="Select" hidden />
                            {EXIT_RESPONSES.map((closingExit) => (
                                <option key={closingExit} value={closingExit}>
                                    {closingExit}
                                </option>
                            ))}
                        </Select>
                        <Bumper />
                        <Bumper />
                        <TextInput
                            id="otherFeedback"
                            name="otherFeedback"
                            value={otherFeedback}
                            onInput={onOtherFeedbackInput}
                            label="Is there anything else you'd like to share?"
                        />
                    </NestedCard>
                ),
            },
        ];

        if (
            (sessionNumber !== 0 &&
                sessionDetails.length === 12 &&
                sessionNumber % 4 === 0) ||
            (sessionDetails.length === 6 && sessionNumber % 3 === 0)
        ) {
            formStepsReturnArray.push({
                isComplete:
                    periodicSurvey.overall_rating != null &&
                    periodicSurvey.overall_rating !== '' &&
                    periodicSurvey.coach_rating != null &&
                    periodicSurvey.coach_rating !== '',
                formNode: (
                    <PeriodicSurvey
                        periodicSurvey={periodicSurvey}
                        setPeriodicSurvey={setPeriodicSurvey}
                    />
                ),
            });
        }

        return formStepsReturnArray;
    }, [
        action,
        groupProfiles,
        isPreviousSessionClosing,
        kudosToMembers,
        learnings,
        onActionInput,
        onChangeExit,
        onLearningsInput,
        onOtherFeedbackInput,
        onSelectMemberForKudos,
        otherFeedback,
        periodicSurvey,
        selectedExit,
        sessionDetails.length,
        sessionNumber,
        userId,
    ]);

    return (
        <SteppedForm
            headerNode={headerNode}
            formSteps={formSteps}
            onFinalSubmit={onSubmit}
            onChangeFormStep={onChangeFormStep}
            onClose={onClose}
        />
    );
};
