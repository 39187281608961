import React from 'react';

import { CustomSVGProps, setSVGColor } from 'styles/theme';

export const Idea = (props: CustomSVGProps) => (
    <svg
        width={14}
        height={16}
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M4.1875 15.125H9.8125M8.6875 8.54803C8.6875 9.45612 7.93198 10.1923 7 10.1923C6.06802 10.1923 5.3125 9.45612 5.3125 8.54803M1.375 6.23412C1.375 2.9751 3.8934 0.875 7 0.875C10.1066 0.875 12.625 2.9751 12.625 6.23412C12.625 8.66302 10.5222 10.5914 9.95306 12.2002C9.81492 12.5907 9.47671 12.9327 9.0625 12.9327H4.9375C4.52329 12.9327 4.18508 12.5907 4.04694 12.2002C3.47783 10.5914 1.375 8.66302 1.375 6.23412Z"
            stroke={setSVGColor(props)}
            strokeLinecap="round"
        />
    </svg>
);
