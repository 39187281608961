import React, { useCallback, useMemo, useState } from 'react';

import { supabase, useAuth } from 'hooks/useAuth';
import { useToast } from 'hooks/useToast';

import {
    FeedbackTypeEnum,
    FEEDBACK_TYPES,
    OPPORTUNITY_QUESTIONS,
    RESULT_QUESTIONS,
} from 'libs/constants';

import { SteppedForm } from 'components/layout/SteppedForm';

import { TextInput } from 'components/ui/TextInput';
import { Select } from 'components/ui/Select';
import { QuotedNestedCard } from 'components/ui/QuotedNestedCard';

import { colors, Fonts, spacing } from 'styles/theme';
import { Bumper, HorizontalFlexWrapper, NestedCard } from 'styles/shared';

interface FeedbackToolProps {
    onChangeFormStep: () => void;
    onClose: () => void;
}

export const FeedbackTool = ({
    onChangeFormStep,
    onClose,
}: FeedbackToolProps) => {
    const { userId } = useAuth();
    const { showSuccessToast, showErrorToast } = useToast();

    const [recipientName, setRecipientName] = useState<string>('');
    const [event, setEvent] = useState<string>('');
    const [eventDate, setEventDate] = useState<string>('');
    const [eventObservation, setEventObservation] = useState<string>('');
    const [type, setType] = useState<FeedbackTypeEnum | null>(null);
    const [result, setResult] = useState<string>('');
    const [resultQuestion, setResultQuestion] = useState<string>('');
    const [opportunity, setOpportunity] = useState<string>('');
    const [opportunityQuestion, setOpportunityQuestion] = useState<string>('');

    const onRecipientNameInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setRecipientName(target.value);
        },
        []
    );

    const onEventInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setEvent(target.value);
        },
        []
    );

    const onEventDateInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setEventDate(target.value);
        },
        []
    );

    const onEventObservationInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setEventObservation(target.value);
        },
        []
    );

    const onChangeType = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setType(target.value as FeedbackTypeEnum);
        },
        []
    );

    const onResultInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setResult(target.value);
        },
        []
    );

    const onChangeResultQuestion = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setResultQuestion(target.value);
        },
        []
    );

    const onOpportunityInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setOpportunity(target.value);
        },
        []
    );

    const onChangeOpportunityQuestion = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setOpportunityQuestion(target.value);
        },
        []
    );

    const fullScript = useMemo(
        () =>
            `${
                recipientName === '' ? '_______' : recipientName
            }, I have some feedback about the ${event} on ${eventDate}.

I noticed that ${eventObservation}

As a result, ${result} ${resultQuestion}

There's an opportunity here to continue ${opportunity} ${opportunityQuestion}

Thanks for being so open to my feedback.`,
        [
            event,
            eventDate,
            eventObservation,
            opportunity,
            opportunityQuestion,
            recipientName,
            result,
            resultQuestion,
        ]
    );

    const submitFeedbackScript = useCallback(async () => {
        if (type == null) {
            showErrorToast(
                'Please select a "reinforcing" or "developmental" feedback type'
            );
            return;
        }

        const { error: databaseError } = await supabase
            .from('feedback_scripts')
            .insert({
                created_by: userId,
                recipient_name: recipientName,
                event,
                event_date: eventDate,
                event_observation: eventObservation,
                type,
                result,
                result_question: resultQuestion,
                opportunity,
                opportunity_question: opportunityQuestion,
                full_script: fullScript,
            });

        if (databaseError != null) {
            showErrorToast(databaseError.message);
        }

        try {
            await navigator.clipboard.writeText(fullScript);
            showSuccessToast('Feedback script copied to clipboard!');
        } catch (error) {
            if (typeof error === 'string') {
                showErrorToast(error.toUpperCase());
            } else if (error instanceof Error) {
                showErrorToast(error.message);
            }
        }
    }, [
        event,
        eventDate,
        eventObservation,
        fullScript,
        opportunity,
        opportunityQuestion,
        recipientName,
        result,
        resultQuestion,
        showErrorToast,
        showSuccessToast,
        type,
        userId,
    ]);

    const headerNode = useMemo(
        () => (
            <>
                <Fonts.Heading5>{`The Grand’s Feedback Method`}</Fonts.Heading5>
                <Bumper $minHeight={spacing.xSmall} />
            </>
        ),
        []
    );

    const formSteps = useMemo(
        () => [
            {
                isComplete: true,
                shouldHideProgress: true,
                submitText: `Get started`,
                formNode: (
                    <>
                        <NestedCard>
                            <Fonts.Medium>
                                {`Giving feedback is an act of trust and confidence. When done well it can deepen your relationship with your colleague and enable your team to realize their potential.`}
                            </Fonts.Medium>
                            <Bumper />
                            <Fonts.Small $color={colors.grandGreen}>
                                {`First, we’ll help you figure out exactly what to say by creating a script using the SBIO framework (`}
                                <Fonts.Small
                                    as="span"
                                    $color={colors.grandGreen}
                                    $isOpenSansSemiBold
                                >{`Situation, Behavior, Impact, Opportunity`}</Fonts.Small>
                                {`). From there we’ll help you deliver feedback in the right context.`}
                            </Fonts.Small>
                        </NestedCard>
                    </>
                ),
            },
            {
                isComplete: event !== '' && eventDate !== '',
                formNode: (
                    <>
                        <Fonts.Heading7
                            $color={colors.mountainAsh}
                        >{`Situation`}</Fonts.Heading7>
                        <Bumper />
                        <QuotedNestedCard>
                            <Bumper $minHeight={spacing.xxSmall} />
                            <HorizontalFlexWrapper>
                                <TextInput
                                    type="text"
                                    placeholder="Shruti"
                                    value={recipientName}
                                    onInput={onRecipientNameInput}
                                    $width="260px"
                                />
                                <Bumper $minWidth={spacing.xSmall} />
                                <Fonts.MediumQuote $color={colors.mountainAsh}>
                                    {`I have some feedback about the`}
                                </Fonts.MediumQuote>
                            </HorizontalFlexWrapper>
                            <Bumper $minHeight={spacing.xxxSmall} />
                            <Fonts.XSmall
                                $color={colors.grey30}
                            >{`Their name (optional)`}</Fonts.XSmall>
                            <Bumper />
                            <HorizontalFlexWrapper>
                                <TextInput
                                    type="text"
                                    placeholder="Strategic Business Review"
                                    value={event}
                                    onInput={onEventInput}
                                    $width="350px"
                                />
                                <Bumper $minWidth={spacing.xSmall} />
                                <Fonts.MediumQuote
                                    $color={colors.mountainAsh}
                                >{`that took place on`}</Fonts.MediumQuote>
                            </HorizontalFlexWrapper>
                            <Bumper $minHeight={spacing.xxxSmall} />
                            <Fonts.XSmall
                                $color={colors.grey30}
                            >{`Meeting or event name`}</Fonts.XSmall>
                            <Bumper />
                            <TextInput
                                type="text"
                                placeholder="Monday"
                                value={eventDate}
                                onInput={onEventDateInput}
                                $width="160px"
                            />
                            <Bumper $minHeight={spacing.xxxSmall} />
                            <Fonts.XSmall
                                $color={colors.grey30}
                            >{`Day`}</Fonts.XSmall>
                        </QuotedNestedCard>
                    </>
                ),
            },
            {
                isComplete: eventObservation !== '' && type !== null,
                formNode: (
                    <>
                        <Fonts.Heading7
                            $color={colors.mountainAsh}
                        >{`Behavior`}</Fonts.Heading7>
                        <Bumper />
                        <QuotedNestedCard>
                            <Bumper $minHeight={spacing.xxSmall} />
                            <Fonts.MediumQuote
                                $color={colors.mountainAsh}
                            >{`I noticed that`}</Fonts.MediumQuote>
                            <Bumper $minHeight={spacing.xxSmall} />
                            <TextInput
                                id="eventObservation"
                                name="eventObservation"
                                placeholder="when you presented to the clients, you..."
                                value={eventObservation}
                                onInput={onEventObservationInput}
                                isTextArea
                            />
                            <Bumper $minHeight={spacing.xxxSmall} />
                            <Fonts.XSmall
                                $color={colors.grey30}
                            >{`Describe a specific behavior you observed. Be objective.`}</Fonts.XSmall>
                            <Bumper />
                            <Bumper />
                            <Select
                                onInput={onChangeType}
                                value={type ?? ''}
                                label="What type of feedback do you want to give on their behavior?"
                                required
                            >
                                <option value="" hidden>{`Select`}</option>
                                {FEEDBACK_TYPES.map((feedbackType) => (
                                    <option
                                        key={feedbackType}
                                        value={feedbackType}
                                    >
                                        {`${feedbackType
                                            .charAt(0)
                                            .toLocaleUpperCase()}${feedbackType.slice(
                                            1
                                        )}`}
                                    </option>
                                ))}
                            </Select>
                            <Bumper />
                            <Fonts.MediumQuote>{`What's the difference?`}</Fonts.MediumQuote>
                            <Bumper />
                            <Fonts.Small>
                                {`Instead of "positive" or "negative," we suggest using words that better suit feedback's purpose and intention.`}
                            </Fonts.Small>
                            <Bumper />
                            <Fonts.Small>
                                {`"Reinforcing" feedback helps someone become more aware of a productive behavior.`}
                            </Fonts.Small>
                            <Fonts.Small>
                                {`"Developmental" feedback helps someone notice an opportunity to improve a specific behavior.`}
                            </Fonts.Small>
                        </QuotedNestedCard>
                    </>
                ),
            },
            {
                isComplete: result !== '' && resultQuestion !== '',
                formNode: (
                    <>
                        <Fonts.Heading7
                            $color={colors.mountainAsh}
                        >{`Impact`}</Fonts.Heading7>
                        <Bumper />
                        <QuotedNestedCard>
                            <Bumper $minHeight={spacing.xxSmall} />
                            <Fonts.MediumQuote
                                $color={colors.mountainAsh}
                            >{`As a result,`}</Fonts.MediumQuote>
                            <Bumper $minHeight={spacing.xxSmall} />
                            <TextInput
                                id="result"
                                name="result"
                                placeholder="the message taken away was..."
                                value={result}
                                onInput={onResultInput}
                                isTextArea
                            />
                            <Bumper $minHeight={spacing.xxxSmall} />
                            <Fonts.XSmall
                                $color={colors.grey30}
                            >{`Describe result`}</Fonts.XSmall>
                            <Bumper />
                            <Bumper />
                            <Select
                                onInput={onChangeResultQuestion}
                                value={resultQuestion ?? ''}
                                label="Select a question to include in your script:"
                                required
                            >
                                <option value="" hidden>{`Select`}</option>
                                {RESULT_QUESTIONS.map(
                                    (resultQuestionOption) => (
                                        <option
                                            key={resultQuestionOption}
                                            value={resultQuestionOption}
                                        >
                                            {resultQuestionOption}
                                        </option>
                                    )
                                )}
                            </Select>
                        </QuotedNestedCard>
                    </>
                ),
            },
            {
                isComplete: opportunity !== '' && opportunityQuestion !== '',
                formNode: (
                    <>
                        <Fonts.Heading7
                            $color={colors.mountainAsh}
                        >{`Opportunity`}</Fonts.Heading7>
                        <Bumper />
                        <QuotedNestedCard>
                            <Bumper $minHeight={spacing.xxSmall} />
                            <Fonts.MediumQuote
                                $color={colors.mountainAsh}
                            >{`There's an opportunity here to ${
                                type === 'reinforcing' ? 'continue' : 'practice'
                            }`}</Fonts.MediumQuote>
                            <Bumper $minHeight={spacing.xxSmall} />
                            <TextInput
                                id="opportunity"
                                name="opportunity"
                                placeholder="clear delivery in large meetings"
                                value={opportunity}
                                onInput={onOpportunityInput}
                                isTextArea
                            />
                            <Bumper $minHeight={spacing.xxxSmall} />
                            <Fonts.XSmall
                                $color={colors.grey30}
                            >{`Suggest an opportunity`}</Fonts.XSmall>
                            <Bumper />
                            <Bumper />
                            <Select
                                onInput={onChangeOpportunityQuestion}
                                value={opportunityQuestion ?? ''}
                                label="Select a question to include in your script:"
                                required
                            >
                                <option value="" hidden>{`Select`}</option>
                                {OPPORTUNITY_QUESTIONS.map(
                                    (opportunityQuestionOption) => (
                                        <option
                                            key={opportunityQuestionOption}
                                            value={opportunityQuestionOption}
                                        >
                                            {opportunityQuestionOption}
                                        </option>
                                    )
                                )}
                            </Select>
                        </QuotedNestedCard>
                    </>
                ),
            },
            {
                isComplete: true,
                submitText: 'Copy script and finish',
                shouldHideProgress: true,
                shouldHideHeader: true,
                formNode: (
                    <>
                        <Fonts.Heading6>{`Here's what you can say, give it a try:`}</Fonts.Heading6>
                        <Bumper />
                        <QuotedNestedCard $padding={spacing.xLarge}>
                            <Fonts.MediumQuote>
                                <Fonts.MediumQuote $color={colors.mountainAsh}>
                                    {recipientName === ''
                                        ? '_______'
                                        : recipientName}
                                </Fonts.MediumQuote>
                                {`, I have some feedback about the `}
                                <Fonts.MediumQuote $color={colors.mountainAsh}>
                                    {event}
                                </Fonts.MediumQuote>
                                {` on `}
                                <Fonts.MediumQuote $color={colors.mountainAsh}>
                                    {eventDate}
                                </Fonts.MediumQuote>
                                {`.`}
                            </Fonts.MediumQuote>
                            <Bumper />
                            <Fonts.MediumQuote>
                                {`I noticed that `}
                                <Fonts.MediumQuote $color={colors.mountainAsh}>
                                    {eventObservation}
                                </Fonts.MediumQuote>
                            </Fonts.MediumQuote>
                            <Bumper />
                            <Fonts.MediumQuote>
                                {`As a result, `}
                                <Fonts.MediumQuote $color={colors.mountainAsh}>
                                    {result}
                                </Fonts.MediumQuote>
                                {` ${resultQuestion}`}
                            </Fonts.MediumQuote>
                            <Bumper />
                            <Fonts.MediumQuote>
                                {`There's an opportunity here to continue `}
                                <Fonts.MediumQuote $color={colors.mountainAsh}>
                                    {opportunity}
                                </Fonts.MediumQuote>
                                {` ${opportunityQuestion}`}
                            </Fonts.MediumQuote>
                            <Bumper />
                            <Fonts.MediumQuote>
                                {`Thanks for being so open to my feedback.`}
                            </Fonts.MediumQuote>
                        </QuotedNestedCard>
                    </>
                ),
            },
        ],
        [
            event,
            eventDate,
            eventObservation,
            onChangeOpportunityQuestion,
            onChangeResultQuestion,
            onChangeType,
            onEventDateInput,
            onEventInput,
            onEventObservationInput,
            onOpportunityInput,
            onRecipientNameInput,
            onResultInput,
            opportunity,
            opportunityQuestion,
            recipientName,
            result,
            resultQuestion,
            type,
        ]
    );

    return (
        <SteppedForm
            headerNode={headerNode}
            formSteps={formSteps}
            onFinalSubmit={submitFeedbackScript}
            onChangeFormStep={onChangeFormStep}
            onClose={onClose}
            $isBottomAreaWhite
        />
    );
};
