import styled, { css } from 'styled-components';

import MainBackgroundImageDesktop from 'assets/bckg-landscape-desktop.png';
import MainBackgroundImageMobile from 'assets/bckg-landscape-mobile.png';
import OnboardingBackgroundImageDesktop from 'assets/bckg-clouds-desktop.png';
import OnboardingBackgroundImageMobile from 'assets/bckg-clouds-mobile.png';

import { mobileCssQuerist, spacing } from 'styles/theme';

interface AppWrapperProps {
    $isAuthRoute: boolean;
}

export const AppWrapper = styled.div<AppWrapperProps>`
    position: relative;
    background-image: url(${MainBackgroundImageDesktop});
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    ${mobileCssQuerist(css`
        background-image: url(${MainBackgroundImageMobile});
        display: block;
        overflow: scroll;
    `)}

    ${(props) =>
        props.$isAuthRoute
            ? css`
                  background-image: url(${OnboardingBackgroundImageDesktop});

                  ${mobileCssQuerist(css`
                      background-image: url(${OnboardingBackgroundImageMobile});
                  `)}
              `
            : null};
`;

export const FooterWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: ${spacing.medium} ${spacing.xxxLarge};

    ${mobileCssQuerist(
        css`
            position: relative;
        `
    )}
`;
