import styled, { css } from 'styled-components';

import { Fonts, colors, mobileCssQuerist, spacing } from 'styles/theme';

// TODO: rework all styling here to match new V3 theming system
// when working on responsive styling polish
// Eg. how fonts are out of component, how mobile CSS queries are used

export const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;

    ${mobileCssQuerist(
        css`
            height: auto;
            display: block;
        `
    )}
`;

export const SignUpFormWrapper = styled.div`
    background-color: ${colors.light};
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;

    ${mobileCssQuerist(
        css`
            width: 100%;
            height: auto;
        `
    )}
`;

export const SignUpForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const ScrollingContentWrapper = styled.div`
    position: relative;
    width: 50%;
    height: 100%;
    padding: 0 120px;
    overflow: scroll;

    // Custom screen size handling in case of smaller window size
    @media only screen and (max-width: 1320px) {
        padding: 0 ${spacing.xxxLarge};
    }

    ${mobileCssQuerist(
        css`
            width: 100%;
            height: auto;
        `
    )}
`;

export const ScrollingContentSection = styled.div`
    height: 100vh;
    width: 100%;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;

    ${mobileCssQuerist(
        css`
            &:first-of-type {
                height: 50vh;
            }
        `
    )}
`;

export const ScrollingContentMainHeader = styled(Fonts.Heading2)`
    width: 100%;
`;

export const ScrollingContentSectionHeader = styled(Fonts.Small)`
    width: 100%;
`;

export const ScrollingContentSectionBodyText = styled(Fonts.Medium)`
    margin-top: 4px;
    width: 100%;
`;

export const ScrollingContentImage = styled.img`
    width: calc(100% - 200px);
    margin-right: 48px;

    ${mobileCssQuerist(
        css`
            width: calc(100% - 40px);
        `
    )}
`;

export const ScrollCTAWrapper = styled.div`
    position: fixed;
    bottom: 0;
    z-index: 100;
    background: linear-gradient(transparent, ${colors.white}, ${colors.white});
    width: 100vw;
    height: 100px;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
`;

export const ScrollCTA = styled.div`
    position: absolute;
    bottom: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${mobileCssQuerist(
        css`
            bottom: 10px;
        `
    )}
`;

export const ChevronWrapper = styled.div`
    margin-top: 16px;
    transform: rotate(90deg);

    ${mobileCssQuerist(
        css`
            margin-top: 4px;
        `
    )}
`;
