import styled, { css } from 'styled-components';

import { mobileCssQuerist, spacing, transparencies } from 'styles/theme';

export const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: ${transparencies.darkJungle80};
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: scroll;
    cursor: pointer;

    ${mobileCssQuerist(
        css`
            overflow-x: hidden;
        `
    )}
`;

export const CloseButtonWrapper = styled.div`
    position: absolute;
    top: ${spacing.small};
    right: ${spacing.small};
`;
