import React, { useEffect, useMemo, useState } from 'react';
import { Database } from 'libs/supabaseTypes';

import { Fonts } from 'styles/theme';
import { Bumper } from 'styles/shared';

import { useToast } from 'hooks/useToast';
import { supabase } from 'hooks/useAuth';

import { Divider, List, ItalicText } from 'legacy/styles/coach';
import { Loader } from 'components/ui/Loader';

type SubmittedRecap = Database['public']['Tables']['recaps']['Row'];

interface RecapDetailsProps {
    submittedRecapId: string;
}

export const RecapDetails = ({ submittedRecapId }: RecapDetailsProps) => {
    const { showErrorToast } = useToast();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [submittedRecap, setSubmittedRecap] = useState<SubmittedRecap | null>(
        null
    );

    const parsedSubmittedRecapData = useMemo(
        () => JSON.parse(JSON.stringify(submittedRecap?.raw_data ?? {})),
        [submittedRecap?.raw_data]
    );

    useEffect(() => {
        const getSubmittedRecap = async () => {
            const { data, error } = await supabase
                .from('recaps')
                .select('*')
                .eq('id', submittedRecapId);

            if (error == null) {
                setIsLoading(false);
                setSubmittedRecap(data[0] ?? null);
            } else {
                showErrorToast(error.message);
            }
        };
        getSubmittedRecap();
    }, [showErrorToast, submittedRecapId]);

    return isLoading ? (
        <Loader />
    ) : (
        <>
            <Fonts.Large>{`${submittedRecap?.group_id} (${new Date(
                submittedRecap?.session_date ?? 0
            ).toLocaleString('en-us', {
                month: 'long',
            })} ${new Date(
                submittedRecap?.session_date ?? 0
            ).getDate()}, ${new Date(
                submittedRecap?.session_date ?? 0
            ).getFullYear()})`}</Fonts.Large>
            <Fonts.Heading5>{`Submitted Session Recap`}</Fonts.Heading5>
            <Bumper />
            <Fonts.Heading7>{`Attendance and Closing Words`}</Fonts.Heading7>
            <Bumper />
            <Fonts.Medium>{'Expected attendees:'}</Fonts.Medium>
            <List>
                {parsedSubmittedRecapData.expected_attendees?.map(
                    (expectedAttendee: string) => (
                        <li key={expectedAttendee}>{expectedAttendee}</li>
                    )
                )}
            </List>
            <Bumper />
            <Bumper />
            <Fonts.Medium>{'Attendees:'}</Fonts.Medium>
            <List>
                {parsedSubmittedRecapData.attributed_closing_words?.map(
                    (attributedClosingWord: string) => (
                        <li key={attributedClosingWord}>
                            {attributedClosingWord.replace('~~', ': ')}
                        </li>
                    )
                )}
            </List>
            <Bumper />
            <Bumper />
            <Divider />

            <Bumper />
            <Fonts.Heading6>{`Content and learnings`}</Fonts.Heading6>
            <Bumper />
            <Fonts.Medium>{'Topics:'}</Fonts.Medium>
            <List>
                <li>{parsedSubmittedRecapData.topic1}</li>
                <li>{parsedSubmittedRecapData.topic2}</li>
                <li>{parsedSubmittedRecapData.topic3}</li>
            </List>
            <Bumper />
            <Bumper />
            <Fonts.Medium>{'Exercises:'}</Fonts.Medium>
            <List>
                {parsedSubmittedRecapData.selected_exercises?.map(
                    (selectedExercise: string) => (
                        <li key={selectedExercise}>{selectedExercise}</li>
                    )
                )}
            </List>
            <Bumper />
            <Bumper />
            <Fonts.Medium>{'Learnings:'}</Fonts.Medium>
            <ItalicText>{`"${parsedSubmittedRecapData.learnings}"`}</ItalicText>
            <Bumper />
            <Bumper />
            <Divider />

            <Bumper />
            <Fonts.Heading6>{`Session and Product Feedback`}</Fonts.Heading6>
            <Bumper />
            <Fonts.Medium>
                {`"The Grand’s training and support helped me feel prepared for this session."`}
            </Fonts.Medium>
            <ItalicText>{parsedSubmittedRecapData.exit}</ItalicText>
            <Bumper />
            <Bumper />
            <Fonts.Medium>{`Admin time:`}</Fonts.Medium>
            <ItalicText>{parsedSubmittedRecapData.admin_time}</ItalicText>
            <Bumper />
            <Bumper />
            {parsedSubmittedRecapData.admin_type != null &&
            parsedSubmittedRecapData.admin_type !== '' ? (
                <>
                    <Fonts.Medium>{`Admin type:`}</Fonts.Medium>
                    <ItalicText>
                        {parsedSubmittedRecapData.admin_type}
                    </ItalicText>
                    <Bumper />
                    <Bumper />
                </>
            ) : null}
            {parsedSubmittedRecapData.other != null &&
            parsedSubmittedRecapData.admin_type !== '' ? (
                <>
                    <Fonts.Medium>{`Other feedback:`}</Fonts.Medium>
                    <ItalicText>{parsedSubmittedRecapData.other}</ItalicText>
                    <Bumper />
                    <Bumper />
                </>
            ) : null}
        </>
    );
};
