import React from 'react';

import { Fonts } from 'styles/theme';
import { AvatarWrapper } from 'styles/avatar';

interface AvatarProps {
    profileInitials: string;
}

export const Avatar = ({ profileInitials }: AvatarProps) => (
    <AvatarWrapper>
        <Fonts.Small>{profileInitials}</Fonts.Small>
    </AvatarWrapper>
);
