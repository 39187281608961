import styled, { css } from 'styled-components';

import { spacing, transparencies } from 'styles/theme';

export const DimensionCardWrapper = styled.div`
    margin-top: ${spacing.medium};
    border-radius: ${spacing.radiusSmall};
    background-color: ${transparencies.mountainAsh10};
    width: 150px;
    padding: ${spacing.small};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ResponsesWrapper = styled.div`
    margin-bottom: ${spacing.xSmall};
    border: 1px solid ${transparencies.darkJungle5};
    border-radius: ${spacing.radiusSmall};
    background-color: ${transparencies.white80};
    width: 100%;
    padding: ${spacing.xSmall};
    cursor: pointer;

    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const DimensionSubtotalWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
`;

export const SubtotalNumberWrapper = styled.div`
    margin-right: ${spacing.small};
    border-radius: ${spacing.radiusLarge};
    background-color: ${transparencies.mountainAsh10};
    width: ${spacing.xLarge};
    height: ${spacing.xLarge};
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface DropdownChevronWrapperProps {
    $isDropdownOpen: boolean;
}

export const DropdownChevronWrapper = styled.div<DropdownChevronWrapperProps>`
    position: absolute;
    right: 4px;
    transform: rotate(90deg);

    ${(props) =>
        props.$isDropdownOpen
            ? css`
                  transform: rotate(270deg);
              `
            : null};
`;
