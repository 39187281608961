import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth';
import { useMemberGroupData } from 'hooks/useMemberGroupData';
import { useToast } from 'hooks/useToast';

import { getRelationshipToToday } from 'libs/utils';

import { SubmitClosing } from 'components/layout/SubmitClosing';
import { FeedbackTool } from 'components/layout/FeedbackTool';
import { SubmitRetro } from 'components/layout/SubmitRetro';
import { RetroResults } from 'components/layout/RetroResults';
import { SessionDescriptions } from 'legacy/components/SessionDescriptions';
import { SubmitRecap } from 'legacy/components/SubmitRecap';
import { RecapDetails } from 'legacy/components/RecapDetails';

import { Button } from 'components/ui/Button';

import { Close } from 'components/svg/Close';

import { Bumper, Card } from 'styles/shared';
import { CloseButtonWrapper, ModalBackground } from 'styles/modal';
import { spacing } from 'styles/theme';

type ModalContent =
    | 'closing'
    | 'feedback'
    | 'retro'
    | 'retro-results'
    | 'session-descriptions'
    | 'coach-recap'
    | 'recap-details'
    | null;

export const Modal = () => {
    const { userId } = useAuth();
    const { closings, group, retros } = useMemberGroupData();
    const { showSuccessToast, showWarningToast } = useToast();

    const [searchParams, setSearchParams] = useSearchParams();

    const scrollRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    const modalContent = searchParams.get('modalContent') as ModalContent;
    const sessionDate = searchParams.get('sessionDate') as string;
    const retroId = searchParams.get('retroId') as string;
    const groupProgramId = searchParams.get('groupProgramId') as string;
    const incompleteRecapId = searchParams.get('incompleteRecapId') as string;
    const submittedRecapId = searchParams.get('submittedRecapId') as string;

    const scrollToTop = useCallback(() => {
        scrollRef.current?.scrollTo({ top: 0 });
    }, []);

    const onCloseModal = useCallback(() => {
        if (modalContent != null) {
            searchParams.delete('modalContent');
        }
        if (sessionDate != null) {
            searchParams.delete('sessionDate');
        }
        if (retroId != null) {
            searchParams.delete('retroId');
        }
        if (groupProgramId != null) {
            searchParams.delete('groupProgramId');
        }
        if (incompleteRecapId != null) {
            searchParams.delete('incompleteRecapId');
        }
        if (submittedRecapId != null) {
            searchParams.delete('submittedRecapId');
        }
        setSearchParams(searchParams);
    }, [
        modalContent,
        sessionDate,
        retroId,
        groupProgramId,
        incompleteRecapId,
        submittedRecapId,
        setSearchParams,
        searchParams,
    ]);

    useEffect(() => {
        if (group == null || modalContent !== 'closing') {
            return;
        }

        if (
            closings.find(
                (closing) =>
                    closing.created_by === userId &&
                    closing.session_date === sessionDate
            ) != null
        ) {
            showSuccessToast(
                `You've already submitted a closing for that session!`
            );
            onCloseModal();
        } else if (
            (sessionDate !== (group?.previous_session_date ?? '') &&
                sessionDate !== (group?.next_session_date ?? '')) ||
            (sessionDate === (group?.previous_session_date ?? '') &&
                getRelationshipToToday(
                    new Date(group?.next_session_date ?? 0)
                ) !== 'upcoming')
        ) {
            showWarningToast(
                'This session closing space is no longer available'
            );
            onCloseModal();
        }
    }, [
        closings,
        group,
        group?.next_session_date,
        group?.previous_session_date,
        modalContent,
        onCloseModal,
        sessionDate,
        showSuccessToast,
        showWarningToast,
        userId,
    ]);

    useEffect(() => {
        if (retros.length === 0 || modalContent !== 'retro') {
            return;
        }

        retros.some((retro) => {
            if (
                getRelationshipToToday(new Date(retro.created_at ?? 0)) ===
                'current'
            ) {
                showSuccessToast(`You've already submitted a retro today!`);
                onCloseModal();
                return true;
            }
        }, []);
    }, [modalContent, onCloseModal, retros, showSuccessToast]);

    const sessionNumber = useMemo(() => {
        if (sessionDate === (group?.previous_session_date ?? '')) {
            return (group?.session_counter ?? 1) - 1;
        } else if (sessionDate === (group?.next_session_date ?? '')) {
            return group?.session_counter ?? 1;
        }
        return 0;
    }, [
        group?.next_session_date,
        group?.previous_session_date,
        group?.session_counter,
        sessionDate,
    ]);

    const onClickModalInnerContent = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
        },
        []
    );

    return modalContent == null ? null : (
        <ModalBackground ref={scrollRef} onClick={onCloseModal}>
            <div>
                <Bumper $minHeight={spacing.xxxLarge} $shouldHideOnMobile />
                <Bumper />
                <Card
                    onClick={onClickModalInnerContent}
                    $margin="auto"
                    $width="720px"
                >
                    <CloseButtonWrapper>
                        <Button onClick={onCloseModal} $style="float">
                            <Close />
                        </Button>
                    </CloseButtonWrapper>

                    {modalContent === 'closing' ? (
                        <SubmitClosing
                            sessionNumber={
                                sessionNumber ?? group?.session_counter ?? 0
                            }
                            sessionDate={sessionDate}
                            isPreviousSessionClosing={false}
                            onChangeFormStep={scrollToTop}
                            onClose={onCloseModal}
                        />
                    ) : null}

                    {modalContent === 'feedback' ? (
                        <FeedbackTool
                            onChangeFormStep={scrollToTop}
                            onClose={onCloseModal}
                        />
                    ) : null}

                    {modalContent === 'retro' ? (
                        <SubmitRetro
                            onChangeFormStep={scrollToTop}
                            onClose={onCloseModal}
                        />
                    ) : null}

                    {modalContent === 'retro-results' ? (
                        <RetroResults retroId={retroId} />
                    ) : null}

                    {modalContent === 'session-descriptions' ? (
                        <SessionDescriptions groupProgramId={groupProgramId} />
                    ) : null}

                    {modalContent === 'coach-recap' ? (
                        <SubmitRecap
                            selectedIncompleteRecapId={incompleteRecapId}
                            onChangeFormStep={scrollToTop}
                            onClose={onCloseModal}
                        />
                    ) : null}

                    {modalContent === 'recap-details' ? (
                        <RecapDetails submittedRecapId={submittedRecapId} />
                    ) : null}
                </Card>
                <Bumper />
                <Bumper $minHeight={spacing.xxxLarge} />
            </div>
        </ModalBackground>
    );
};
