import React, { useEffect, useMemo, useState } from 'react';
import {
    useLocation,
    useMatch,
    useParams,
    useSearchParams,
} from 'react-router-dom';

import { useAuth } from 'hooks/useAuth';
import { useMemberGroupData } from 'hooks/useMemberGroupData';
import { useNavigateWithParams } from 'hooks/useNavigateWithParams';

import { sortModules } from 'queries/groups';

import {
    formatDateTime,
    getDayCountBetweenDates,
    getRelationshipToToday,
} from 'libs/utils';

import { Tab, TabControls } from 'components/ui/TabControls';
import { MarkdownWrapper } from 'components/ui/MarkdownWrapper';
import { Tag } from 'components/ui/Tag';
import { ActionCard, ExternalLinkProps } from 'components/ui/ActionCard';

import { colors, Fonts, spacing } from 'styles/theme';
import {
    Card,
    ContentScroller,
    HorizontalFlexWrapper,
    Bumper,
} from 'styles/shared';

export const SessionDetails = () => {
    const { userProfile, userId } = useAuth();
    const { closings, group, scheduledSessions, sessionDetails } =
        useMemberGroupData();
    const { sessionNumber } = useParams();
    const { pathname } = useLocation();
    const navigateWithParams = useNavigateWithParams();

    const [, setSearchParams] = useSearchParams();
    const [initialPath, setInitialPath] = useState<string>('');

    const isScheduledSession = sessionNumber == null;

    const baseRoute = isScheduledSession
        ? '/member/scheduled-session'
        : `/member/all-sessions/${sessionNumber}`;

    const baseWithNoTabRoute = useMatch(`${baseRoute}`);
    const descriptionRoute = useMatch(`${baseRoute}/description/*`);
    const reviewRoute = useMatch(`${baseRoute}/review/*`);
    const applyRoute = useMatch(`${baseRoute}/apply/*`);
    const reflectRoute = useMatch(`${baseRoute}/reflect/*`);

    const sessionDate = useMemo(
        () =>
            isScheduledSession
                ? group?.next_session_date ?? ''
                : scheduledSessions?.[parseInt(sessionNumber, 10) - 1]?.start ??
                  null,
        [
            group?.next_session_date,
            isScheduledSession,
            scheduledSessions,
            sessionNumber,
        ]
    );

    const sessionDetailsDatum = useMemo(
        () =>
            sessionDetails[
                (isScheduledSession
                    ? group?.session_counter ?? 1
                    : parseInt(sessionNumber, 10)) - 1
            ] ?? null,
        [
            group?.session_counter,
            isScheduledSession,
            sessionDetails,
            sessionNumber,
        ]
    );

    const sortedModules = useMemo(
        () =>
            sortModules(
                sessionDetailsDatum?.session_details_modules ?? [],
                sessionDetailsDatum?.modules ?? []
            ),
        [
            sessionDetailsDatum?.modules,
            sessionDetailsDatum?.session_details_modules,
        ]
    );

    const sessionContent = useMemo(
        () => ({
            description:
                (sessionDetailsDatum?.description?.length ?? 0) > 0
                    ? [sessionDetailsDatum?.description ?? '']
                    : sortedModules?.map(
                          (module) => module?.description ?? ''
                      ) ?? [],
            review:
                sortedModules
                    ?.filter((module) => module?.review_content != null)
                    .map((module) => module?.review_content ?? '') ?? [],
            apply:
                sortedModules
                    ?.filter((module) => module?.apply_content != null)
                    .map((module) => module?.apply_content ?? '') ?? [],
            reflect:
                sortedModules
                    ?.filter((module) => module?.reflect_content != null)
                    .map((module) => module?.reflect_content ?? '') ?? [],
        }),
        [sessionDetailsDatum?.description, sortedModules]
    );

    const sessionDetailsTabs = useMemo(() => {
        const tabsToReturn: Array<Tab> = [];
        if (sessionDetails.length === 0) {
            return tabsToReturn;
        }

        if (sessionContent.description.length > 0) {
            tabsToReturn.push({
                text: 'Description',
                isSelected: descriptionRoute != null,
                onClick: () => navigateWithParams(`${baseRoute}/description`),
            });
        }
        if (sessionContent.review.length > 0) {
            tabsToReturn.push({
                text: 'Review',
                isSelected: reviewRoute != null,
                onClick: () => navigateWithParams(`${baseRoute}/review`),
            });
        }
        if (sessionContent.apply.length > 0) {
            tabsToReturn.push({
                text: 'Apply',
                isSelected: applyRoute != null,
                onClick: () => navigateWithParams(`${baseRoute}/apply`),
            });
        }
        if (sessionContent.reflect.length > 0) {
            tabsToReturn.push({
                text: 'Reflect',
                isSelected: reflectRoute != null,
                onClick: () => navigateWithParams(`${baseRoute}/reflect`),
            });
        }
        return tabsToReturn;
    }, [
        sessionDetails.length,
        sessionContent.description.length,
        sessionContent.review.length,
        sessionContent.apply.length,
        sessionContent.reflect.length,
        descriptionRoute,
        navigateWithParams,
        baseRoute,
        reviewRoute,
        applyRoute,
        reflectRoute,
    ]);

    useEffect(() => {
        setInitialPath(pathname);
        // Only want to set the initial path loaded on component mount
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (sessionDetailsTabs.length === 0 && baseWithNoTabRoute == null) {
            navigateWithParams(baseRoute);
        } else if (
            sessionDetailsTabs.length > 0 &&
            baseWithNoTabRoute != null
        ) {
            navigateWithParams(initialPath);
        }
    }, [
        baseRoute,
        baseWithNoTabRoute,
        initialPath,
        navigateWithParams,
        sessionDetailsTabs.length,
    ]);

    const sessionContentMarkdownStrings: Array<string> = useMemo(() => {
        if (descriptionRoute != null) {
            return sessionContent.description;
        } else if (reviewRoute != null) {
            return sessionContent.review;
        } else if (applyRoute != null) {
            return sessionContent.apply;
        } else if (reflectRoute != null) {
            return sessionContent.reflect;
        } else {
            return [];
        }
    }, [
        applyRoute,
        descriptionRoute,
        reflectRoute,
        reviewRoute,
        sessionContent.apply,
        sessionContent.description,
        sessionContent.reflect,
        sessionContent.review,
    ]);

    const daysUntilSessionDate = useMemo(
        () =>
            getDayCountBetweenDates(
                new Date(),
                group?.next_session_date == null
                    ? new Date()
                    : new Date(group.next_session_date)
            ),
        [group?.next_session_date]
    );

    const actionCardNode = useMemo(() => {
        if (getRelationshipToToday(new Date(sessionDate ?? 0)) === 'upcoming') {
            return null;
        }

        const completedClosing = closings.find(
            (closing) =>
                closing.created_by === userId &&
                closing.session_date === sessionDate
        );
        const exercisesFrameworks =
            sortedModules
                ?.map((module) =>
                    module?.exercises_frameworks.filter(
                        (exerciseFramework) =>
                            exerciseFramework.type === 'Exercise'
                    )
                )
                .flat() ?? [];

        if (completedClosing != null && exercisesFrameworks.length === 0) {
            return null;
        }

        const actionItems =
            completedClosing != null ||
            sessionDate == null ||
            (sessionDate !== group?.previous_session_date &&
                sessionDate !== group?.next_session_date) ||
            (sessionDate === group?.previous_session_date &&
                getRelationshipToToday(
                    new Date(group?.next_session_date ?? 0)
                ) !== 'upcoming')
                ? []
                : [
                      {
                          text: 'Session Closing',
                          onClick: () =>
                              setSearchParams({
                                  modalContent: 'closing',
                                  sessionDate: sessionDate,
                              }),
                      },
                  ];
        const externalLinks: Array<ExternalLinkProps> = [];
        exercisesFrameworks.forEach((exerciseFramework) => {
            // TypeScript infers that exerciseFramework can possibly be undefined,
            // which doesn't appear to be true, but optional chaining resolves it
            switch (exerciseFramework?.link) {
                case 'The Grand Retro': {
                    actionItems.push({
                        text: 'Take Grand Retro',
                        onClick: () =>
                            navigateWithParams('/member/insights/grand-retro'),
                    });
                    break;
                }
                case 'Feedback Tool': {
                    actionItems.push({
                        text: 'Feedback Tool',
                        onClick: () =>
                            setSearchParams({
                                modalContent: 'feedback',
                            }),
                    });
                    break;
                }
                case 'Topic Tool': {
                    externalLinks.push({
                        text: 'Topic Tool',
                        link: `https://topics-39ziwba.thegrand.world/?groupSession=${group?.gid}-${sessionNumber}`,
                    });
                    break;
                }
                default:
                    return;
            }
        });

        if (actionItems.length === 0 && externalLinks.length === 0) {
            return null;
        }

        return (
            <ActionCard
                actionItems={actionItems}
                externalLinks={externalLinks}
            />
        );
    }, [
        closings,
        group?.gid,
        group?.next_session_date,
        group?.previous_session_date,
        navigateWithParams,
        sessionDate,
        sessionNumber,
        setSearchParams,
        sortedModules,
        userId,
    ]);

    return (
        <>
            <ContentScroller>
                <Bumper $minHeight={spacing.xxxLarge} />
                <Bumper $minHeight={spacing.xxxLarge} />
                {isScheduledSession ? (
                    <>
                        <Fonts.Heading5>{`Hello, ${
                            userProfile?.full_name?.split(' ')[0] ??
                            'Grand Explorer'
                        }`}</Fonts.Heading5>
                        <Bumper $minHeight={spacing.xxxSmall} />
                        {userProfile?.imagined_future == null ||
                        userProfile.imagined_future === '' ? (
                            <>
                                <Fonts.MediumQuote>{`“Imagination is the beginning of creation. You imagine what you desire, you will what you imagine, and at last, you create what you will.”`}</Fonts.MediumQuote>
                                <Bumper $minHeight={spacing.xxSmall} />
                                <Fonts.Medium>{`– George Bernard Shaw`}</Fonts.Medium>
                            </>
                        ) : (
                            <>
                                <Fonts.MediumQuote>
                                    {`When you joined The Grand, you shared that 6 months from now you hoped you would be... “${userProfile.imagined_future}”`}
                                </Fonts.MediumQuote>
                            </>
                        )}
                        <Bumper />
                    </>
                ) : null}

                <Card>
                    <Fonts.XLarge $color={colors.mountainAsh}>
                        {`Session ${
                            isScheduledSession
                                ? group?.session_counter
                                : sessionNumber
                        }`}
                    </Fonts.XLarge>
                    <Bumper $minHeight={spacing.xxxSmall} />
                    <HorizontalFlexWrapper>
                        <Fonts.Medium $color={colors.grandGreen}>
                            {formatDateTime(
                                new Date(
                                    isScheduledSession &&
                                    group?.next_session_date != null
                                        ? group.next_session_date
                                        : scheduledSessions?.[
                                              parseInt(
                                                  sessionNumber ?? '',
                                                  10
                                              ) - 1
                                          ]?.start ?? 0
                                )
                            )}
                        </Fonts.Medium>
                        {Math.abs(daysUntilSessionDate) !==
                        daysUntilSessionDate ? null : (
                            <Tag
                                text={
                                    daysUntilSessionDate === 0
                                        ? 'Today'
                                        : `In ${daysUntilSessionDate} days`
                                }
                            />
                        )}
                    </HorizontalFlexWrapper>
                    {sessionDetailsDatum?.title == null ? null : (
                        <>
                            <Bumper $minHeight={spacing.medium} />
                            <Fonts.Heading5>
                                {sessionDetailsDatum.title}
                            </Fonts.Heading5>
                        </>
                    )}
                    {sessionDetailsTabs.length > 0 ? (
                        <>
                            <Bumper />
                            <TabControls tabs={sessionDetailsTabs} />
                            <Bumper $minHeight={spacing.xLarge} />
                            {descriptionRoute == null ? null : (
                                <>
                                    <Fonts.Heading7>{`Description`}</Fonts.Heading7>
                                    <Bumper />
                                </>
                            )}
                            {sessionContentMarkdownStrings.map(
                                (markdownString, index) => (
                                    <MarkdownWrapper
                                        key={index}
                                        markdownString={markdownString}
                                        $shouldReduceTopMargin={
                                            descriptionRoute != null &&
                                            index > 0
                                        }
                                    />
                                )
                            )}
                        </>
                    ) : (
                        <>
                            <Bumper />
                            {actionCardNode}
                        </>
                    )}
                </Card>
                <Bumper $minHeight={spacing.xxxLarge} />
                <Bumper $minHeight={spacing.small} />
            </ContentScroller>
            {sessionDetailsTabs.length > 0 ? (
                <>
                    <Bumper $minWidth={spacing.small} />
                    <div>
                        <Bumper $minHeight={spacing.xxxLarge} />
                        <Bumper $minHeight={spacing.xxxLarge} />
                        {actionCardNode}
                    </div>
                </>
            ) : null}
        </>
    );
};
