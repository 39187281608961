import React from 'react';

import { CustomSVGProps, setSVGColor } from 'styles/theme';

export const Lightning = (props: CustomSVGProps) => (
    <svg
        width={14}
        height={20}
        viewBox="0 0 14 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12.9583 3.15073C13.0242 3.05995 13.1002 2.97654 13.1713 2.88984C13.9309 1.96378 13.2966 0.5 12.0613 0.5H6.21845C5.80713 0.5 5.41556 0.679564 5.14303 0.993161L0.863198 7.57008C0.0434175 8.5134 0.701351 10 1.93862 10H2.2073C3.36683 10 4.02925 11.3141 3.40728 12.2649C3.35587 12.3435 3.30618 12.424 3.27052 12.5109L1.12428 17.7403C1.05296 17.9141 0.943824 18.0717 0.875845 18.2468C0.653408 18.8198 1.0579 19.5 1.71972 19.5C1.89108 19.5 2.05891 19.4504 2.20363 19.357L11.0208 12.2752C12.0454 11.6138 11.5851 10 10.3718 10C9.30939 10 8.77374 8.7172 9.47281 7.93897C9.51028 7.89726 9.54777 7.85515 9.58068 7.80976L12.9583 3.15073Z"
            stroke={setSVGColor(props)}
        />
    </svg>
);
