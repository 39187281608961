import React, { useEffect, useMemo } from 'react';
import { Outlet, useMatch } from 'react-router-dom';

import { useMemberGroupData } from 'hooks/useMemberGroupData';
import { useNavigateWithParams } from 'hooks/useNavigateWithParams';

import { getRelationshipToToday } from 'libs/utils';

import { NavMenu } from 'components/ui/NavMenu';

import { Home } from 'components/svg/Home';
import { Star } from 'components/svg/Star';
import { Idea } from 'components/svg/Idea';

import { MainViewWrapper } from 'styles/shared';

export const Member = () => {
    const { group, sessionDetails, scheduledSessions, closings } =
        useMemberGroupData();
    const navigateWithParams = useNavigateWithParams();

    const memberWithNoSubRoute = useMatch('/member');
    const scheduledSessionRoute = useMatch('/member/scheduled-session/*');
    const allSessionsRoute = useMatch('/member/all-sessions/*');
    const insightsRoute = useMatch('/member/insights/*');

    useEffect(() => {
        if (memberWithNoSubRoute != null) {
            navigateWithParams('/member/scheduled-session/description');
        }
    }, [memberWithNoSubRoute, navigateWithParams]);

    useEffect(() => {
        if (
            scheduledSessions.length > 0 &&
            sessionDetails.length === 0 &&
            group?.next_session_date != null &&
            getRelationshipToToday(new Date(group.next_session_date)) !==
                'upcoming' &&
            closings.find(
                (closing) => closing.session_date === group.next_session_date
            ) != null
        ) {
            navigateWithParams('/member/insights/my-learnings');
        }

        // The missing dependency is navigateWithParams, but adding it would cause
        // this to re-run every time you changed a route, preventing navigation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        closings,
        group?.next_session_date,
        scheduledSessions.length,
        sessionDetails.length,
    ]);

    const scheduledSessionText = useMemo(() => {
        if (group?.next_session_date == null) {
            return '';
        }

        switch (getRelationshipToToday(new Date(group.next_session_date))) {
            case 'previous': {
                return 'Last Session';
            }
            case 'current': {
                return `Today's Session`;
            }
            case 'upcoming': {
                return 'Next Session';
            }
            case null:
            default: {
                return '';
            }
        }
    }, [group?.next_session_date]);

    const menuItems = useMemo(() => {
        const menuItemsToReturn = [];

        if (scheduledSessionText !== '') {
            menuItemsToReturn.push({
                text: scheduledSessionText,
                link: '/member/scheduled-session/description',
                SVGComponent: Home,
                isSelected: scheduledSessionRoute != null,
            });
        }

        if (sessionDetails.length > 0) {
            menuItemsToReturn.push({
                text: 'All Sessions',
                link: '/member/all-sessions',
                SVGComponent: Star,
                isSelected: allSessionsRoute != null,
            });
        }

        menuItemsToReturn.push({
            text: 'Insights',
            link: '/member/insights/my-learnings',
            SVGComponent: Idea,
            isSelected: insightsRoute != null,
        });

        return menuItemsToReturn;
    }, [
        allSessionsRoute,
        insightsRoute,
        scheduledSessionRoute,
        scheduledSessionText,
        sessionDetails.length,
    ]);

    return (
        <MainViewWrapper>
            <NavMenu menuItems={menuItems} />
            <Outlet />
        </MainViewWrapper>
    );
};
