import React from 'react';

import { CustomSVGProps, setSVGColor } from 'styles/theme';

export const Star = (props: CustomSVGProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={17}
        fill="none"
        {...props}
    >
        <path
            stroke={setSVGColor(props)}
            d="M7.993 1.422c.372-.896 1.642-.896 2.014 0l1.382 3.322c.157.378.513.636.92.669l3.587.287c.967.078 1.36 1.285.623 1.916l-2.733 2.341a1.09 1.09 0 0 0-.352 1.082l.835 3.5c.225.944-.801 1.69-1.63 1.184l-3.07-1.876a1.09 1.09 0 0 0-1.138 0l-3.07 1.876c-.829.506-1.855-.24-1.63-1.184l.835-3.5a1.09 1.09 0 0 0-.352-1.082l-2.733-2.34c-.737-.632-.344-1.84.623-1.917l3.587-.287a1.09 1.09 0 0 0 .92-.669z"
        />
    </svg>
);
