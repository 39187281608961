import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth';

import { MenuItem, MenuItemProps } from 'components/ui/MenuItem';
import { Avatar } from 'components/ui/Avatar';

import { GrandLogoHorz } from 'components/svg/GrandLogoHorz';

import { colors, Fonts, spacing } from 'styles/theme';
import { Card, Divider, Bumper, HorizontalFlexWrapper } from 'styles/shared';

interface NavMenuItemProps extends MenuItemProps {
    link: string;
}

interface NavMenuProps {
    menuItems: Array<NavMenuItemProps>;
}

export const NavMenu = ({ menuItems }: NavMenuProps) => {
    const { signOut, userProfile } = useAuth();

    const onSignOut = useCallback(() => {
        signOut?.();
    }, [signOut]);

    return (
        <div>
            <Bumper $minHeight={spacing.xxxLarge} />
            <Bumper $minHeight={spacing.xxxLarge} />
            <Card
                $margin={`0 ${spacing.xxxLarge} 0 0`}
                $width="236px"
                $padding={spacing.large}
            >
                <GrandLogoHorz width={100} />
                <Divider />
                {menuItems.map(({ text, link, SVGComponent, isSelected }) => (
                    <Link key={link} to={link}>
                        <MenuItem
                            SVGComponent={SVGComponent}
                            text={text}
                            isSelected={isSelected}
                        />
                    </Link>
                ))}
                <Divider />

                <HorizontalFlexWrapper>
                    <Avatar
                        profileInitials={`${
                            userProfile?.full_name?.split(' ')?.[0]?.[0] ?? ''
                        }${userProfile?.full_name?.split(' ')?.[1]?.[0] ?? ''}`}
                    />
                    <Bumper $minWidth={spacing.xSmall} />
                    <button onClick={onSignOut}>
                        <Fonts.Small
                            $color={colors.grey40}
                            $isUnderlined
                        >{`Log Out`}</Fonts.Small>
                    </button>
                </HorizontalFlexWrapper>
            </Card>
        </div>
    );
};
