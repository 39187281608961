import React, { useMemo } from 'react';

import { DateRelationship } from 'libs/utils';

import { CheckRounded } from 'components/svg/CheckRounded';
import { Lock } from 'components/svg/Lock';
import { Unlock } from 'components/svg/Unlock';

import { colors, Fonts, transparencies } from 'styles/theme';
import {
    ChronologyDataWrapper,
    HeaderTextWrapper,
    InfoCardWrapper,
    TitleWrapper,
} from 'styles/infoCard';
import { ProgressBar, ProgressBarWrapper } from 'styles/shared';

interface InfoCardProps {
    topLineText?: string;
    headerText?: string;
    progressPercentage?: number;
    dateRelationship: DateRelationship;
    mainText: string;
    onClick: () => void;
}

export const InfoCard = ({
    topLineText,
    headerText,
    progressPercentage,
    dateRelationship,
    mainText,
    onClick,
}: InfoCardProps) => {
    const svgToRender = useMemo(() => {
        switch (dateRelationship) {
            case 'previous': {
                return <CheckRounded color={colors.success} />;
            }
            case 'current': {
                return <Unlock color={colors.success} />;
            }
            case 'upcoming':
            default: {
                return <Lock color={colors.grey30} />;
            }
        }
    }, [dateRelationship]);

    return (
        <InfoCardWrapper
            onClick={onClick}
            $isUpcoming={dateRelationship === 'upcoming'}
        >
            <ChronologyDataWrapper>
                <HeaderTextWrapper
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                    }}
                >
                    {topLineText == null ? null : (
                        <Fonts.Small $color={colors.grandGreen}>
                            {topLineText}
                        </Fonts.Small>
                    )}
                    {headerText == null ? null : (
                        <Fonts.Small
                            $color={colors.grandGreen}
                            $isOpenSansSemiBold
                        >
                            {headerText}
                        </Fonts.Small>
                    )}
                </HeaderTextWrapper>
                {progressPercentage == null ? null : (
                    <ProgressBarWrapper
                        $color={transparencies.darkJungle10}
                        $isStretch
                    >
                        <ProgressBar
                            $color={colors.success}
                            $width={progressPercentage}
                        />
                    </ProgressBarWrapper>
                )}
                {svgToRender}
            </ChronologyDataWrapper>
            <TitleWrapper $isUpcoming={dateRelationship === 'upcoming'}>
                <Fonts.Large $color={colors.grandGreen}>{mainText}</Fonts.Large>
            </TitleWrapper>
        </InfoCardWrapper>
    );
};
