import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import { Button } from 'components/ui/Button';

import { spacing } from 'styles/theme';
import {
    CardBottomButtonWrapper,
    Bumper,
    BackButtonWrapper,
    ProgressBar,
    ProgressBarWrapper,
} from 'styles/shared';

export interface FormStep {
    formNode: ReactNode;
    isComplete: boolean;
    submitText?: string;
    shouldHideProgress?: boolean;
    shouldHideHeader?: boolean;
    onSubmitStep?: () => void;
}

interface SteppedFormProps {
    headerNode?: ReactNode;
    formSteps: Array<FormStep>;
    onChangeFormStep?: () => void;
    onClose?: () => void;
    onFinalSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
    isLoadingSubmission?: boolean;
    $isBottomAreaWhite?: boolean;
}

export const SteppedForm = ({
    headerNode,
    formSteps,
    onChangeFormStep,
    onClose,
    onFinalSubmit,
    isLoadingSubmission,
    $isBottomAreaWhite,
}: SteppedFormProps) => {
    const [currentStep, setCurrentStep] = useState<number>(0);

    const progressPercentage = useMemo(() => {
        if (currentStep === 0) {
            return 5;
        } else if (currentStep === formSteps.length - 1) {
            return 95;
        }

        return (currentStep / (formSteps.length - 1)) * 100;
    }, [currentStep, formSteps.length]);

    const handlePressBack = useCallback(() => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
            onChangeFormStep?.();
        }
    }, [currentStep, onChangeFormStep]);

    const handleSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (currentStep === formSteps.length - 1) {
                formSteps[currentStep]?.onSubmitStep?.();
                onFinalSubmit?.(event);
                onClose?.();
                return;
            }

            if (formSteps[currentStep]?.isComplete) {
                formSteps[currentStep]?.onSubmitStep?.();
                setCurrentStep(currentStep + 1);
                onChangeFormStep?.();
            }
        },
        [currentStep, formSteps, onChangeFormStep, onClose, onFinalSubmit]
    );

    return (
        <>
            {formSteps[currentStep]?.shouldHideProgress ||
            currentStep === 0 ? null : (
                <BackButtonWrapper>
                    <Button onClick={handlePressBack} isBackButton />
                </BackButtonWrapper>
            )}
            {formSteps[currentStep]?.shouldHideProgress ? null : (
                <ProgressBarWrapper>
                    <ProgressBar $width={progressPercentage} />
                </ProgressBarWrapper>
            )}

            <Bumper />
            {headerNode == null || formSteps[currentStep]?.shouldHideHeader
                ? null
                : headerNode}

            <form onSubmit={handleSubmit} style={{ height: 'auto' }}>
                {formSteps[currentStep]?.formNode}
                <Bumper $minHeight={spacing.xLarge} />
                <CardBottomButtonWrapper
                    $backgroundColor={
                        currentStep === 0 || $isBottomAreaWhite
                            ? 'white'
                            : 'light'
                    }
                >
                    <Button
                        type="submit"
                        value="Submit"
                        $isLoading={isLoadingSubmission ?? false}
                        $isDisabled={!formSteps[currentStep]?.isComplete}
                    >
                        {formSteps[currentStep]?.submitText != null
                            ? formSteps[currentStep]?.submitText
                            : currentStep === formSteps.length - 1
                            ? `Submit`
                            : `Next`}
                    </Button>
                </CardBottomButtonWrapper>
            </form>
        </>
    );
};
