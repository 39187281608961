import React, { useEffect, useMemo, useState } from 'react';

import { Fonts, colors } from 'styles/theme';
import { Bumper } from 'styles/shared';

import { useToast } from 'hooks/useToast';

import { Loader } from 'components/ui/Loader';
import { MarkdownWrapper } from 'components/ui/MarkdownWrapper';

import {
    sortSessionDetails,
    sortModules,
    JoinedGroupProgramHistory,
    groupProgramHistoryQuery,
} from 'queries/groups';

interface SessionDescriptionsProps {
    groupProgramId: string;
}

export const SessionDescriptions = ({
    groupProgramId,
}: SessionDescriptionsProps) => {
    const { showErrorToast } = useToast();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedGroupProgramHistory, setSelectedGroupProgramHistory] =
        useState<JoinedGroupProgramHistory | null>(null);

    useEffect(() => {
        const getSelectedGroupProgramHistory = async () => {
            const { data, error } = await groupProgramHistoryQuery.eq(
                'id',
                groupProgramId
            );

            if (error == null) {
                setIsLoading(false);
                const joinedGroupProgramHistory: JoinedGroupProgramHistory =
                    data;
                setSelectedGroupProgramHistory(joinedGroupProgramHistory);
            } else {
                showErrorToast(error.message);
            }
        };

        getSelectedGroupProgramHistory();
    }, [groupProgramId, showErrorToast]);

    const sortedSessionDetails = useMemo(
        () => sortSessionDetails(selectedGroupProgramHistory),
        [selectedGroupProgramHistory]
    );

    return (
        <>
            <Fonts.Heading6>{`Session Objectives`}</Fonts.Heading6>
            <Bumper />
            {isLoading ? (
                <Loader />
            ) : (
                sortedSessionDetails.map(
                    // Type check is working in development, but compiler flags on build
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    (sessionDetailsDatum, index) => {
                        const sortedModules = sortModules(
                            sessionDetailsDatum?.session_details_modules ?? [],
                            sessionDetailsDatum?.modules ?? []
                        );
                        return (
                            <>
                                <Bumper />
                                <Fonts.XSmall
                                    $isCapitalized
                                    $isOpenSansSemiBold
                                    $color={colors.mountainAsh}
                                >{`Session ${index + 1}:`}</Fonts.XSmall>
                                <Fonts.Heading7>
                                    {sessionDetailsDatum?.title}
                                </Fonts.Heading7>
                                <Bumper />
                                <br />
                                {(sessionDetailsDatum?.description?.length ??
                                    0) > 0 ? (
                                    <MarkdownWrapper
                                        markdownString={
                                            sessionDetailsDatum?.description ??
                                            ''
                                        }
                                    />
                                ) : (
                                    sortedModules.map((module, moduleIndex) => (
                                        <>
                                            {moduleIndex !== 0 ? (
                                                // TODO: remove temporary inline styling
                                                <div
                                                    style={{
                                                        marginTop: '-28px',
                                                    }}
                                                />
                                            ) : null}
                                            <MarkdownWrapper
                                                key={`${index}--${moduleIndex}`}
                                                markdownString={
                                                    module?.description ?? ''
                                                }
                                            />
                                        </>
                                    ))
                                )}
                                {/* TODO: remove temporary inline styling */}
                                <div
                                    style={{
                                        marginBottom: '-24px',
                                    }}
                                />
                            </>
                        );
                    }
                )
            )}
        </>
    );
};
