import React from 'react';

import { RETRO_DIMENSIONS } from 'libs/constants';

import CareerWellbeing from 'assets/retro-career-wellbeing.png';
import EmotionalResilience from 'assets/retro-emotional-resilience.png';
import EnvironmentalWellbeing from 'assets/retro-environmental-wellbeing.png';
import FinancialWellbeing from 'assets/retro-financial-wellbeing.png';
import IntellectualCuriosity from 'assets/retro-intellectual-curiosity.png';
import PhysicalWellbeing from 'assets/retro-physical-wellbeing.png';
import SocialWellbeing from 'assets/retro-social-wellbeing.png';
import SpiritualAwareness from 'assets/retro-spiritual-awareness.png';

import { colors, Fonts, spacing } from 'styles/theme';
import { Bumper, GridListWrapper } from 'styles/shared';
import { DimensionCardWrapper } from 'styles/retroDimensions';

export const getDimensionImageSrc = (
    dimension: keyof typeof RETRO_DIMENSIONS
) => {
    switch (dimension) {
        case 'Career Wellbeing': {
            return CareerWellbeing;
        }
        case 'Emotional Resilience': {
            return EmotionalResilience;
        }
        case 'Environmental Wellbeing': {
            return EnvironmentalWellbeing;
        }
        case 'Financial Wellbeing': {
            return FinancialWellbeing;
        }
        case 'Intellectual Curiosity': {
            return IntellectualCuriosity;
        }
        case 'Physical Wellbeing': {
            return PhysicalWellbeing;
        }
        case 'Social Wellbeing': {
            return SocialWellbeing;
        }
        case 'Spiritual Awareness':
        default: {
            return SpiritualAwareness;
        }
    }
};

interface AssessmentSubtotalsProps {
    // TODO: properly type this
    subtotals: object | null;
}

export const DimensionSubtotals = ({ subtotals }: AssessmentSubtotalsProps) => (
    <GridListWrapper>
        {(
            Object.keys(RETRO_DIMENSIONS) as (keyof typeof RETRO_DIMENSIONS)[]
        ).map((dimension) => (
            <DimensionCardWrapper key={dimension}>
                <img
                    src={getDimensionImageSrc(dimension)}
                    width={80}
                    alt="dimension-image"
                />
                <Fonts.Medium
                    $textAlign="center"
                    $color={colors.mountainAsh}
                    $isOpenSansSemiBold
                >
                    {dimension}
                </Fonts.Medium>
                <Bumper $minHeight={spacing.xSmall} />
                <Fonts.Medium>{`Total: ${
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    subtotals?.[dimension] ?? 0
                }`}</Fonts.Medium>
            </DimensionCardWrapper>
        ))}
    </GridListWrapper>
);
