import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'components/ui/Button';

import { Lightning } from 'components/svg/Lightning';

import { colors, Fonts, spacing } from 'styles/theme';
import { Card, Divider, Bumper, HorizontalFlexWrapper } from 'styles/shared';

export interface ActionItemProps {
    text: string;
    onClick: () => void;
}

// Can be removed once Topic Tool has been integrated into platform
export interface ExternalLinkProps {
    text: string;
    link: string;
}

interface ActionCardProps {
    actionItems: Array<ActionItemProps>;
    externalLinks: Array<ExternalLinkProps>;
}

export const ActionCard = ({ actionItems, externalLinks }: ActionCardProps) => {
    return (
        <Card $width="236px" $padding={spacing.large}>
            <HorizontalFlexWrapper $isMarginAuto>
                <Lightning color={colors.mountainAsh} />
                <Bumper $minWidth={spacing.xxSmall} />
                <Fonts.Medium
                    $color={colors.mountainAsh}
                    $isOpenSansSemiBold
                >{`Take Action`}</Fonts.Medium>
            </HorizontalFlexWrapper>
            <Divider />
            {actionItems.map(({ text, onClick }, index) => (
                <Fragment key={text}>
                    <Button
                        key={text}
                        onClick={onClick}
                        $style="ghost"
                        $isStretch
                        $isSmall
                    >
                        {text}
                    </Button>
                    {index + 1 === actionItems.length &&
                    externalLinks.length === 0 ? null : (
                        <Bumper $minHeight={spacing.xSmall} />
                    )}
                </Fragment>
            ))}
            {externalLinks.map(({ text, link }, index) => (
                <Fragment key={text}>
                    <Link to={link} target="_blank" rel="noreferrer">
                        <Button $style="ghost" $isStretch $isSmall>
                            {text}
                        </Button>
                    </Link>
                    {index + 1 === actionItems.length ? null : (
                        <Bumper $minHeight={spacing.xSmall} />
                    )}
                </Fragment>
            ))}
        </Card>
    );
};
