import React, { useCallback, useState } from 'react';

import { RETRO_DIMENSIONS } from 'libs/constants';
import { mapDimensionQuestion } from 'libs/utils';

import { ChevronSmall } from 'components/svg/ChevronSmall';

import { colors, Fonts, spacing } from 'styles/theme';
import { Bumper, HorizontalFlexWrapper, NestedCard } from 'styles/shared';
import {
    ResponsesWrapper,
    DimensionSubtotalWrapper,
    DropdownChevronWrapper,
    SubtotalNumberWrapper,
} from 'styles/retroDimensions';

interface DimensionResponsesProps {
    // TODO: properly type this
    retroDataPayload: object | null;
}

export const DimensionResponses = (props: DimensionResponsesProps) => {
    const { retroDataPayload } = props;

    const [expandedDimension, setExpandedDimension] = useState<string>('');

    const onExpandCategory = useCallback(
        (dimension: string) => () => {
            if (dimension === expandedDimension) {
                setExpandedDimension('');
            } else {
                setExpandedDimension(dimension);
            }
        },
        [expandedDimension]
    );

    return (
        <NestedCard>
            {Object.keys(RETRO_DIMENSIONS).map((dimension) => (
                <ResponsesWrapper
                    key={dimension}
                    onClick={onExpandCategory(dimension)}
                >
                    <DimensionSubtotalWrapper>
                        <SubtotalNumberWrapper>
                            <Fonts.Small
                                $color={colors.mountainAsh}
                                $isOpenSansSemiBold
                            >
                                {/* A very annoying TypeScript error that requires you to type the object, its keys */}
                                {/* and its values even though you're iterating through that same object's keys */}
                                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                {/* @ts-ignore */}
                                {retroDataPayload?.subtotals?.[dimension]}
                            </Fonts.Small>
                        </SubtotalNumberWrapper>
                        <Fonts.Medium $isOpenSansSemiBold>
                            {dimension}
                        </Fonts.Medium>
                        <DropdownChevronWrapper
                            $isDropdownOpen={expandedDimension === dimension}
                        >
                            <ChevronSmall width={12} height={12} />
                        </DropdownChevronWrapper>
                    </DimensionSubtotalWrapper>
                    {expandedDimension === dimension ? (
                        <>
                            <Bumper $minHeight={spacing.xxSmall} />
                            {/* See above */}
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            {RETRO_DIMENSIONS[dimension].map(
                                (question: string, index: number) => {
                                    const databaseQuestionParam =
                                        mapDimensionQuestion(
                                            dimension,
                                            index
                                        ) as keyof typeof retroDataPayload;
                                    const questionResponse =
                                        retroDataPayload?.[
                                            databaseQuestionParam
                                        ];

                                    return (
                                        <>
                                            <Bumper
                                                $minHeight={spacing.xxSmall}
                                            />
                                            <HorizontalFlexWrapper
                                                key={question}
                                            >
                                                <Bumper
                                                    $minHeight={
                                                        spacing.xxxSmall
                                                    }
                                                    $minWidth={spacing.xSmall}
                                                />
                                                <Fonts.Small
                                                    $color={colors.grandGreen}
                                                    $isOpenSansSemiBold
                                                >
                                                    {questionResponse}
                                                </Fonts.Small>
                                                <Bumper
                                                    $minHeight={
                                                        spacing.xxxSmall
                                                    }
                                                    $minWidth={spacing.medium}
                                                />
                                                <Fonts.Small
                                                    $color={colors.grandGreen}
                                                >
                                                    {question}
                                                </Fonts.Small>
                                            </HorizontalFlexWrapper>
                                            <Bumper
                                                $minHeight={spacing.xxSmall}
                                            />
                                        </>
                                    );
                                }
                            )}
                        </>
                    ) : null}
                </ResponsesWrapper>
            ))}
        </NestedCard>
    );
};
