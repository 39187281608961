import React from 'react';

import { CustomSVGProps, setSVGColor } from 'styles/theme';

export const Lock = (props: CustomSVGProps) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M0.5 9.38674H2.98023e-07H0.5ZM0.5 16.5H1H0.5ZM16.5 19.5V19V19.5ZM3.5 19.5V20V19.5ZM19.5 9.38675H19H19.5ZM19.5 16.5H20H19.5ZM16.5 6.38676V5.88676V6.38676ZM3.5 6.38674L3.5 6.88674L3.5 6.38674ZM9.5 14.1004C9.5 14.3766 9.72386 14.6004 10 14.6004C10.2761 14.6004 10.5 14.3766 10.5 14.1004H9.5ZM10.5 11.015C10.5 10.7388 10.2761 10.515 10 10.515C9.72386 10.515 9.5 10.7388 9.5 11.015H10.5ZM2.98023e-07 9.38674L0 16.5H1L1 9.38674H2.98023e-07ZM16.5 19H3.5V20H16.5V19ZM19 9.38675V16.5H20V9.38675H19ZM16.5 5.88676L3.50001 5.88674L3.5 6.88674L16.5 6.88676V5.88676ZM20 9.38675C20 7.45376 18.433 5.88676 16.5 5.88676V6.88676C17.8807 6.88676 19 8.00604 19 9.38675H20ZM1 9.38674C1 8.00602 2.11929 6.88673 3.5 6.88674L3.50001 5.88674C1.56701 5.88673 3.8743e-07 7.45374 2.98023e-07 9.38674H1ZM0 16.5C-5.96046e-08 18.433 1.567 20 3.5 20V19C2.11929 19 1 17.8807 1 16.5H0ZM16.5 20C18.433 20 20 18.433 20 16.5H19C19 17.8807 17.8807 19 16.5 19V20ZM4.65384 6.67094C4.65384 3.51315 7.07253 1 10 1V0C6.46999 0 3.65384 3.0125 3.65384 6.67094H4.65384ZM10 1C12.9275 1 15.3462 3.51315 15.3462 6.67094H16.3462C16.3462 3.0125 13.53 0 10 0V1ZM10.5 14.1004L10.5 11.015H9.5L9.5 14.1004H10.5Z"
            fill={setSVGColor(props)}
        />
    </svg>
);
