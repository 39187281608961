import styled, { css } from 'styled-components';
import { colors } from 'styles/theme';

export interface PillButtonProps {
    $isSelected: boolean;
    $isDisabled?: boolean;
}

export const Overlays = {
    ButtonHover: styled.div`
        position: absolute;
        top: 0;
        left: 0;
        border-radius: inherit;
        height: 100%;
        width: 100%;
        background-color: ${colors.black};
        opacity: 0;
        transition: 0.2s;

        &:hover,
        &:active {
            opacity: 0.2;
        }
    `,
};

export const Buttons = {
    OverlayMedium: styled(Overlays.ButtonHover)`
        background-color: ${colors.black};

        &:hover,
        &:active {
            opacity: 0.1;
        }
    `,
    Pill: styled.button<PillButtonProps>`
        position: relative;
        margin: 8px;
        margin-right: 12px;
        margin-left: 0;
        border: none;
        outline: none;
        border-radius: 64px;
        background-color: ${colors.grey10};
        padding: 4px 12px;

        ${(props) =>
            props.$isSelected
                ? css`
                      background-color: ${colors.grey30};
                  `
                : null};

        ${(props) =>
            props.$isDisabled
                ? css`
                      cursor: default;
                  `
                : null};
    `,
} as const;
