import styled, { css } from 'styled-components';

import { Fonts, spacing, colors } from 'styles/theme';

export const ListWrapper = styled.div`
    overflow: hidden;
    width: 100%;
    max-width: 850px;

    @media only screen and (max-width: ${spacing.mobileBreakpoint}) {
        width: 100vw;
        padding: 0 16px;
    }
`;

export const ScrollerWrapper = styled.div`
    max-height: calc(100%);
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
`;

export const CoachSelectWrapper = styled.div`
    width: 100%;
    display: flex;
    padding-left: 12px;
`;

export const RecapsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

export const SignOutWrapper = styled.div`
    position: absolute;
    bottom: 32px;
`;

// For SubmitRecap
export interface PillButtonProps {
    $isSelected: boolean;
}

export const SessionExercisesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const ObjectiveSelectorWrapper = styled.div`
    border: 1px solid lightgrey;
    border-radius: 8px;
    background-color: white;
    width: 100%;
    padding: 24px;
    padding-bottom: 0;
`;

export const Divider = styled.div`
    width: 100%;
    min-height: 1px;
    background-color: ${colors.grey10};
`;

export const ExplainerTextWrapper = styled.div`
    margin-bottom: 6px;
`;

// Recap Details
export const List = styled.div`
    list-style: inside;
`;

export const ItalicText = styled(Fonts.Medium)`
    font-style: italic;
`;

export const LinkText = styled(Fonts.Medium)`
    &:hover,
    &:active {
        color: ${colors.grandGreen};
    }
`;

// Legacy theme
export const Buttons = {
    ButtonHover: styled.div`
        position: absolute;
        top: 0;
        left: 0;
        border-radius: inherit;
        height: 100%;
        width: 100%;
        background-color: ${colors.black};
        opacity: 0;
        transition: 0.2s;

        &:hover,
        &:active {
            opacity: 0.2;
        }
    `,
    Pill: styled.button<PillButtonProps>`
        position: relative;
        margin: 8px;
        margin-right: 12px;
        margin-left: 0;
        border: none;
        outline: none;
        border-radius: 64px;
        background-color: ${colors.grey40};
        padding: 4px 12px;
        cursor: pointer;

        ${(props) =>
            props.$isSelected
                ? css`
                      background-color: ${colors.darkJungle};
                  `
                : null};
    `,
};
