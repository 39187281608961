import styled from 'styled-components';

import { spacing } from 'styles/theme';
import { Card } from 'styles/shared';

export const AuthWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const AuthForm = styled(Card).attrs({ as: 'form' })`
    // Custom width to line up with child Cloudfare Turnstile width
    width: 366px;
    align-items: center;
`;

export const StepsCompleteTextWrapper = styled.div`
    margin: ${spacing.large};
    max-width: 460px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
