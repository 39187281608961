import styled, { css } from 'styled-components';

import { colors, shadows, spacing, transparencies } from 'styles/theme';

export interface ButtonProps {
    $isBackButton?: boolean;
    $isLoading?: boolean;
    $isDisabled?: boolean;
    $isStretch?: boolean;
    $isSmall?: boolean;
    $style?: 'fill' | 'outline' | 'ghost' | 'float';
}

export const Button = styled.button<ButtonProps>`
    position: relative;
    outline: 0;
    border: 1px solid ${colors.grandGreen};
    border-radius: ${spacing.radiusSmall};
    background-color: ${colors.grandGreen};
    padding: ${spacing.xSmall} ${spacing.small};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.white};
    cursor: pointer;
    transition: box-shadow 0.2s, background-color 0.2s;

    &:hover {
        box-shadow: ${shadows.buttonBoxShadow};
    }

    ${(props) =>
        props.$isBackButton
            ? css`
                  // Customized padding for symmetry
                  padding: ${spacing.xxSmall} 10px;
                  transform: scaleX(-1);
              `
            : null};

    ${(props) =>
        props.$isLoading
            ? css`
                  padding: 0 0 3px;
                  pointer-events: none;
              `
            : null};

    ${(props) =>
        props.$isDisabled
            ? css`
                  opacity: 0.3;
                  pointer-events: none;
              `
            : null};

    ${(props) =>
        props.$isStretch
            ? css`
                  width: 100%;
              `
            : null};

    ${(props) =>
        props.$isSmall
            ? css`
                  padding: ${spacing.xxSmall} ${spacing.xSmall};
                  font-size: 14px;
              `
            : null};

    ${(props) => {
        switch (props.$style) {
            case 'outline': {
                return css`
                    background-color: transparent;
                    color: ${colors.grandGreen};

                    &:hover {
                        background-color: ${transparencies.darkJungle10};
                        box-shadow: none;
                    }
                `;
            }
            case 'ghost': {
                return css`
                    border-color: ${colors.light};
                    background-color: ${colors.light};
                    color: ${colors.grandGreen};

                    &:hover {
                        background-color: ${colors.grey10};
                        box-shadow: none;
                    }
                `;
            }
            case 'float': {
                return css`
                    border-color: transparent;
                    background-color: transparent;
                    color: ${colors.grandGreen};

                    &:hover {
                        box-shadow: none;
                    }
                `;
            }
            case 'fill':
            default: {
                return;
            }
        }
    }};
`;
