import styled, { css } from 'styled-components';

import { shadows, spacing, transparencies } from 'styles/theme';

interface InfoCardProps {
    $isUpcoming: boolean;
}

export const InfoCardWrapper = styled.button<InfoCardProps>`
    margin-top: ${spacing.large};
    border: 1px solid ${transparencies.success10};
    border-radius: ${spacing.radiusSmall};
    background-color: ${transparencies.success10};
    width: 280px;
    padding: ${spacing.small};
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: box-shadow 0.2s;

    &:hover {
        box-shadow: ${shadows.buttonBoxShadow};
    }

    ${(props) =>
        props.$isUpcoming
            ? css`
                  border-color: ${transparencies.darkJungle5};
                  background-color: ${transparencies.darkJungle5};
                  pointer-events: none;
              `
            : null}
`;

export const ChronologyDataWrapper = styled.div`
    margin-bottom: ${spacing.xSmall};
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const HeaderTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
`;

export const TitleWrapper = styled.div<InfoCardProps>`
    border-radius: ${spacing.radiusSmall};
    background-color: ${transparencies.success10};
    width: 100%;
    padding: ${spacing.xxSmall};
    text-align: left;

    ${(props) =>
        props.$isUpcoming
            ? css`
                  background-color: ${transparencies.darkJungle5};
              `
            : null}
`;
