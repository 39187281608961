import React, { useCallback, useMemo } from 'react';

import { useAuth } from 'hooks/useAuth';
import { ClosingData, useMemberGroupData } from 'hooks/useMemberGroupData';

import { formatDate } from 'libs/utils';
import { Bumper, Divider } from 'styles/shared';

import { colors, Fonts, spacing, transparencies } from 'styles/theme';

export const GroupLearnings = () => {
    const { userId } = useAuth();
    const { closings, sessionDetails } = useMemberGroupData();

    const closingsBySession = useMemo(() => {
        const closingsBySessionToReturn: Array<Array<ClosingData>> = [];
        closings.forEach((closing) => {
            const sessionClosings =
                closingsBySessionToReturn[closing.session_number ?? 0];
            if (sessionClosings == null) {
                closingsBySessionToReturn[closing.session_number ?? 0] = [
                    closing,
                ];
            } else {
                const newSessionClosings = structuredClone(sessionClosings);
                if (closing.created_by === userId) {
                    newSessionClosings.push(closing);
                } else {
                    newSessionClosings.unshift(closing);
                }
                closingsBySessionToReturn[closing.session_number ?? 0] =
                    newSessionClosings;
            }
        });
        return closingsBySessionToReturn;
    }, [closings, userId]);

    const getSessionTitle = useCallback(
        (sessionNumber: number) => {
            const sessionDetailsForClosing = sessionDetails[sessionNumber - 1];
            return sessionDetailsForClosing == null
                ? ''
                : sessionDetailsForClosing.title;
        },
        [sessionDetails]
    );

    const renderGroupLearnings = useCallback(
        (sessionClosings: Array<ClosingData>, index: number) => (
            <>
                {index === 0 ? null : <Bumper $minHeight={spacing.xSmall} />}
                <Fonts.XLarge
                    $color={colors.mountainAsh}
                >{`Session ${sessionClosings[0]?.session_number}`}</Fonts.XLarge>
                {sessionClosings[0]?.session_date == null ? null : (
                    <Fonts.Medium $color={colors.grandGreen}>
                        {formatDate(
                            new Date(sessionClosings[0]?.session_date ?? 0)
                        )}
                    </Fonts.Medium>
                )}
                {sessionDetails.length > 0 ? (
                    <>
                        <Bumper $minHeight={spacing.xSmall} />
                        <Fonts.Heading7>
                            {getSessionTitle(
                                sessionClosings[0]?.session_number ?? 0
                            )}
                        </Fonts.Heading7>
                    </>
                ) : null}
                {sessionClosings.map((closing) => {
                    const parsedRawData = JSON.parse(
                        JSON.stringify(closing.raw_data ?? {})
                    );
                    return (
                        <>
                            <Bumper $minHeight={spacing.small} />
                            {closing.created_by === userId ? (
                                <>
                                    <Fonts.Small
                                        $isOpenSansSemiBold
                                    >{`Your learning:`}</Fonts.Small>
                                    <Bumper $minHeight={spacing.xxxSmall} />
                                </>
                            ) : null}
                            <Fonts.MediumQuote>
                                {`“${parsedRawData?.learnings}”`}
                            </Fonts.MediumQuote>
                        </>
                    );
                })}
                <Bumper $minHeight={spacing.xSmall} />
                {index === sessionClosings.length - 1 ? null : (
                    <Divider $backgroundColor={transparencies.darkJungle20} />
                )}
            </>
        ),
        [getSessionTitle, sessionDetails.length, userId]
    );

    return <>{closingsBySession.reverse().map(renderGroupLearnings)}</>;
};
