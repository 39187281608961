import React, { useEffect, useState } from 'react';
import { Route, Routes, useMatch, useSearchParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useAuth } from 'hooks/useAuth';
import { useToast } from 'hooks/useToast';
import { useNavigateWithParams } from 'hooks/useNavigateWithParams';

import { SignIn } from 'components/layout/SignIn';
import { SignUp } from 'components/layout/SignUp';
import { PasswordReset } from 'components/layout/PasswordReset';
import { Confirm } from 'components/layout/Confirm';
import { Member } from 'components/layout/Member';
import { OnboardingContainer } from 'components/layout/OnboardingContainer';
import { SessionDetails } from 'components/layout/SessionDetails';
import { SessionList } from 'components/layout/SessionList';
import { Insights } from 'components/layout/Insights';
import { GroupReports } from 'legacy/components/GroupReports';
import { Coach } from 'legacy/components/Coach';
import { PendingRecaps } from 'legacy/components/PendingRecaps';
import { RecapHistory } from 'legacy/components/RecapHistory';
import { CoachResources } from 'legacy/components/CoachResources';
import { Footer } from 'components/ui/Footer';
import { Modal } from 'components/layout/Modal';

import { colors, GlobalStyles, transparencies } from 'styles/theme';
import { AppWrapper, FooterWrapper } from 'styles/appRoutes';

export const AppRoutes = () => {
    const { hasInitialized, isSignedIn, userProfile } = useAuth();
    const { showWarningToast } = useToast();
    const navigateWithParams = useNavigateWithParams();

    const passwordResetRoute = useMatch('/password-reset/*');
    const confirmRoute = useMatch('/confirm/*');
    const signInRoute = useMatch('/sign-in/*');
    const signUpRoute = useMatch('/sign-up/*');
    const applyRoute = useMatch('/apply/*');
    const onboardingRoute = useMatch('/onboarding/*');
    const sponsorRoute = useMatch('/sponsor/*');
    const coachRoute = useMatch('/coach/*');
    const memberRoute = useMatch('/member/*');

    const [searchParams] = useSearchParams();
    const [hasDisplayedScreenSizeToast, setHasDisplayedScreenSizeToast] =
        useState<boolean>(false);

    useEffect(() => {
        if (
            !hasDisplayedScreenSizeToast &&
            window.innerWidth < 1120 &&
            (coachRoute != null ||
                (memberRoute != null &&
                    searchParams.get('modalContent') !== 'closing'))
        ) {
            setHasDisplayedScreenSizeToast(true);
            showWarningToast(
                'Mobile devices and smaller browser window sizes are not yet fully supported'
            );
        }
    }, [
        coachRoute,
        hasDisplayedScreenSizeToast,
        memberRoute,
        searchParams,
        showWarningToast,
    ]);

    useEffect(() => {
        if (!hasInitialized) {
            return;
        }

        if (passwordResetRoute == null && confirmRoute == null) {
            if (
                signInRoute == null &&
                signUpRoute == null &&
                applyRoute == null &&
                !isSignedIn
            ) {
                navigateWithParams('/sign-in');
            } else if (userProfile != null) {
                if (
                    sponsorRoute != null &&
                    userProfile?.sponsor_id != null &&
                    userProfile.sponsor_id !== ''
                ) {
                    return;
                } else if (
                    sponsorRoute == null &&
                    userProfile?.sponsor_id != null &&
                    userProfile.sponsor_id !== ''
                ) {
                    navigateWithParams('/sponsor');
                } else if (
                    coachRoute != null &&
                    userProfile?.is_coach === true
                ) {
                    return;
                } else if (
                    coachRoute == null &&
                    userProfile?.is_coach === true
                ) {
                    navigateWithParams('/coach');
                } else if (
                    memberRoute != null &&
                    (userProfile?.member_groups?.length ?? 0) > 0
                ) {
                    return;
                } else if (
                    memberRoute == null &&
                    (userProfile?.member_groups?.length ?? 0) > 0
                ) {
                    navigateWithParams('/member');
                } else if (
                    onboardingRoute != null &&
                    (userProfile?.member_groups?.length ?? 0) === 0
                ) {
                    return;
                } else if (
                    onboardingRoute == null &&
                    (userProfile?.member_groups?.length ?? 0) === 0
                ) {
                    navigateWithParams('/onboarding');
                }
            }
        }
    }, [
        applyRoute,
        coachRoute,
        confirmRoute,
        hasInitialized,
        isSignedIn,
        memberRoute,
        navigateWithParams,
        onboardingRoute,
        passwordResetRoute,
        signInRoute,
        signUpRoute,
        sponsorRoute,
        userProfile,
    ]);

    return (
        <>
            <GlobalStyles />
            <AppWrapper
                $isAuthRoute={
                    signInRoute != null ||
                    signUpRoute != null ||
                    applyRoute != null ||
                    passwordResetRoute != null ||
                    confirmRoute != null
                }
            >
                <ToastContainer />
                {hasInitialized ? (
                    <>
                        <Routes>
                            {/* Routes accessible regardless of authentication */}
                            <Route
                                path="password-reset"
                                element={<PasswordReset />}
                            />
                            <Route path="confirm" element={<Confirm />} />

                            {/* Routes accessible only when unauthenticated */}
                            <Route path="sign-in" element={<SignIn />} />
                            <Route
                                path="sign-up/:tempGroupSponsorId?/:sponsorName?"
                                element={<SignUp />}
                            />
                            <Route
                                path="apply/:tempGroupSponsorId?/:sponsorName?"
                                element={<SignUp />}
                            />

                            {/* Routes accessible only to authenticated sponsors */}
                            <Route path="sponsor" element={<GroupReports />} />

                            {/* Routes accessible only to authenticated coaches */}
                            <Route path="coach" element={<Coach />}>
                                <Route
                                    path="submit-recaps/*"
                                    element={<PendingRecaps />}
                                />
                                <Route
                                    path="past-recaps/*"
                                    element={<RecapHistory />}
                                />
                                <Route
                                    path="resources/*"
                                    element={<CoachResources />}
                                />
                            </Route>

                            {/* Routes accessible only to authenticated users with no app access */}
                            <Route
                                path="onboarding"
                                element={<OnboardingContainer />}
                            />

                            {/* Routes accessible only to authenticated members */}
                            <Route path="member" element={<Member />}>
                                <Route
                                    path="scheduled-session/*"
                                    element={<SessionDetails />}
                                />
                                <Route
                                    path="all-sessions/*"
                                    element={<SessionList />}
                                />
                                <Route
                                    path="all-sessions/:sessionNumber/*"
                                    element={<SessionDetails />}
                                />
                                <Route
                                    path="insights/*"
                                    element={<Insights />}
                                />
                            </Route>
                        </Routes>

                        <FooterWrapper>
                            <Footer
                                hasSignOut={
                                    onboardingRoute != null ||
                                    sponsorRoute != null ||
                                    (isSignedIn &&
                                        (passwordResetRoute != null ||
                                            confirmRoute != null))
                                }
                                $textColor={
                                    signInRoute != null ||
                                    signUpRoute != null ||
                                    applyRoute != null ||
                                    passwordResetRoute != null ||
                                    confirmRoute != null
                                        ? colors.grey30
                                        : transparencies.white80
                                }
                                $hasBottomBumper={
                                    signUpRoute != null || applyRoute != null
                                }
                            />
                        </FooterWrapper>

                        {memberRoute != null ||
                        sponsorRoute != null ||
                        coachRoute != null ? (
                            <Modal />
                        ) : null}
                    </>
                ) : null}
            </AppWrapper>
        </>
    );
};
