import styled from 'styled-components';

import { spacing, transparencies } from 'styles/theme';

export const TagWrapper = styled.div`
    margin: 0 ${spacing.xSmall};
    border-radius: ${spacing.radiusXSmall};
    background-color: ${transparencies.darkJungle5};
    padding: 0 ${spacing.xxSmall};
    display: flex;
`;
