import styled, { css } from 'styled-components';

import { ColorsTransparencies, spacing, transparencies } from 'styles/theme';

// Custom spacing and positioning values needed throughout to accurately cover
// and mock Turnstile component border and border radius

export const TurnstileWrapper = styled.div`
    position: relative;
`;

export interface TurnstileOuterBorderCoverProps {
    $color?: ColorsTransparencies;
}

export const TurnstileOuterBorderCover = styled.div<TurnstileOuterBorderCoverProps>`
    position: absolute;
    top: -${spacing.xxxSmall};
    left: -${spacing.xxxSmall};
    box-sizing: content-box;
    border: 6px solid white;
    border-radius: ${spacing.xSmall};
    width: calc(100% - ${spacing.xxxSmall});
    height: calc(100% - ${spacing.xxxSmall});
    pointer-events: none;

    ${(props) =>
        props.$color == null
            ? null
            : css`
                  border-color: ${props.$color};
              `}
`;

export const TurnstileInnerBorderCover = styled.div`
    position: absolute;
    top: -2px;
    left: -2px;
    box-sizing: content-box;
    border: 1px solid ${transparencies.darkJungle10};
    border-radius: ${spacing.xxSmall};
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    pointer-events: none;
`;
