import { styled, css } from 'styled-components';
import { spacing, colors } from 'styles/theme';

interface DropdownProps {
    $isDropdownOpen: boolean;
}

export const MainWrapper = styled.div`
    margin: 32px;
    border-radius: 4px;
    background-color: ${colors.white};
    width: 800px;
    padding: 28px 32px;

    @media only screen and (max-width: ${spacing.mobileBreakpoint}) {
        margin: 16px;
        width: 100%;
    }
`;

export const Heading = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

export const StatListWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: ${spacing.mobileBreakpoint}) {
        flex-direction: column;
    }
`;

export const StatWrapper = styled.div`
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;

    @media only screen and (max-width: ${spacing.mobileBreakpoint}) {
        margin-top: 40px;
        width: 100%;
        flex-direction: column;

        &:first-of-type {
            margin-top: 8px;
        }
    }
`;

export const ReportTextResponsesWrapper = styled.div`
    width: 100%;
    text-align: left;
`;

export const ItalicText = styled.p`
    font-family: 'OpenSans-Regular';
    font-size: 16px;
    line-height: 24px;
    font-style: italic;
`;

export const ChevronDropdownWrapper = styled.div<DropdownProps>`
    transition: 0.2s;

    ${(props) => {
        switch (props.$isDropdownOpen) {
            case true: {
                return css`
                    transform: scaleY(-1);
                `;
            }
            case false:
            default: {
                return;
            }
        }
    }};
`;

export const ChevronDropdownCaret = styled.div`
    transform: rotate(90deg);
`;
