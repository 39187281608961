import { CHARACTER_SET } from 'libs/constants';

// Arrays in JavaScript are typed as objects, so this util works for comparing
// both hash maps `{}` and arrays `[]`
export const deepEqual = (object1: object, object2: object) => {
    if (object1 === object2) {
        return true;
    }
    if (
        typeof object1 !== 'object' ||
        typeof object2 !== 'object' ||
        object1 == null ||
        object2 == null
    ) {
        return false;
    }
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        // Not sure how to handle this type error since we don't know what objects
        // will be passed to the comparison function here
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!keys2.includes(key) || !deepEqual(object1[key], object2[key])) {
            return false;
        }
    }
    return true;
};

export const generateRandomString = () => {
    let randomString = '';
    for (let i = 0; i < 10; i++) {
        randomString += CHARACTER_SET.charAt(
            Math.floor(Math.random() * CHARACTER_SET.length)
        );
    }
    return randomString;
};

export const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat('en-US', {
        dateStyle: 'long',
    }).format(date);
};

export const formatDateTime = (date: Date) => {
    const formattedDate = new Intl.DateTimeFormat('en-US', {
        dateStyle: 'medium',
        timeStyle: 'medium',
    }).format(date);

    return `${formattedDate.slice(0, formattedDate.length - 6)}${formattedDate
        .slice(formattedDate.length - 2, formattedDate.length)
        .toLowerCase()}`;
};

export const getDayCountBetweenDates = (date1: Date, date2: Date) => {
    const utc1 = Date.UTC(
        date1.getFullYear(),
        date1.getMonth(),
        date1.getDate()
    );
    const utc2 = Date.UTC(
        date2.getFullYear(),
        date2.getMonth(),
        date2.getDate()
    );

    return Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
};

export type DateRelationship = 'previous' | 'current' | 'upcoming';

export const getRelationshipToToday = (date: Date): DateRelationship => {
    const dayCountBetweenDates = getDayCountBetweenDates(date, new Date());
    if (dayCountBetweenDates === 0) {
        return 'current';
    } else if (Math.abs(dayCountBetweenDates) === dayCountBetweenDates) {
        return 'previous';
    } else {
        return 'upcoming';
    }
};

// This function is used to map the assessment questions to database insert params
// Eg. The second of three questions in "Career Wellbeing" would be cw2
export const mapDimensionQuestion = (category: string, index: number) => {
    const prefix = category
        .split(' ')
        .map((word) => word[0]?.toLowerCase())
        .join('');
    return `${prefix}${index + 1}`;
};
