import React from 'react';

import { CustomSVGProps, setSVGColor } from 'styles/theme';

export const Close = (props: CustomSVGProps) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M1 1L10 10M10 10L1 19M10 10L19 19M10 10L19 1"
            stroke={setSVGColor(props)}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
