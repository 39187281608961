import React, {
    Fragment,
    ReactNode,
    useCallback,
    useMemo,
    useState,
} from 'react';

import { supabase, useAuth } from 'hooks/useAuth';
import { useMemberGroupData } from 'hooks/useMemberGroupData';
import { useToast } from 'hooks/useToast';

import { RETRO_DIMENSIONS } from 'libs/constants';
import { mapDimensionQuestion } from 'libs/utils';

import { SteppedForm } from 'components/layout/SteppedForm';

import { DimensionSubtotals } from 'components/ui/DimensionSubtotals';
import { DimensionResponses } from 'components/ui/DimensionResponses';
import { Select } from 'components/ui/Select';
import { TextInput } from 'components/ui/TextInput';

import { colors, Fonts, spacing } from 'styles/theme';
import { Bumper, Checkbox, CheckboxWrapper, NestedCard } from 'styles/shared';

interface SubmitRetroProps {
    onChangeFormStep: () => void;
    onClose: () => void;
}

export const SubmitRetro = ({
    onChangeFormStep,
    onClose,
}: SubmitRetroProps) => {
    const { userEmail, userId, userProfile } = useAuth();
    const { getRetros } = useMemberGroupData();
    const { showSuccessToast, showErrorToast } = useToast();

    // TODO: this should be refactored with useReducer and typed properly
    const [submissionPayload, setSubmissionPayload] = useState({
        subtotals: {},
    });
    const [reactions, setReactions] = useState<string>('');
    const [reflections, setReflections] = useState<string>('');
    const [shouldShare, setShouldShare] = useState<boolean>(true);
    const [isSubmittingRetro, setIsSubmittingRetro] = useState<boolean>(false);

    // How Retros were tracked previously, continuing to capture this for
    // historical data comparisons
    const currentPeriod = useMemo(() => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentQuarter = Math.ceil(currentMonth / 3);
        const currentYear = currentDate.getFullYear();

        return `${currentYear} Q${currentQuarter}`;
    }, []);

    const calculateSubtotal = useCallback(
        (
            databaseQuestionParam: string,
            newSubmissionPayload: typeof submissionPayload
        ): number => {
            let subtotal = 0;
            const prefix = databaseQuestionParam.slice(0, 2);
            for (let i = 1; i <= 3; i++) {
                // See TODO above
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const questionValue = newSubmissionPayload[`${prefix}${i}`];
                subtotal += questionValue == null ? 0 : questionValue;
            }
            return subtotal;
        },
        []
    );

    const onQuestionInput = useCallback(
        (databaseQuestionParam: string, dimension: string) =>
            (event: React.FormEvent<HTMLSelectElement>) => {
                const newSubmissionPayload = { ...submissionPayload };
                const target = event.target as HTMLSelectElement;
                // See TODO above
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newSubmissionPayload[databaseQuestionParam] = parseInt(
                    target.value,
                    10
                );
                // See TODO above
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newSubmissionPayload.subtotals[dimension] = calculateSubtotal(
                    databaseQuestionParam,
                    newSubmissionPayload
                );
                setSubmissionPayload(newSubmissionPayload);
            },
        [calculateSubtotal, submissionPayload]
    );

    const onReactionsInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setReactions(target.value);
        },
        []
    );

    const onReflectionsInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setReflections(target.value);
        },
        []
    );

    const onShareInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setShouldShare(target.checked);
        },
        []
    );

    const onSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (userProfile?.is_test) {
                showErrorToast('Test accounts cannot submit retros');
                return;
            }

            if (isSubmittingRetro) {
                return;
            }
            setIsSubmittingRetro(true);

            const { error } = await supabase
                .from('retros')
                .insert({
                    ...submissionPayload,
                    created_by: userId ?? '',
                    email: userEmail,
                    period: currentPeriod,
                    reflections,
                    reactions,
                    share: shouldShare,
                })
                .select();

            if (error == null) {
                getRetros?.();
                showSuccessToast('Your assessment has been submitted!');
            } else {
                showErrorToast(error.message);
            }

            setIsSubmittingRetro(false);
        },
        [
            currentPeriod,
            getRetros,
            isSubmittingRetro,
            reactions,
            reflections,
            shouldShare,
            showErrorToast,
            showSuccessToast,
            submissionPayload,
            userEmail,
            userId,
            userProfile?.is_test,
        ]
    );

    const headerNode = useMemo(
        () => (
            <>
                <Fonts.Heading5>{`The Grand Retro`}</Fonts.Heading5>
                <Bumper />
            </>
        ),
        []
    );

    const formSteps = useMemo(() => {
        const formStepsReturnArray = [
            {
                isComplete: true,
                formNode: (
                    <NestedCard>
                        <Fonts.Medium>{`Hey, ${userProfile?.full_name}! This is your place to track your personal growth, stay accountable to your goals, and track your session learnings. Reflecting is your way to track progress.`}</Fonts.Medium>
                        <Bumper />
                        <Fonts.Small>{`Based on scientific research, we’ve developed an assessment to help you measure your wellbeing.`}</Fonts.Small>
                        <Bumper />
                        <Fonts.Small>
                            {`Reflection is how you will track your growth and progress throughout your time at The Grand. Aligning your values with how you are doing today is the best way to understand if you’re getting closer to your goals.`}
                        </Fonts.Small>
                        <Bumper />
                        <Fonts.Small>{`As part of this Grand Retro, you have the opportunity to assess where you are today and how that might evolve in the months and years to come.`}</Fonts.Small>
                    </NestedCard>
                ),
            },
        ];

        Object.keys(RETRO_DIMENSIONS).forEach((dimension) => {
            let areDimensionQuestionsComplete = true;
            const questionNodes: Array<ReactNode> = [];
            // A very annoying TypeScript error that requires you to type the object, its keys,
            // and its values even though you're iterating through that same object's keys
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            RETRO_DIMENSIONS[dimension].forEach(
                (question: string, index: number) => {
                    const databaseQuestionParam = mapDimensionQuestion(
                        dimension,
                        index
                    );
                    // See above
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (submissionPayload[databaseQuestionParam] == null) {
                        areDimensionQuestionsComplete = false;
                    }
                    questionNodes.push(
                        <>
                            <Select
                                key={question}
                                name={`${question} select`}
                                onInput={onQuestionInput(
                                    databaseQuestionParam,
                                    dimension
                                )}
                                value={
                                    // See above
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    submissionPayload[databaseQuestionParam]
                                }
                                label={question}
                                required
                            >
                                <option value="" label="Select" hidden />
                                {(() => {
                                    const selectOptions = [];
                                    for (let i = 1; i <= 10; i++) {
                                        selectOptions.push(
                                            <option value={i}>{i}</option>
                                        );
                                    }
                                    return selectOptions;
                                })()}
                            </Select>
                            {index < 2 ? <Bumper /> : null}
                        </>
                    );
                }
            );
            formStepsReturnArray.push({
                isComplete: areDimensionQuestionsComplete,
                formNode: (
                    <NestedCard>
                        <Fonts.XLarge $color={colors.mountainAsh}>
                            {dimension}
                        </Fonts.XLarge>
                        <Bumper $minHeight={spacing.small} />
                        {questionNodes}
                    </NestedCard>
                ),
            });
        });

        formStepsReturnArray.push({
            isComplete: true,
            formNode: (
                <>
                    <Fonts.Medium>{`Here's a summary of each of your dimensions.`}</Fonts.Medium>
                    <DimensionSubtotals
                        subtotals={submissionPayload.subtotals}
                    />
                </>
            ),
        });

        formStepsReturnArray.push({
            isComplete: reflections !== '' && reactions !== '',
            formNode: (
                <>
                    <Fonts.Medium>{`Here are the totals for each of your dimensions.`}</Fonts.Medium>
                    <Bumper />
                    <DimensionResponses retroDataPayload={submissionPayload} />
                    <Bumper />
                    <NestedCard>
                        <Fonts.Medium>
                            {`Reflecting on these results will support you in setting goals for this quarter. We’ll save these responses for you to revisit in your sessions. Consider the following reflection questions:`}
                        </Fonts.Medium>
                        <Bumper />
                        <TextInput
                            id="reactions"
                            name="reactions"
                            value={reactions}
                            onInput={onReactionsInput}
                            label="1. What are your reactions to your scores? Do you feel balanced? Do you want to be?"
                            isTextArea
                            required
                        />
                        <Bumper />
                        <TextInput
                            id="reflections"
                            name="reflections"
                            value={reflections}
                            onInput={onReflectionsInput}
                            label="2. As you reflect on the results of this Assessment, how does it it impact your feelings about your long-term goals?"
                            isTextArea
                            required
                        />
                        <Bumper />
                        <Fonts.Small>{`Sharing your assessment results with your coach will enable them to help you work toward your goals and make the most of the live experience.`}</Fonts.Small>
                        <Bumper />
                        <NestedCard>
                            <CheckboxWrapper>
                                <Checkbox
                                    type="checkbox"
                                    name="share"
                                    onChange={onShareInput}
                                    checked={shouldShare}
                                />
                                <Fonts.Small>
                                    {`I would like to share a copy of my assessment results with
                    my coach.`}
                                </Fonts.Small>
                            </CheckboxWrapper>
                        </NestedCard>
                    </NestedCard>
                </>
            ),
        });

        return formStepsReturnArray;
    }, [
        onQuestionInput,
        onReactionsInput,
        onReflectionsInput,
        onShareInput,
        reactions,
        reflections,
        shouldShare,
        submissionPayload,
        userProfile?.full_name,
    ]);

    return (
        <SteppedForm
            headerNode={headerNode}
            formSteps={formSteps}
            onFinalSubmit={onSubmit}
            onChangeFormStep={onChangeFormStep}
            onClose={onClose}
            $isBottomAreaWhite
        />
    );
};
