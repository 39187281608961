import React, { PropsWithChildren } from 'react';

import { colors, Fonts, spacing } from 'styles/theme';
import { NestedCard, NestedCardProps } from 'styles/shared';
import {
    BottomRightQuoteWrapper,
    TopLeftQuoteWrapper,
} from 'styles/quotedNestedCard';

type QuotedNestedCardProps = NestedCardProps & PropsWithChildren;

export const QuotedNestedCard = ({
    children,
    $padding,
}: QuotedNestedCardProps) => (
    <NestedCard $padding={$padding == null ? spacing.large : $padding}>
        <TopLeftQuoteWrapper>
            <Fonts.Heading3 $color={colors.mountainAsh}>{`“`}</Fonts.Heading3>
        </TopLeftQuoteWrapper>
        {children}
        <BottomRightQuoteWrapper>
            <Fonts.Heading3 $color={colors.mountainAsh}>{`”`}</Fonts.Heading3>
        </BottomRightQuoteWrapper>
    </NestedCard>
);
