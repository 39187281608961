import * as React from 'react';

import { CustomSVGProps, setSVGColor } from 'styles/theme';

export const Dislike = (props: CustomSVGProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        fill="none"
        {...props}
    >
        <path
            stroke={setSVGColor(props)}
            d="M19.6629 6.5L24.1451 6.5C24.8974 6.5 25.5057 7.11396 25.5 7.86741L25.438 16.0103C25.4323 16.7558 24.8273 17.3571 24.083 17.3571L19.6629 17.3571M19.6629 6.5L10.3374 6.5C9.70238 6.5 9.15249 6.94167 9.01471 7.56259L6.53282 17.2714C6.34476 18.1189 6.98868 18.9231 7.85556 18.9231L11.637 18.9231C12.4908 18.9231 13.1614 19.4956 12.9959 20.3345L12.2966 23.8798C12.1311 24.7187 12.7721 25.5 13.6258 25.5L13.8922 25.5C14.3741 25.5 14.8197 25.2435 15.0626 24.8267L19.6629 17.3571M19.6629 6.5L19.6629 17.3571"
        />
    </svg>
);
