import React, {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
} from 'react';
import { ToastPosition, toast, Slide } from 'react-toastify';

const position: ToastPosition = 'top-center';

const TOAST_OPTIONS = {
    position,
    transition: Slide,
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: 'toast',
};

interface ToastContext {
    showSuccessToast: (message: string) => void;
    showWarningToast: (message: string) => void;
    showErrorToast: (message: string) => void;
}

const toastDefaultState: ToastContext = {
    showSuccessToast: (message: string) => {
        console.log(message);
    },
    showWarningToast: (message: string) => {
        console.log(message);
    },
    showErrorToast: (message: string) => {
        console.log(message);
    },
};

const ToastContext = createContext(toastDefaultState);

export function ToastProvider(props: PropsWithChildren) {
    const { children } = props;

    const showSuccessToast = useCallback((message: string) => {
        toast.success(message, TOAST_OPTIONS);
    }, []);

    const showWarningToast = useCallback((message: string) => {
        toast.warning(message, TOAST_OPTIONS);
    }, []);

    const showErrorToast = useCallback((message: string) => {
        toast.error(message, TOAST_OPTIONS);
    }, []);

    return (
        <ToastContext.Provider
            value={{ showSuccessToast, showWarningToast, showErrorToast }}
        >
            {children}
        </ToastContext.Provider>
    );
}

export const useToast = () => useContext(ToastContext);
