import React, { useEffect, useMemo } from 'react';
import { Outlet, useMatch } from 'react-router-dom';

import { useNavigateWithParams } from 'hooks/useNavigateWithParams';

import { NavMenu } from 'components/ui/NavMenu';

import { Home } from 'components/svg/Home';
import { Star } from 'components/svg/Star';
import { Idea } from 'components/svg/Idea';

import { MainViewWrapper } from 'styles/shared';

export const Coach = () => {
    const navigateWithParams = useNavigateWithParams();

    const coachWithNoSubRoute = useMatch('/coach');
    const submitRecapsRoute = useMatch('/coach/submit-recaps/*');
    const pastRecapsRoute = useMatch('/coach/past-recaps/*');
    const resourcesRoute = useMatch('/coach/resources/*');

    useEffect(() => {
        if (coachWithNoSubRoute != null) {
            navigateWithParams('/coach/submit-recaps');
        }
    }, [coachWithNoSubRoute, navigateWithParams]);

    const menuItems = useMemo(
        () => [
            {
                text: `Submit Recaps`,
                link: '/coach/submit-recaps',
                SVGComponent: Home,
                isSelected: submitRecapsRoute != null,
            },
            {
                text: `Past Recaps`,
                link: '/coach/past-recaps',
                SVGComponent: Star,
                isSelected: pastRecapsRoute != null,
            },
            {
                text: `Resources`,
                link: '/coach/resources',
                SVGComponent: Idea,
                isSelected: resourcesRoute != null,
            },
        ],
        [submitRecapsRoute, resourcesRoute, pastRecapsRoute]
    );

    return (
        <MainViewWrapper>
            <NavMenu menuItems={menuItems} />
            <Outlet />
        </MainViewWrapper>
    );
};
