import React, { useCallback, useMemo } from 'react';

import { useMemberGroupData } from 'hooks/useMemberGroupData';
import { useNavigateWithParams } from 'hooks/useNavigateWithParams';

import { formatDate, getRelationshipToToday } from 'libs/utils';

import { InfoCard } from 'components/ui/InfoCard';

import MountainTwoPeople from 'assets/mountain-two-people.png';

import { colors, Fonts, spacing } from 'styles/theme';
import {
    BannerWrapper,
    Bumper,
    Card,
    ContentScroller,
    GridListWrapper,
} from 'styles/shared';

export const SessionList = () => {
    const { group, scheduledSessions, sessionDetails } = useMemberGroupData();
    const navigateWithParams = useNavigateWithParams();

    const nextSessionRelationshipToToday = useMemo(
        () =>
            group?.next_session_date == null
                ? 'upcoming'
                : getRelationshipToToday(new Date(group?.next_session_date)),
        [group?.next_session_date]
    );

    const checkSessionRelationshipToToday = useCallback(
        (sessionNumber: number) => {
            if (sessionNumber < (group?.session_counter ?? 0)) {
                return 'previous';
            } else if (sessionNumber > (group?.session_counter ?? 0)) {
                return 'upcoming';
            } else if (sessionNumber === (group?.session_counter ?? 0)) {
                return nextSessionRelationshipToToday;
            } else {
                return 'upcoming';
            }
        },
        [group?.session_counter, nextSessionRelationshipToToday]
    );

    const onSelectSession = useCallback(
        (sessionNumber: number) => () => {
            navigateWithParams(
                `/member/all-sessions/${sessionNumber}/description`
            );
        },
        [navigateWithParams]
    );

    return (
        <ContentScroller>
            <Bumper $minHeight={spacing.xxxLarge} />
            <Bumper $minHeight={spacing.xxxLarge} />
            <Card>
                <BannerWrapper $backgroundImageUrl={MountainTwoPeople}>
                    <Fonts.Heading5>{`All Sessions`}</Fonts.Heading5>
                    <Bumper />
                    <Fonts.Heading7 $color={colors.mountainAsh}>
                        {group?.gid}
                    </Fonts.Heading7>
                </BannerWrapper>
                <GridListWrapper>
                    {/* Type check is working in development, but compiler flags on build
                    eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    @ts-ignore */}
                    {sessionDetails.map((sessionDetailsDatum, index) => (
                        <InfoCard
                            key={`${sessionDetailsDatum?.id}-${index + 1}`}
                            topLineText={`Session ${index + 1}`}
                            headerText={formatDate(
                                new Date(
                                    scheduledSessions?.[index + 1]?.start ?? 0
                                )
                            )}
                            dateRelationship={
                                index + 1 === group?.session_counter
                                    ? 'current'
                                    : checkSessionRelationshipToToday(index + 1)
                            }
                            mainText={sessionDetailsDatum?.title ?? ''}
                            onClick={onSelectSession(index + 1)}
                        />
                    ))}
                </GridListWrapper>
            </Card>
        </ContentScroller>
    );
};
