import { Database } from 'libs/supabaseTypes';

export type FeedbackTypeEnum = Database['public']['Enums']['feedback_type'];

export const CHARACTER_SET =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789' as const;

export const CONSUMER_SPONSOR_IDS = ['6454a86e-941f-4fd2-b173-3fe982a62ea7'];

export const YEARS_EXPERIENCE_OPTIONS = [
    '1-5 years',
    '6-10 years',
    '10+ years',
] as const;

export const FUNDING_STAGE_OPTIONS = [
    'Pre-seed',
    'Seed',
    'Series A',
    'Series B',
    'Series C',
    'Series D',
    'Series E',
    'Series F',
    'Private',
    'Public',
    'Venture series unknown',
    'Nonprofit',
] as const;

export const EMPLOYEE_COUNT_OPTIONS = [
    '1-10 FTE',
    '11-50 FTE',
    '51-200 FTE',
    '200+ FTE',
] as const;

export const COMPANIES_FOUNDED_OPTIONS = [
    '0 (first-time founder)',
    '1',
    '2',
    '3+',
] as const;

export const COACHING_EXPERIENCE_OPTIONS = [
    'Leadership: Expand your Influence at Work',
    'Parenthood: Thriving at Home and Beyond',
    'Career Transitions: Navigate Change, Embrace Your Next Chapter',
] as const;

export const DIAGNOSTIC_RATING = [
    'Strongly Disagree',
    'Disagree',
    'Somewhat Disagree',
    'Neither Agree Nor Disagree',
    'Somewhat Agree',
    'Agree',
    'Strongly Agree',
] as const;

export const MERGED_PRECURSOR_GROUPS = ['PRE20', 'PRE18'];

export const RATING_RESPONSES = [
    'Very Satisfied',
    'Satisfied',
    'Neutral',
    'Unsatisfied',
    'Very Unsatisfied',
] as const;

export const EXIT_RESPONSES = [
    'Strongly Agree',
    'Agree',
    'Neutral',
    'Disagree',
    'Strongly Disagree',
] as const;

export const KUDOS_TYPES_TO_EMPTY_STRINGS = {
    ['Self-aware']: '',
    Empathetic: '',
    Confident: '',
    Curious: '',
    Resilient: '',
    ['Effective Coach']: '',
} as const;

export const KUDOS_TYPES_TO_EMOJIS = {
    ['Self-aware']: '🧠',
    Empathetic: '❤️',
    Confident: '💪',
    Curious: '🔎',
    Resilient: '🏋️',
    ['Effective Coach']: '🥇',
} as const;

export const FEEDBACK_TYPES: Array<FeedbackTypeEnum> = [
    'reinforcing',
    'developmental',
];

export const OPPORTUNITY_QUESTIONS = [
    'How can I help support your growth?',
    'How can I encourage you in this area?',
    'What are some ways you could work on that?',
    'What resources could I provide to help you grow?',
    'What are some ideas you have moving forward?',
    'How can I support you in this area?',
] as const;

export const RESULT_QUESTIONS = [
    'What are your thoughts?',
    'What stood out to you?',
    'What did you notice about the situation?',
    'How did it feel for you?',
] as const;

export const RETRO_DIMENSIONS = {
    'Career Wellbeing': [
        'I am engaged in meaningful work, and I feel confident in my abilities.',
        'I invest time and energy to develop myself.',
        'I take responsibility and create opportunities to evolve my career.',
    ],
    'Emotional Resilience': [
        'I see challenge as an opportunity to expand and engage.',
        'I feel valued and affirmed and take time to value and affirm others.',
        'I have strategies for when I am stressed.',
    ],
    'Environmental Wellbeing': [
        'I have a physical space that enables me to do my best work.',
        'I live and work in a positive environment where I feel safe.',
        'I find time to connect with nature and be outdoors.',
    ],
    'Financial Wellbeing': [
        'I am appropriately compensated for my work.',
        'I feel like I have a healthy relationship with money.',
        'I am satisfied with my current financial situation.',
    ],
    'Intellectual Curiosity': [
        'I give myself permission to take risks, experiment, and be creative.',
        'I take time to engage in self-learning.',
        'I have opportunities to engage in my strengths every day.',
    ],
    'Physical Wellbeing': [
        'I eat healthy and nourishing foods.',
        'I exercise regularly and feel strong.',
        'I get sufficient sleep every night and allow my body to rest when it’s tired.',
    ],
    'Social Wellbeing': [
        'I have a strong support system.',
        'I cultivate trust and connection with others.',
        'I communicate my feelings effectively.',
    ],
    'Spiritual Awareness': [
        'I have a practice that enables me to make meaning in life.',
        'I create time and space to acknowledge and appreciate other beings.',
        'I am living a life that aligns with my personal values.',
    ],
} as const;
